import Slider from "react-slick";
import { ItemDesktop, ItemMobile } from "components/item";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { isBrowser } from "react-device-detect";
import { loadProductsPreviewRequest, loadProductsOfferRequest } from "store/domains/products/productsActions";
import { useSelector, useDispatch } from "react-redux";
import "react-alice-carousel/lib/alice-carousel.css";
import FilterList from "components/filter/FilterList";

function CarouselOfferProducts({ categories }) {
  const dispatch = useDispatch();
  const currentCity = useSelector((state) => state.cities.city);
  const city = currentCity ? currentCity.toLowerCase() : "medellin";
  useEffect(() => {
    dispatch(loadProductsOfferRequest(categories, city));
  }, [dispatch, categories, city]);
  const productsPreviewSelector = (state) => state.products.listOffer;
  const items = useSelector(productsPreviewSelector) || [];

  var isInfinite = true;

  if (items.length <= 5) {
    isInfinite = false;
  }

  var settings = {
    dots: true,
    infinite: isInfinite,
    speed: 500,
    slidesToShow: 9,
    slidesToScroll: 3,
    lazyLoad: "ondemand",
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: isBrowser ? 1100 : 1000,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };
  const [selectedOption, setSelectedOption] = useState(null);
  const handleSelect = (selectedOption) => {
    setSelectedOption(selectedOption);
    if (selectedOption === "smallest") {
      items.sort((a, b) => {
        return a.price - b.price;
      });
    }
    if (selectedOption === "alphabetically") {
      items.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        return 0;
      });
    }
    if (selectedOption === "largest") {
      items.sort((b, a) => {
        return a.price - b.price;
      });
    }
    if (selectedOption === "popular") {
      items.sort((b, a) => {
        return a.discount - b.discount;
      });
    }
  };

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    if (selectedOption.value === "smallest") {
      items.sort((a, b) => {
        return a.price - b.price;
      });
    }
    if (selectedOption.value === "alphabetically") {
      items.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        return 0;
      });
    }
    if (selectedOption.value === "largest") {
      items.sort((b, a) => {
        return a.price - b.price;
      });
    }
    if (selectedOption.value === "popular") {
      items.sort((b, a) => {
        return a.discount - b.discount;
      });
    }
  };

  return items.length === 0 ? (
    <div className="alert alert-info">
      <span>No se han encontrado datos para mostrar.</span>
    </div>
  ) : (
    <>
      <FilterList
        handleSelect={handleSelect}
        handleChange={handleChange}
        isBrowser={isBrowser}
        selectedOption={selectedOption}
      />
      <Slider {...settings} className="slider-margins">
        {items.length>0&&(isBrowser
          ? items.map((item, index) => (
              <ItemDesktop
                key={`${item["id"]}-${index}`}
                product={item}
                handleClose={() => {}}
                offerProduct={true}
              />
            ))
          : items.map((item, index) => (
              <NavLink
                key={`item-${index}`}
                to={`/categoria/${item.main_category_id}/producto/${item.id_item}`}
                className="item-card-home"
              >
                <ItemMobile key={`${item["id"]}-${index}`} product={item} offerProduct={true}/>
              </NavLink>
            )))}
      </Slider>
    </>
  );
  return(<div>
    
  </div>)
}

CarouselOfferProducts.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
};

CarouselOfferProducts.defaultProps = {
  items: [],
};

export default CarouselOfferProducts;
