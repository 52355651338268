import { useEffect, useState } from 'react';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { parseFloatWithComma } from 'utils/format';
import { useDispatch } from 'react-redux';
import { updateBag } from 'store/domains/shoppingCart/shoppingCartActions'

// TODO: get this value as a env var or a stored value from firebase
const deliveryCost = 4200;

const orderSelector = state => state.shoppingCart.order;
const configs = state => state.configs.list;
const shoppingSelector = state => state.shoppingCart;

const subtotalSelector = createSelector(
  orderSelector,
  (order) => order.reduce((acm, item) => (acm + getItemValue(item)), 0));

const itemCountSelector = createSelector(
  orderSelector,
  configs,
  (order, config) => order.reduce((acm, item) => (acm + getItemTotal(item, config)), 0)
);

const gramCountSelector = createSelector(
  orderSelector,
  configs,
  (order, config) => order.reduce((acm, item) => (acm + getGramTotal(item, config)), 0)
);

const bagsSelector = createSelector(
  shoppingSelector,
  (shoppingCart) => shoppingCart.bags_price
);


const getItemTotal = (item, config) => {
  if (item.unityType === 0) {
    if ((item.main_category_id === 'FRUTAS Y VERDURAS')
    || ((item.sub_category_id === 'POLLO' || item.sub_category_id === 'PESCADOS Y MARISCOS'))) {
      if(item.unit_gram > 0){
        return Math.ceil(item.count*item.unit_gram/config[0].gr_per_bag);
      }else{
        return Math.ceil(item.count/config[0].product_per_bag);
      }
    }else{
      return Math.ceil(item.count/config[0].product_per_bag);
    }
  }else{
    return 0;
  }
}

const getGramTotal = (item, config) => {
  if (item.unityType === 1) {
      return Math.ceil(item.count/config[0].gr_per_bag);
  }else{
    return 0;
  }
}

const getItemValue = (item) => {
   if ((item.unit_measurement_full.trim() !== 'UND' && item.main_category_id === '0004-FRUTAS Y VERDURAS' && item.unityType === 0)
  || (item.unit_measurement_full.trim() !== 'UND' && (item.sub_category_id === 'POLLO' || item.sub_category_id === 'PESCADOS Y MARISCOS') && item.unityType === 0)) {
   
  if (item.unityType === 0) {
    return (parseFloatWithComma(item.pum)  * item.unit_gram) * item.count; 
  } else {
    return parseFloatWithComma(item.pum) * item.count;
  }

} else  
  if (item.unityType === 0) {
    return item.price * item.count;
  } else {
    return parseFloatWithComma(item.pum) * item.count;
  }
}

const totalSelector = createSelector(
  subtotalSelector,
  (subtotalValue) => {
    return (subtotalValue + deliveryCost)
  }
);



const useShoppingCartValues = () => {
  
  const [bagsCount, setBagsCount] = useState(0);
  const [bagsPrice, setBagsPrice] = useState(0);

  const dispatch = useDispatch();
  const order = useSelector(orderSelector);
  const subtotalValue = useSelector((state) => subtotalSelector(state));
  const bagsValue = useSelector((state) => bagsSelector(state));
  const totalValue = useSelector((state) => totalSelector(state) + bagsValue);
  const itemCount = useSelector((state) => itemCountSelector(state));
  const gramCount = useSelector((state) => gramCountSelector(state));

  const handleBagsCount = (items, price) => {
    if(bagsCount !== items){
      setBagsCount(items);
    }
    let pre_price = items * price;
    if(bagsPrice !== pre_price){
      setBagsPrice(pre_price);
    }
  }
  const handleChangeBags = () => {

  }
  useEffect(() => {
    if (bagsCount !== 0) {      
      dispatch(updateBag(bagsCount, bagsPrice))
    }
  }, [bagsCount, bagsPrice, dispatch])

  return {
    deliveryCost, 
    order,
    subtotalValue,
    totalValue,
    itemCount,
    gramCount,
    bagsCount,
    bagsPrice,
    handleBagsCount,
    handleChangeBags
  }
};

export default useShoppingCartValues;
