import {
  call,
  put,
  select,
  takeEvery,
  fork,
  take
} from 'redux-saga/effects';
import { isIOS, isSafari } from "react-device-detect";
import {
  addNewAddressRequest,
  addNewAddressSuccessed,
  addNewAddressFailed,
  modifyAddressRequest,
  deleteAddressRequest,

  addNewProfileRequest,
  addNewProfileSuccessed,
  addNewProfileFailed,
  modifyProfileRequest,
  deleteProfileRequest,
} from './userActions';
import { currentUserIdSelector } from 'selectors';
import { reduxSagaFirebase } from '../../rootSaga';
import firebase from 'firebase/app';

function* updateUserAddresses({ payload: { addresses } }) {
  // select para el selector, que es la referencia a algun valor del local storage.
  const currentUserId = yield select(currentUserIdSelector);

  try {
    // call para ejecutar una función con argumentos
    yield call(reduxSagaFirebase.firestore.updateDocument, `/users/${currentUserId}`, 'addresses', addresses);

    // put para ejecutar acciones
    yield put(addNewAddressSuccessed());
  } catch (error) {
    console.error(error);

    addNewAddressFailed();
  }
}

function* updateUserProfile({ payload: { profile } }) {
  const currentUserId = yield select(currentUserIdSelector);

  try {

    if (profile.profilePhoto) {
      yield call(reduxSagaFirebase.storage.uploadFile, `images/user_photos/${currentUserId}-IMG`, profile.profilePhoto.file, profile.profilePhoto.metadata);
      const url = yield call(reduxSagaFirebase.storage.getDownloadURL, `images/user_photos/${currentUserId}-IMG`);
      yield call(reduxSagaFirebase.auth.updateProfile, {
        photoURL: url
      });
      yield call(reduxSagaFirebase.firestore.updateDocument, `/users/${currentUserId}`, 'photoURL', url);
    }


    yield call(reduxSagaFirebase.auth.updateEmail, profile.email);
    yield call(reduxSagaFirebase.auth.updateProfile, {
      displayName: profile.name,
      // photoURL: profile.photo
    });




    yield call(reduxSagaFirebase.firestore.updateDocument, `/users/${currentUserId}`, 'name', profile.name);
    yield call(reduxSagaFirebase.firestore.updateDocument, `/users/${currentUserId}`, 'last_name', profile.last_name);
    yield call(reduxSagaFirebase.firestore.updateDocument, `/users/${currentUserId}`, 'phone', profile.phone);
    yield call(reduxSagaFirebase.firestore.updateDocument, `/users/${currentUserId}`, 'email', profile.email);
    yield call(reduxSagaFirebase.firestore.updateDocument, `/users/${currentUserId}`, 'identification_card', profile.identification_card);

    yield put(addNewProfileSuccessed());
  } catch (error) {
    console.log(error);
    addNewProfileFailed();
  }
}

function* deleteUserProfile({ payload: { profile } }) {
  // const currentUser = yield select(userSelector);
  const currentUserId = yield select(currentUserIdSelector);

  try {

    // Delete from Auth
    yield call(reduxSagaFirebase.auth.deleteProfile);

    // Delete from firestore
    yield call(reduxSagaFirebase.firestore.deleteDocument, `/users/${currentUserId}`);

    // Actions success
    yield put(deleteProfileRequest());

  } catch (error) {
    console.log(error);
    yield put(addNewProfileFailed());
  }
}


function* loginStatusWatcher() {
  if (!isIOS && !isSafari) {
    const channel = yield call(reduxSagaFirebase.auth.channel);
    while (true) {
      const { user } = yield take(channel);
      if (user) {
        Notification.requestPermission()
          .then((permission) => {
            if (permission === 'granted') {
              firebase.messaging().getToken()
                .then((token) => {
                  firebase.firestore().collection('users').doc(user.uid).set({
                    token: token
                  }, { merge: true })
                    .then(() => { console.log('Push token saved') });
                }).catch((err) => {
                  console.log('Messaging error: ' + err);
                })
            }
          });
      }
    }
  }
}

export function* loginRootSaga() {
  yield fork(loginStatusWatcher);
}

export function* userSaga() {
  // cuando alguna de estas acciones se ejecute, se ejecutará esta función
  yield takeEvery([addNewAddressRequest, modifyAddressRequest, deleteAddressRequest], updateUserAddresses);

  yield takeEvery([addNewProfileRequest, modifyProfileRequest], updateUserProfile);

  yield takeEvery([deleteProfileRequest], deleteUserProfile);
}
