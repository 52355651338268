import React, { useState } from 'react'
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';

import { HideAlertTerms } from 'hooks';

const AlertTerminos = () => {
    
    let currentState = useSelector((state) => (state.alertterms.showAlert));
    
    const [open, setOpen] = useState(currentState!==undefined ? currentState : true);

    const {
        handleShowAlert
    } = HideAlertTerms();
    

    const handleClose = () => {
        //console.log("cerrar y cambiar estado")
        handleShowAlert(false)
        setOpen(false)
    }
    return (
        <Collapse in={open}>
            <Alert severity="warning" variant="filled"
            action={
                <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                    handleClose(false);
                }}
                >
                <CloseIcon fontSize="inherit" />
                </IconButton>
            }>
                Al ingresar a la plataforma estas aceptando los 
                <Link to='/terminos'>
                    <u><b> términos y condiciones</b></u>
                </Link> 
            </Alert>
        </Collapse>
    )
}

export default AlertTerminos;