import { useState } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { useDateValidation } from '../commons';
import { addressesSelector } from 'selectors';
import { stores } from 'utils/const';

const useDeliveryDetailsBehavior = () => {
  const [referenceCode, setReferenceCode] = useState('PAGO'+Math.round(1 + Math.random() * (999 - 1)));
  const [deliveryAddress, setDeliveryAddress] = useState('');
  const [deliveryNeighborhood, setDeliveryNeighborhood] = useState('');
  const [deliveryZone, setDeliveryZone] = useState('');
  const [deliveryCity, setDeliveryCity] = useState('');
  const [pickupStore, setPickupStore] = useState(stores[0]);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [deliveryTime, setDeliveryTime] = useState({date: new Date(), time: 'default'});
  const [deliveryType, setDeliveryType] = useState('');
  const addresses = useSelector(addressesSelector) || [];
  const handleDeliveryAddressChange = (event) => {
    if (event.target.value === 'pickup') {
      setDeliveryAddress('PICK UP - ' + pickupStore);
      setDeliveryNeighborhood('');
      setDeliveryZone('');
      setDeliveryCity('');
    } else {      
      setDeliveryAddress(addresses[event.target.value].value);
      setDeliveryNeighborhood(addresses[event.target.value].neighborhood);
      setDeliveryZone(addresses[event.target.value].zone);
      setDeliveryCity(addresses[event.target.value].city ? addresses[event.target.value].city : 'Cali');
    }
  };
  const handlePickupStoreChange = (event) => {
    setPickupStore(event.target.value);
    setDeliveryAddress('PICK UP -' + event.target.value);
    setDeliveryNeighborhood('');
    setDeliveryZone('');
  }
  const handleDeliveryTimeChange = (value, type) => {  // Time delivery
    setDeliveryTime({...deliveryTime, [type] : value});
  }
  const handleDeliveryTypeChange = (event) => { // Type delivery
    setDeliveryType(event.target.value);
  }
  const handlePaymentMethodChange = (event) => { // Payment
    setPaymentMethod(event.target.value);
  };  
  const shouldDisableConfirmButton = deliveryAddress.length === 0 || paymentMethod.length === 0 || deliveryType.length === 0;
  const confirmButtonClassnames = classnames('btn btn-success btn-block', {
    'disabled': shouldDisableConfirmButton
  });
  //validate current time to display advice
  const { isStoreClosed, deliveryDate } = useDateValidation();
  
  return {
    referenceCode,
    addresses,
    confirmButtonClassnames,
    deliveryAddress,
    deliveryDate,
    isStoreClosed,
    paymentMethod,
    handleDeliveryAddressChange,
    handlePaymentMethodChange,
    handlePickupStoreChange,
    handleDeliveryTimeChange,
    handleDeliveryTypeChange,
    shouldDisableConfirmButton,
    deliveryNeighborhood,
    deliveryZone,
    deliveryCity,
    pickupStore,
    deliveryTime,
    deliveryType,
    setReferenceCode
  };
};

export default useDeliveryDetailsBehavior;
