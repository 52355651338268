import React, { memo, Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
//import CarouselAlliesMobile from "components/carousels/allies/CarouselAlliesMobile";
import CarouselProducts from "components/carousels/products/CarouselProducts";
import { splitCategoria } from "utils/format";
import InputSearchMobile from "../../components/inputSearchMobile/InputSearchMobile";
import { useSelector } from "react-redux";
import CarouselImageDesktop from "components/carousels/allies/CarouselImageDesktop";
import { ChangeCitiesHome, useExistsProducts } from "hooks";
import CarouselOfferProducts from "components/carousels/products/CarouselOfferProducts";
const HomeMobile = ({ categories, currentCity }) => {
  const visibilityCities = useSelector((state) => state.cities.visibility);
  const { setExists, exists, existsProducts } = useExistsProducts();

  useEffect(() => {
    setExists([]);
  }, [currentCity]);

  const { auth, profile } = useSelector((state) => ({
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  }));

  const { name } = profile;

  const { handleToggleModal } = ChangeCitiesHome();

  const handleShowModal = () => {
    handleToggleModal(true);
  };
  return (
    <div>
      <CarouselImageDesktop />
      <div className="page-login page-login-full bottom-20">
        {auth.uid ? (
          <h2
            class="category-title"
            style={{
              marginBottom: "30px",
              marginTop: "20px",
              color: "#01742b",
              fontSize: "25px",
            }}
          >
            Hola, {name} <br></br>{" "}
            <p>
              Busca tus productos para tu mercado, del resto nos encargamos
              nosotros.
            </p>
          </h2>
        ) : (
          <h2
            class="category-title"
            style={{
              marginBottom: "30px",
              marginTop: "20px",
              color: "#01742b",
              fontSize: "25px",
            }}
          >
            Bienvenido a La Montaña Agromercados <br></br>
            <p>
              Inicia buscando tus productos o mira nuestras tiendas oficiales.
            </p>{" "}
          </h2>
        )}
        {visibilityCities !== "hidden" && (
          <>
            <div>
              Actualmente estas visualizando los precios de la ciudad{" "}
              <b>{currentCity}</b> <br />
              <button
                type="button"
                style={{
                  margin: "0",
                  color: "#01742b",
                  background: "transparent",
                  textDecoration: "underline",
                }}
                onClick={() => handleShowModal()}
              >
                Cambiar ciudad.
              </button>
            </div>
          </>
        )}

        {/* <div className="slider-margins bottom-30">
        <h3 className="color-default category-title">
        Ingresa A Nuestras Tiendas Oficiales
              </h3>
          <CarouselAlliesMobile />
        </div> */}

        <form>
          <div className="page-login-field top-15">
            <InputSearchMobile />
          </div>
        </form>
        <div className="slider-margins bottom-20">
        <div className="row bottom-10">
                <div className="col-md-12">
                  <h3 className="color-default category-title-content">
                    {splitCategoria("Ofertas")}
                  </h3>
                </div>
              </div>
              {categories.length > 0&&
              <CarouselOfferProducts
              categories={categories}
            />
              }
          {categories.map((category, index) => (
            currentCity &&
            !exists.find((cat) => cat === category.id) && (
              <Fragment key={`category-${index}`}>
                <div className="bottom-10 top-30">
                  <div className="col-md-12">
                    <h3 className="color-default category-title-content">
                      {splitCategoria(category.name || category.id)}
                      <Link
                        to={`/categoria/${category.id}`}
                        className="color-default top-5 right-10 font-13 float-right"
                      >
                        Ver más <i className="fa fa-chevron-right" />
                      </Link>
                    </h3>
                  </div>
                </div>
                <CarouselProducts
                  categories={categories}
                  category={category.id}
                  existsProducts={existsProducts}
                />
                <div className="decoration top-30"></div>
              </Fragment>
            )
          ))}
        </div>
      </div>
    </div>
  );
};

HomeMobile.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object),
};

HomeMobile.defaultProps = {
  categories: [],
};

export default memo(HomeMobile);
