import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from "react-router-dom";
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

const InputSearchMobile = props => {

    // value storage input value
    const [value, setValue] = useState( '' );
    const [alertSearch, setAlertSearch] = useState( false )
    const history = useHistory();

    const handleChange = ( event ) => {
        const value = event.target.value;
        setValue(value);
    }

    const handleSubmit = ( event ) => {
        event.preventDefault();
        if(!value){
            setAlertSearch(true)
            return false;
        }
        history.push(`/buscar/${ value }`);
    }

    const handleCloseAlertSearch = () => {
        setAlertSearch(false)
    }

    return (
        <div>
            <Snackbar open={alertSearch} autoHideDuration={6000} onClose={handleCloseAlertSearch}>
                <div className="alert">
                    <MuiAlert elevation={6} variant="filled" severity="warning" onClose={handleCloseAlertSearch}>
                        Debes ingresar un criterio de búsqueda.
                    </MuiAlert>
                </div>
            </Snackbar>
            <form onSubmit={ handleSubmit } className="form-grid">
                <input 
                    style={{}}
                    onChange={ handleChange }
                    type="text" 
                    placeholder={ props.placeholder }
                    value= { value }
                    className="pl-30" 
                />
                <button className="form-button button-green" type="submit">
                    <i className={ `${props.icon} form-icon` } />
                    {/* Buscar */}
                </button>
            </form>
        </div>
    );
}


InputSearchMobile.propTypes = {
    icon: PropTypes.string,
    placeholder: PropTypes.string

};

InputSearchMobile.defaultProps = {
    icon: 'fa fa-search color-white',
    placeholder: 'Busca lo que más te guste'
};

export default InputSearchMobile;
