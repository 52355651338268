import { useSelector, useDispatch } from "react-redux";
import { addedStatusSelector } from 'selectors';
import { noItemAdded } from "store/domains/shoppingCart/shoppingCartActions";

const useProductGridsBehavior = () => {
  const dispatch = useDispatch();
  const addedProduct = useSelector(state => addedStatusSelector(state));
  const noAddedProduct = () => {
    dispatch(noItemAdded());
  };

  return {
    addedProduct,
    noAddedProduct
  };
};

export default useProductGridsBehavior;
