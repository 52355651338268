import {combineReducers} from "redux";
import {firestoreReducer} from "redux-firestore";
import {firebaseReducer} from "react-redux-firebase";
import authReducer from "../pages/auth/store/reducers/authReducer";
import productsReducer from './domains/products/productsActions';
import categoriesReducer from './domains/category/categoryActions';
import categoryDetailReducer from './domains/categoryDetail/categoryDetailActions';
import shoppingCartReducer from './domains/shoppingCart/shoppingCartActions';
import ordersReducer from './domains/orders/ordersActions';
import configsReducer from './domains/config/configActions';
import citiesAction from './domains/cities/citiesAction';
import alerttermsAction from './domains/alertterms/alerttermsAction';

const rootReducer = combineReducers({
    auth: authReducer,
    categories: categoriesReducer,
    categoryDetail: categoryDetailReducer,
    orders: ordersReducer,
    shoppingCart: shoppingCartReducer,
    products: productsReducer,
    configs: configsReducer,
    cities: citiesAction,
    alertterms: alerttermsAction,
    firestore: firestoreReducer,
    firebase: firebaseReducer
});

export default rootReducer;
