import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// import firebase from 'config/firebaseConfig';

const notFoundImage = 'https://firebasestorage.googleapis.com/v0/b/prueba-lamontana.appspot.com/o/Imagen-no-encontrada-app-la-monta%C3%B1a.jpg?alt=media&token=3ac58f10-11c7-457f-850a-fcadbef87275';
const othersImage = 'https://firebasestorage.googleapis.com/v0/b/prueba-lamontana.appspot.com/o/others.png?alt=media&token=ea693704-d1f7-4ae5-8922-ebf1c98fa552';

const Image = ({ id, className, isOthers }) => {
  // const storage = firebase.storage();
  const [path, setPath] = useState(notFoundImage);

  useEffect(() => {
    if (isOthers) {
      setPath(othersImage);
    } else {
      setPath(`https://res.cloudinary.com/carvajal/image/upload/dpr_auto,q_50,f_auto,w_auto/productos/${id}`);
      //setPath(notFoundImage);
    }
    
  }, [id, isOthers]);

  return (
    <img alt={`product-${id}`} src={path} className={`${className} mx-auto d-block`} onError={(e)=>{e.target.onerror = null; e.target.src=notFoundImage}} />
  );
};

Image.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  isOthers: PropTypes.bool,
};

Image.defaultProps = {
  id: '',
  className: '',
  isOthers: false,
};

export default Image;
