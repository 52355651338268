import formatMoney from "./formatMoney";

export const formatCurrency = (value) =>
  "$ " + formatMoney(value !== undefined ? value : 0, 0);

export const formatCurrencyPUM = (value, decimal = 2) =>
  formatMoney(value !== undefined ? value : 0, decimal);

export const formatPercent = (value) => `${Math.round(value)}%`;

export const capitalizeString = (string) => `${string.toLowerCase()}`;

export const capitalizeString2 = (string) =>
  `${string.replace(
    /^([a-z\u00E0-\u00FC])|\s+([a-z\u00E0-\u00FC])/g,
    function ($1) {
      return $1.toUpperCase();
    }
  )}`;

export const capitalizeString3 = (string) => {
  let text = string
    .toLowerCase()
    .replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());
  // convertir en minuscula las 'Y', 'A' y 'De' que esten entre espacios
  return text.replace(/(\s(Y|A|De)\s)/, (m) => m.toLowerCase());
};

export const splitCategoria = (string) => {
  if (string === "0034-REPOSTERIA") string = "0034-REPOSTERÍA";
  if (string === "0012-BEBIDAS REFRESCANTES LIQUIDAS")
    string = "0012-BEBIDAS REFRESCANTES LÍQUIDAS";
  if (string === "0011-ALINOS CONDIMENTOS") string = "0011-ALIÑOS CONDIMENTOS";
  if (string === "0024-LACTEOS") string = "0024-LÁCTEOS";
  if (string === "0028-PANADERIA") string = "0028-PANADERÍA";
  if (string === "0019-AROMATICA Y TE") string = "0019-AROMÁTICA Y TÉ";
  let text = string.split("-");
  text = text.length === 1 ? text[0] : text[1];
  return capitalizeString3(text);
};
export const parseFloatWithComma = (string = "") =>
  parseFloat(string.replace(",", ".")); //.replace(',', '.')

export const getMaturity = (maturity) => {
  switch (maturity) {
    case "0":
      return "Verde";
    case "1":
      return "Normal";
    case "2":
      return "Maduro";
    default:
      return "Verde";
  }
};

export const getPrice = (currentCity, product) => {
  switch (currentCity) {
    case "Cali":
      return product.price_neto_cali;
    case "Medellin":
      return product.price_neto_medellin;
    default:
      return product.price_neto;
  }
};

export const getPum = (currentCity, product) => {
  switch (currentCity) {
    case "Cali":
      return product.pum_cali;
    case "Medellin":
      return product.pum_medellin;
    default:
      return product.pum;
  }
};
