import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewProfileRequest,
  deleteProfileRequest,
} from "store/domains/user/userActions";
import { signOut } from "pages/auth/store/actions/authActions";
import avatar from "../../../images/public_avatar.png";
import Swal from "sweetalert2";

const ProfileDesktop = ({ profile }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [profileFields, setProfileFields] = useState(profile);
  const [buttonDisabled, setbuttonDisabled] = useState(true);
  const [labelPhoto, setLabelPhoto] = useState("Ningún archivo seleccionado");
  const { auth } = useSelector((state) => ({
    auth: state.firebase.auth,
    loadingLogin: state.auth.loading,
  }));
  // console.log({profileFields});

  const handleSubmit = () => {
    if (isLogged()) {
      const newProfile = {
        name: profileFields.name,
        last_name: profileFields.last_name ? profileFields.last_name : "",
        phone: profileFields.phone ? profileFields.phone : "",
        email: profileFields.email,
        identification_card: profileFields.identification_card,
        profilePhoto: profileFields.profilePhoto
          ? profileFields.profilePhoto
          : undefined,
      };

      dispatch(addNewProfileRequest(newProfile));
      Swal.fire({
        icon: "success",
        title: "Perfil actualizado!",
        showConfirmButton: false,
        timer: 4000,
      });

      if (newProfile.profilePhoto) {
        setTimeout(() => {
          history.push("/");
        }, 4000);
      }
    } else {
      // TODO: mostrar un mensaje al usuario cuando por tiempo se desloguee, y le solicite volver a logearse
      console.log("unlogged user");
    }
  };
  const handleChange = (event) => {
    setProfileFields({
      ...profileFields,
      [event.target.name]: event.target.value,
    });
    if (!profileFields.identification_card || profileFields.identification_card.length < 5) {
      setbuttonDisabled(true);
    } else setbuttonDisabled(false);
  };

  const handleSignOut = () => {
    dispatch(signOut());
    history.push("/");
  };

  const handleDeleteProfile = () => {
    if (isLogged()) {
      Swal.fire({
        title: "¿Desea eliminar su perfil?",
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, elimina mi perfil!",
        cancelButtonText: "No, cancelar!",
      }).then((result) => {
        if (result.value) {
          dispatch(deleteProfileRequest(profileFields));
          console.log("Profile deleted");
          Swal.fire("Eliminado!", "Tu perfil ha sido eliminado.", "success");
          history.push("/");
        }
      });
    } else {
      console.log("Unlogged User");
    }
  };

  const handleFile = (event) => {
    // console.log(event.target.files[0]);
    const file = event.target.files[0];

    if (file) {
      var tempPath = URL.createObjectURL(file);
      const name = +new Date() + "-" + file.name;
      const metadata = { contentType: file.type };
      setProfileFields({
        ...profileFields,
        profilePhoto: {
          name,
          metadata,
          file,
        },
        photoURL: tempPath,
      });
      setLabelPhoto(`Previsualizando: ${file.name}`); // muestra el nombre archivo seleccionado

      setbuttonDisabled(false);
    }
  };

  const isLogged = () => {
    if (auth.uid) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <header className="page-header page-title">
        <h2>Mi perfil</h2>
      </header>
      <div className="row">
        <div className="col-lg-12 mb-3 content-padding">
          <section className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-4">
                  <div className="row">
                    <div className="col-md-12 top-40">
                      <div
                        className="profile-image-desk"
                        style={{ textAlign: "center" }}
                      >
                        <div className="profile-image-box-desk">
                          <img
                            src={profileFields.photoURL || avatar}
                            alt="avatar"
                          />
                        </div>

                        <div className="btn-pic-upload-desk">
                          <input
                            type="file"
                            name="photoURL"
                            id="photoURL"
                            className="inputfile"
                            onChange={handleFile}
                          />
                          <label htmlFor="photoURL">
                            <span>
                              <i
                                className="fa fa-camera"
                                style={{ cursor: "pointer" }}
                              />
                            </span>
                          </label>
                        </div>
                        <label htmlFor="photoURL">{labelPhoto}</label>
                      </div>
                    </div>
                    <div className="col-md-12 align-center">
                      <h2 className="color-default align-center top-10">
                        {profileFields.last_name
                          ? `${profileFields.name} ${profileFields.last_name}`
                          : profileFields.name}
                      </h2>
                    </div>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="row top-40">
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-9">
                          <p className="bottom-0 font-12">Nombre</p>
                          <input
                            className="form-control"
                            name="name"
                            value={profileFields.name}
                            type="text"
                            onChange={handleChange}
                          />
                          {/* <p className="color-default">{profileFields.name}</p> */}
                        </div>
                        <div className="col-md-3 align-right top-15">
                          {/* <a href className="color-green bottom-0">Editar</a> */}
                        </div>
                      </div>
                      <div className="row top-10">
                        <div className="col-md-9">
                          <p className="bottom-0 font-12">Apellido</p>
                          <input
                            className="form-control"
                            name="last_name"
                            value={profileFields.last_name}
                            type="text"
                            onChange={handleChange}
                          />
                          {/* <p className="color-default">{profileFields.last_name}</p> */}
                        </div>
                        <div className="col-md-3 align-right top-15">
                          {/* <a href className="color-green bottom-0">Editar</a> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-9">
                          <p className="bottom-0 font-12">Correo electrónico</p>
                          <input
                            className="form-control"
                            name="email"
                            value={profileFields.email}
                            type="text"
                            onChange={handleChange}
                          />
                          {/* <p style={{margin: '0 0 1.25rem'}} className="color-default">{profileFields.email}</p> */}
                        </div>
                        <div className="col-md-3 align-right top-15">
                          {/* <a href className="color-green bottom-0">Editar</a> */}
                        </div>
                      </div>
                      <div className="row top-10">
                        <div className="col-md-9">
                          <p className="bottom-0 font-12">Teléfono</p>
                          <input
                            className="form-control"
                            name="phone"
                            value={profileFields.phone}
                            type="number"
                            onChange={handleChange}
                          />
                          {/* <p className="color-default">{profileFields.phone}</p> */}
                        </div>
                        <div className="col-md-3 align-right top-15">
                          {/* <a href className="color-green bottom-0">Editar</a> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row top-10">
                        <div className="col-md-9">
                          <p className="bottom-0 font-12">Cedula</p>
                          <input
                            className="form-control"
                            name="identification_card"
                            value={profileFields.identification_card}
                            type="number"
                            onChange={handleChange}
                          />
                          {/* <p className="color-default">{profileFields.phone}</p> */}
                        </div>
                        <div className="col-md-3 align-right top-15">
                          {/* <a href className="color-green bottom-0">Editar</a> */}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="row top-20">
                                    <div className="col-md-9">
                                        <p className="bottom-0 font-12">Foto de perfil</p>
                                        <input onChange={ handleFile } name="photoURL" type="file" ref={fileRef}/>
                                    </div>
                                </div> */}

                  <div className="row top-20">
                    <div className="col-md-4">
                      <button
                        className="btn btn-success btn-block"
                        onClick={handleSubmit}
                        disabled={buttonDisabled}
                      >
                        Guardar cambios
                      </button>
                    </div>
                    <div className="col-md-4">
                      <button
                        className="btn btn-danger btn-block"
                        onClick={handleSignOut}
                      >
                        Cerrar sesión
                      </button>
                    </div>
                    <div className="col-md-4">
                      <button
                        className="btn btn-default btn-block"
                        onClick={handleDeleteProfile}
                      >
                        Borrar cuenta
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

ProfileDesktop.propTypes = {
  profile: PropTypes.object,
};

ProfileDesktop.defaultProps = {
  profile: {},
};

export default ProfileDesktop;
