import React, { useState } from "react";
import PropTypes from "prop-types";
import DetailProductDesktop from "components/modals/DetailProductDesktop";
import Image from "components/image/Image";
import {
  formatCurrency,
  capitalizeString,
  formatCurrencyPUM,
  parseFloatWithComma,
  getPrice,
} from "utils/format";
// import Img from 'react-cloudinary-lazy-image';
import LazyLoad from "react-lazyload";
import { useDetailedProductBehavior } from "hooks";
import { useSelector } from "react-redux";

const ItemDesktop = ({ product, handleClose, offerProduct }) => {
  const currentCity = useSelector((state) => state.cities.city);
  const { discount, id_item, pum, title, unit_measurement_pum } = product;
  let price_neto = getPrice(currentCity, product);
  product.price = price_neto;
  const { handleSubmit } = useDetailedProductBehavior(product, handleClose);
  const [array, setArray] = useState([]);

  const [displayDetail, setDisplayDetail] = useState(false);
  const toggleDetail = () => {
    setDisplayDetail(!displayDetail);
    if (displayDetail === false) {
      setArray([
        ...array,
        {
          id: id_item,
          counter: 1,
        },
      ]);
    }
  };
  const shouldDisplayDiscountData = +discount !== 0;
  const shouldDisplayOfferImg = offerProduct;
  const isNewProduct = product.new_product === true;
  return (
    <>
      <DetailProductDesktop
        show={displayDetail}
        handleClose={toggleDetail}
        product={product}
      />
      <div className="product-container">
        <div className="overlay-product">
          <button
            className="btn btn-warning button-overlay-btn1"
            onClick={toggleDetail}
          >
            Ver
          </button>
          <button
            className="btn btn-warning button-overlay-btn2"
            onClick={() => {
              handleSubmit(true);
            }}
          >
            Agregar
          </button>
        </div>
        <div className="ima-productos border-radius-image">
          {shouldDisplayDiscountData && (
            <div className="label-offer">{discount}% </div>
          )}
          {shouldDisplayOfferImg && (
            <img
              className="offer-img"
              src={process.env.PUBLIC_URL + "/images/oferta.png"}
              alt="offer"
            />
          )}
          {isNewProduct && (
            <img
              className="new-product-img"
              src={process.env.PUBLIC_URL + "/images/Producto_nuevo.png"}
              alt="new-product"
            />
          )}
          <div className="full-width">
            <LazyLoad once>
              <Image id={id_item} className="img-fluid img-item" />
            </LazyLoad>
          </div>
        </div>
        <div
          className="content-item-products"
          style={{ minHeight: "160px", position: "relative" }}
        >
          <h4
            className="black-title bottom-0 text-center"
            style={{ minHeight: "43px" }}
          >
            {capitalizeString(title)}
          </h4>
          {/* <p className="bottom-0 font-12 unit-measurement">{quantity_pum+" "+capitalizeString(unit_measurement_full)}</p> */}
          <p className="bottom-0 font-12 unit-measurement">sku: {id_item}</p>
          <p className="bottom-0 color-default font-12 text-center">
            {" "}
            {capitalizeString(String(unit_measurement_pum))} a $
            {formatCurrencyPUM(parseFloatWithComma(pum), 1)}
            <div
              style={{
                minHeight: "40px",
                backgroundColor: "#388e3c",
                borderBottomLeftRadius: "6px",
                borderBottomRightRadius: "6px",
              }}
            >
              <p className="bottom-0 price">
                <span className="price-bold">{formatCurrency(price_neto)}</span>
                {shouldDisplayDiscountData && (
                  <span className="color-gray left-20 old-price">
                    {formatCurrency(product.price)}
                  </span>
                )}
              </p>
            </div>
            {/* {shouldDisplayDiscountData && (
              <span>
                <i className="fa fa-tag color-green icon-round-border font-9 left-20" />
                Ahorra {formatCurrency(product.price - product.price_neto)}
              </span>) */}
          </p>
        </div>
      </div>
    </>
  );
};

ItemDesktop.propTypes = {
  product: PropTypes.object,
};

ItemDesktop.defaultProps = {
  product: {},
};

export default ItemDesktop;
