import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { loadProductsPerCategoryRequest } from 'store/domains/categoryDetail/categoryDetailActions';
import { loadProductsRequest } from "store/domains/products/productsActions";
import SubCategoryGridMobile from './SubCategoryGridMobile';
import SubCategoryGridDesktop from './SubCategoryGridDesktop';
import { loadCategoriesRequest } from "store/domains/category/categoryActions";
import { isBrowser } from "react-device-detect";

const SubCategory = () => {
	const { categoryName ,subcategoryName } = useParams();
    const products = useSelector((state) => (state.categoryDetail));
	const categories = useSelector((state) => (state.categories.list));
	const dispatch = useDispatch();
	const currentCity = useSelector((state) => (state.cities.city));
	const city = currentCity? currentCity.toLowerCase() : "medellin";

    const CategoryLayout = isBrowser ? SubCategoryGridDesktop : SubCategoryGridMobile;

	useEffect(() => {
		// fetch all products per category, and filter in category layout if a subcategory is provided
		dispatch(loadProductsPerCategoryRequest(categoryName, city));
		dispatch(loadProductsRequest());
		dispatch(loadCategoriesRequest());
	}, [dispatch, categoryName, city]);

	return (
		<CategoryLayout products={products.products} category={categoryName} subcategory={subcategoryName} categories={categories} />
	)
};

export default SubCategory;