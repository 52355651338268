import React from "react";
import PropTypes from "prop-types";
import Image from "components/image/Image";
import { formatCurrency, getMaturity, parseFloatWithComma } from "utils/format";
import { useShoppingCartActions } from "hooks";
import LazyLoad from "react-lazyload";
import styles from "./ShoppingCartItem.module.css";

const ShoppingCartMobileItem = ({ index, item }) => {
  const {
    currentCount,
    handleDecrement,
    handleIncrement,
    handleDeleteItem,
  } = useShoppingCartActions(index, item);
  const {
    unit_gram,
    id_item,
    price,
    title,
    unityType,
    pum,
    maturity,
    main_category_id,
  } = item;

  const maturityClass = "maturity-" + maturity;

  return (
    <>
      <div className="store-cart-1">
        <LazyLoad once>
          <Image
            id={id_item}
            className="preload-image rounded-image product-cart"
          />
        </LazyLoad>
        <h4 className="black-title bottom-10">{title}</h4>
        {main_category_id === "0004-FRUTAS Y VERDURAS" && (
          <span className="color-gray font-14">
            Madurez:{" "}
            <b className={styles[maturityClass]}>{getMaturity(maturity)}</b>
          </span>
        )}
        <span className="color-gray font-14">
          Medido en: {unityType === 0 ? <b>Unidades </b> : <b>Gramos</b>}
        </span>
        <em className="color-green">
          <b>
            {(item.unit_measurement_full !== "UND" &&
              item.main_category_id === "0004-FRUTAS Y VERDURAS" &&
              item.unityType === 0) ||
            (item.unit_measurement_full !== "UND" &&
              (item.sub_category_id === "POLLO" ||
                item.sub_category_id === "PESCADOS Y MARISCOS") &&
              item.unityType === 0)
              ? formatCurrency(
                  unityType === 0
                    ? parseFloatWithComma(pum) * unit_gram * currentCount
                    : parseFloatWithComma(pum) * currentCount
                )
              : formatCurrency(
                  unityType === 0
                    ? price * currentCount
                    : parseFloatWithComma(pum) * currentCount
                )}
          </b>
        </em>
        <div className="store-cart-qty">
          <div className="input-group">
            <input
              type="button"
              value="-"
              className="button-minus"
              data-field="quantity"
              onClick={handleDecrement}
            />
            <input
              onChange={() => {}}
              type="number"
              step="1"
              max=""
              value={currentCount}
              name="quantity"
              className="quantity-field"
            />
            <input
              type="button"
              value="+"
              className="button-plus"
              data-field="quantity"
              onClick={handleIncrement}
            />
          </div>
        </div>
        <button className="store-cart-1-remove " onClick={handleDeleteItem}>
          <i className="fa fa-trash-alt" /> eliminar
        </button>
      </div>
      <div className="decoration top-30" />
    </>
  );
};

ShoppingCartMobileItem.propTypes = {
  index: PropTypes.number.isRequired,
  item: PropTypes.object,
};

ShoppingCartMobileItem.defaultProps = {
  item: {},
};

export default ShoppingCartMobileItem;
