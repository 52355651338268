import { call, put, takeEvery } from 'redux-saga/effects';
import {
  loadProductsPerCategoryRequest,
  loadProductsPerCategorySuccess,
  loadProductsPerCategoryFailed
} from './categoryDetailActions';
import { reduxSagaFirebase } from '../../rootSaga';
import firebase from 'config/firebaseConfig';

function* fetchProductsPerCategory({ payload: {categoryName, city} }) {
  try {
    const origin = city==='cali'?'Cali':'Medellin'
    firebase.firestore();
    const documentRef = firebase.firestore().collection(`/categories/${categoryName}/products`)
                                            .where('status', "==", true)
/*                                             .where('origin','in',[
                                              "Ambos",
                                              origin
                                            ]) */
                                            .where('stock_'+city, ">=", 1)
/*                                             .orderBy('stock_'+city,'asc')
                                            .orderBy('description', 'asc'); */
    //const documentRef = firebase.firestore().collection(`/categories/${categoryName}/products`).where('status', "==", true).orderBy('description', 'asc');
    const snapshot = yield call(reduxSagaFirebase.firestore.getCollection, documentRef);
    const documents = snapshot.docs;
    const products = yield documents.map((document) => (document.data()));

    yield put(loadProductsPerCategorySuccess(products));
  } catch (error) {
    console.error(error);

    yield put(loadProductsPerCategoryFailed());
  }
}

export function* categoryDetailSaga() {
  yield takeEvery([loadProductsPerCategoryRequest], fetchProductsPerCategory);
}
