import * as reduxModule from "redux";
import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import { persistStore, persistReducer } from "redux-persist";
import rootReducer from "./rootReducer";
import rootSaga from "./rootSaga";
import thunk from "redux-thunk";
import { getFirestore, reduxFirestore } from "redux-firestore";
import firebaseConfig from "../config/firebaseConfig";
import { getFirebase, reactReduxFirebase } from "react-redux-firebase";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

const persistConfig = {
  key: "root",
  storage
};
const persistedReducer = persistReducer(persistConfig, rootReducer)
/*
Fix for Firefox redux dev tools extension
https://github.com/zalmoxisus/redux-devtools-instrument/pull/19#issuecomment-400637274
 */
reduxModule.__DO_NOT_USE__ActionTypes.REPLACE = "@@redux/INIT";

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers =
  process.env.NODE_ENV !== "production" &&
  typeof window === "object" &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;
const enhancer = composeEnhancers(
  applyMiddleware(
    thunk.withExtraArgument({ getFirebase, getFirestore }),
    sagaMiddleware
  ),
  reduxFirestore(firebaseConfig),
  reactReduxFirebase(firebaseConfig, {
    useFirestoreForProfile: true,
    userProfile: "users",
    attachAuthIsReady: true
  })
);
const store = createStore(persistedReducer, enhancer);

sagaMiddleware.run(rootSaga);

export default store;

export const persistor = persistStore(store);
