import React, { useState } from 'react';
import PropTypes from "prop-types";
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from "react-router-dom";
import { Loading } from "components/common/Loading";
import { forgotPassword } from "../store/actions/authActions";

const authSelector = (state) => ({
    authError: state.auth.authError,
    loadingLogin: state.auth.loading,
    forgotPwSended: state.auth.forgotPwSended
});

const ForgotPwModal = ({ handleForgotPw, handleClose, handleChangeAlert }) => {

    const dispatch = useDispatch();
    const {
        authError,
        loadingLogin,
        forgotPwSended
    } = useSelector(authSelector);

    const [email, setEmail] = useState('');
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(forgotPassword(email));
    }

    return (
        <div className="col-md-7 bottom-0 top-10">
            <div className="row bottom-10">
                <div className="col-md-2" />
                <div className="col-md-8 align-center">
                    <h2 className="color-default">Recupera tu cuenta</h2>
                </div>
                <div className="col-md-2" onClick={handleClose}>
                    <div className="color-default" style={{ cursor: "pointer" }} >
                        <h3 className="color-default">
                            <i className="fa fa-times" />
                        </h3>
                    </div>
                </div>
            </div>

            <div className="row bottom-30">
                <div className="col-md-2" />
                <div className="col-md-8">
                    <form onSubmit={handleSubmit} className="bottom-30">
                        <div className="form-group row">
                            <div className="col-lg-12">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="email"
                                    placeholder="Escribe tu correo electrónico"
                                    onChange={handleEmailChange}
                                    value={email}
                                />
                            </div>
                        </div>
                        {
                            forgotPwSended ?
                                <div className="align-center">
                                    <i className="far fa-check-circle color-green"></i>
                                    <span> Se ha enviado exitosamente el enlace de recuperación al correo {email}</span>
                                </div>
                                :
                                <div>
                                    <div className="color-red center bottom-10">
                                        {authError ? <span>{authError}</span> : null}
                                    </div>
                                    <p className="align-center bottom-20">
                                        <span onClick={handleForgotPw} className="color-default">
                                        Escribe tu correo electrónico y te enviaremos instrucciones para recuperar
                                        tu cuenta.
                                        </span>
                                    </p>
                                    {
                                        loadingLogin
                                            ?
                                            <Loading />
                                            :
                                            <button type="submit" className="btn btn-success btn-block bottom-30">
                                                Recuperar cuenta
                                            </button>
                                    }
                                </div>
                        }
                        <p className="align-center bottom-20 top-10">
                            <span onClick={handleForgotPw} className="color-green" style={{ cursor: "pointer" }}>
                                Volver a inicio de sesión
                            </span>
                        </p>

                    </form>
                    <br />
                    <p className="align-center color-default bottom-0 top-10">
                        ¿No te has registrado?
                    </p>
                    <p className="align-center bottom-10">
                        <span
                            onClick={() => { handleChangeAlert(); handleForgotPw(); }}
                            className="color-green"
                            style={{ cursor: "pointer" }}
                        >
                            Ingresa aquí
                        </span>
                    </p>
                    <NavLink to="/terminos" onClick={handleClose}>
                        <p className="align-center bottom-0">
                            <span className="color-green align-center" style={{ cursor: "pointer" }}>
                                Ver terminos y condiciones
                            </span>
                        </p>
                    </NavLink>
                </div>
                <div className="col-md-2" />
            </div>
        </div>
    )
}

ForgotPwModal.propTypes = {
    handleForgotPw: PropTypes.func,
    handleChangeAlert: PropTypes.func,
    handleClose: PropTypes.func
};

ForgotPwModal.defaultProps = {
    handleForgotPw: () => { },
    handleChangeAlert: () => { },
    handleClose: () => { }
};

export default ForgotPwModal
