import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { hideAlert } from 'store/domains/alertterms/alerttermsAction'

const HideAlertTerms = () => {
    const dispatch = useDispatch();
    
    const [showAlert, setShowAlert] = useState(undefined);

    const handleShowAlert = (show) => {
        setShowAlert(show);
    }

    useEffect(() => {
        if( showAlert !== undefined ){
            dispatch(hideAlert(showAlert))
        }
    },[ showAlert, dispatch ])

    return {
        handleShowAlert
    }
}

export default HideAlertTerms;