import {
  call,
  fork,
  put,
  select,
  takeEvery,
  // fork
} from 'redux-saga/effects';
import {
  currentOrderRequest,
  currentOrderSuccessed,
  currentOrderFailed,
  finishOrderRequest,
  cancelOrderRequest,
  cancelOrderSuccessed,
  orderListRequest,
  orderListSuccessed,
  orderListFailed,
} from './ordersActions';
import firebase from 'config/firebaseConfig';
import { currentUserIdSelector } from 'selectors';
import { clearShoppingCart } from 'store/domains/shoppingCart/shoppingCartActions';
import { reduxSagaFirebase } from '../../rootSaga';

function* syncOrderStatus({ payload: { orderId }}) {
  try {
    yield fork(reduxSagaFirebase.firestore.syncDocument, `/orders/${orderId}`, {
      successActionCreator: (currentOrderData) => (currentOrderSuccessed(currentOrderData)),
      transform: (documentSnapshot) => (documentSnapshot.data())
    }); 
  } catch (error) {
    console.error(error);

    yield put(currentOrderFailed());
  }
}

function* finishCurrentOrder({ payload: { orderId }}) {
  try {
    // yield call(
    //   reduxSagaFirebase.firestore.updateDocument,
    //   `/orders/${orderId}`,
    //   'status',
    //   'FINALIZADO');

    // clear current shopping cart
    yield put(clearShoppingCart());
  } catch (error) {
    console.error(error);

    yield put(currentOrderFailed());
  }
}

function* cancelCurrentOrder({ payload: { orderId, statusDates }}) {
  try {
    let shouldSendRequests = true;
    while(shouldSendRequests) {
      if(statusDates){
        yield call(
          reduxSagaFirebase.firestore.updateDocument,
          `/orders/${orderId}`,
          'statusDates',
          statusDates);
      }
      yield call(
        reduxSagaFirebase.firestore.updateDocument,
        `/orders/${orderId}`,
        'status',
        'CANCELADO');
        shouldSendRequests = false;
      // clear current shopping cart
      yield put(clearShoppingCart());
      yield put(cancelOrderSuccessed());
    }
  } catch (error) {
    console.error(error);

    yield put(currentOrderFailed());
  }
}

function* fetchOrderList() {
  const currentUserId = yield select(currentUserIdSelector);

  try {
    const documentRef = firebase.firestore().collection(`/orders`).where('userId', "==", currentUserId).orderBy("createdAt", "desc");
    const snapshot = yield call(reduxSagaFirebase.firestore.getCollection, documentRef);
    const documents = snapshot.docs;
    const orders = yield documents.map((document) => ({
      id: document.id,
      ...document.data()
    }));

    yield put(orderListSuccessed(orders));
  } catch(error) {
    console.error(error);

    orderListFailed();
  }
}

export function* ordersSaga() {
  yield takeEvery([currentOrderRequest], syncOrderStatus);
  yield takeEvery([orderListRequest], fetchOrderList);
  yield takeEvery([finishOrderRequest], finishCurrentOrder);
  yield takeEvery([cancelOrderRequest], cancelCurrentOrder);
}
