import React from "react";

export const Loading = ({caption}) => {
    return (
        <React.Fragment>
            <div className={caption ? 'loading' : 'loading full-page' }>
                <i className="fa fa-spinner fa-spin fa-2x fa-fw"/>
                <span> {caption ? caption : 'Cargando...' }</span>
            </div>                       
        </React.Fragment>
    );
};