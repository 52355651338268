import React from 'react';
import '../../styles/mobile/framework-store.css'
import ToolbarMobile from "../../../components/layout/components/mobile/ToolbarMobile";

class TracingMobile extends React.Component {
    render() {

        return (
            <React.Fragment>

                <ToolbarMobile isBack={true} showCart={false}/>

                <div className="page-content h-fixed">

                    <h2 className="regular left-20 top-0 bottom-10 font-20">Orden #456123</h2>

                    <div className="page-login page-login-full bottom-20">

                        <p className="font-18 top-0 bottom-5 color-gray">Tu pedido sera entregado en:</p>
                        <p className="font-18 top-0 bottom-20 color-default">Tus poductos sera entregados en el trascurso del día</p>

                        <ul id="timeline">
                            <li className="timeline-item">
                                <div className="timeline-icon"/>
                                <div className="timeline-body-m">
                                    <div className="timeline-lead">
                                        <h3 className="timeline-title">Creado</h3>
                                    </div>
                                    <p className="color-gray bottom-0">2:35 p.m.<br/>El pedido fue enviado al
                                        supermercado.</p>
                                </div>
                            </li>
                            <li className="timeline-item">
                                <div className="timeline-icon"/>
                                <div className="timeline-body-m">
                                    <div className="timeline-lead">
                                        <h3 className="timeline-title">Recibido</h3>
                                    </div>
                                    <p className="color-gray bottom-0">2:38 p.m.<br/>El supermercado está preparando
                                        tu pedido.</p>
                                </div>
                            </li>
                            <li className="timeline-item off">
                                <div className="timeline-icon"/>
                                <div className="timeline-body-m">
                                    <div className="timeline-lead">
                                        <h3 className="timeline-title">En camino</h3>
                                    </div>
                                    <p className="color-gray bottom-0">Tu pedido se encuentra con el repartidor y
                                        está en camino a tu dirección.</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </React.Fragment>
        );

    }
}

export default TracingMobile;