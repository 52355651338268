import React from 'react';
import {isBrowser } from 'react-device-detect';
import ProfileDesktop from "./ProfileDesktop";
import ProfileMobile from "./ProfileMobile";
import { useSelector } from 'react-redux';
import { userSelector } from 'selectors';

const Profile = () => {
    const userProfileData = useSelector(userSelector);
    const ProfileDetailLayout = isBrowser ? ProfileDesktop : ProfileMobile;
  
    return (<ProfileDetailLayout profile={userProfileData} />);
}

export default Profile;
