import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { isBrowser } from "react-device-detect";
import { useParams } from "react-router-dom";
import { loadProductsPerCategoryRequest } from "store/domains/categoryDetail/categoryDetailActions";
import { loadProductsRequest } from "store/domains/products/productsActions";
import CategoryGridDesktop from "./CategoryGridDesktop";
import CategoryGridMobile from "./CategoryGridMobile";
import { Loading } from "components/common/Loading";
import { loadCategoriesRequest } from "store/domains/category/categoryActions";

const Category = () => {
  const { categoryName, subcategoryName } = useParams();
  const products = useSelector((state) => state.categoryDetail);
  const categories = useSelector((state) => state.categories.list);
  const dispatch = useDispatch();
  const CategoryLayout = isBrowser ? CategoryGridDesktop : CategoryGridMobile;
  const currentCity = useSelector((state) => state.cities.city);
  const city = currentCity ? currentCity.toLowerCase() : "medellin";
  useEffect(() => {
    // fetch all products per category, and filter in category layout if a subcategory is provided
    dispatch(loadProductsPerCategoryRequest({ categoryName, city }));
    dispatch(loadProductsRequest());
    dispatch(loadCategoriesRequest());
  }, [dispatch, categoryName, city]);

  return products.ui.isLoading ? (
    <Loading />
  ) : (
    <CategoryLayout
      products={products.products}
      category={categoryName}
      subcategory={subcategoryName}
      categories={categories}
    />
  );
};

export default Category;
