import React from 'react';
import '../../styles/mobile/framework-store.css'

class TracingDesktop extends React.Component {
    render() {
        return (
            <h1>Esto es desktop</h1>
        );

    }
}

export default TracingDesktop;