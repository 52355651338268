import React from 'react';
import { Route, Redirect } from "react-router-dom";
import { useSelector } from 'react-redux';

/**
 * Funcion para asignarle el titulo y reiniciar el escroll de la pagina
 * @param title string 
 * @param props any
 */
export const RouteWithTitle = ({ route, ...props }) => {
    const { auth } = useSelector(state => state.firebase);
    const { title, requireAuth, path } = route;
    
    try{
        // setear el titulo de la pagina
        document.title = title  + ' | La Montaña Agromercados';
        // enviar a la parte superior al cambiar de pagina
        window.scrollTo(0, 0);
    }catch{}

    return (requireAuth && auth.isEmpty?
        <Route exact path={path}>
            <Redirect to="/" />
        </Route>
        :
        <Route {...props} />
    )
}
