import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isBrowser } from "react-device-detect";
import OrderHistoryDesktop from "./OrderHistoryDesktop";
import OrderHistoryMobile from "./OrderHistoryMobile";
import { orderListRequest } from "store/domains/orders/ordersActions";

const OrderHistory = () => {
  const orders = useSelector((state) => (state.orders.list));
  const dispatch = useDispatch();
  const OrderHistoryLayout = isBrowser ? OrderHistoryDesktop : OrderHistoryMobile;

  useEffect(() => {
    dispatch(orderListRequest());
  }, [dispatch]);
  return (<OrderHistoryLayout orders={orders} />);
};

export default OrderHistory;
