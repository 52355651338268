import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  decrementItemQuantity,
  incrementItemQuantity,
  removeItemFromOrder,
  updateUnitGram
} from 'store/domains/shoppingCart/shoppingCartActions';

const useShoppingCartActions = (index, item) => {
  const dispatch = useDispatch();
  item.unit_gram = item.unit_gram === undefined? 0:item.unit_gram;
  
  const { unit_gram,count, unityType } = item;
  
  const [currentCount, setCurrentCount] = useState(count);

  useEffect(() => {
    setCurrentCount(item.count);
    dispatch(updateUnitGram(item.unit_gram?item.unit_gram:0));
  }, [item, dispatch])


  const handleDecrement = () => {
    const prevCount = unityType === 0 ? currentCount - 1 : currentCount - unit_gram;
    if (prevCount >= (unityType === 0 ? 1 : unit_gram)) {
      setCurrentCount(prevCount);
      dispatch(decrementItemQuantity(index, unityType === 0 ? false : true));
    }
  };
  const handleIncrement = () => {
    const nextCount = unityType === 0 ? currentCount + 1 : currentCount + unit_gram;
    setCurrentCount(nextCount);
    dispatch(incrementItemQuantity(index, unityType === 0 ? false : true));
  };

  const handleDeleteItem = () => {
    dispatch(removeItemFromOrder(item))
  };

  return {
    currentCount,
    handleDecrement,
    handleIncrement,
    handleDeleteItem
  };
};

export default useShoppingCartActions;
