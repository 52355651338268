import React, { useState } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { clearCurrentOrder } from '../../../store/domains/orders/ordersActions';
import {
  signInWithCredentials,
  signInWithFacebook,
  signInWithGoogle
} from "../store/actions/authActions";
import { Loading } from "components/common/Loading";


const authSelector = (state) => ({
  auth: state.firebase.auth,
  authError: state.auth.authError,
  loadingLogin: state.auth.loading
});

const LoginModal = ({ handleChangeAlert, handleClose, handleForgotPw }) => {
  const dispatch = useDispatch();
  const {
    authError,
    loadingLogin
  } = useSelector(authSelector);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const handleSignInWithCredentials = (creds) => dispatch(signInWithCredentials(creds));
  const handleSignInWithGoogle = () => { 
    dispatch(clearCurrentOrder())
    dispatch(signInWithGoogle()) 
  };
  const handleSignInWithFacebook = () => {
    dispatch(clearCurrentOrder())
    dispatch(signInWithFacebook())
  };
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(clearCurrentOrder())
    handleSignInWithCredentials({ email, password });
  };

  return (
    <div className="col-md-7 bottom-0 top-10">
      <div className="row bottom-10">
        <div className="col-md-2" />
        <div className="col-md-8 align-center">
          <h2 className="color-default">¡Bienvenido!</h2>
        </div>
        <div className="col-md-2" onClick={handleClose}>
          <div className="color-default" style={{ cursor: "pointer" }} >
            <h3 className="color-default">
              <i className="fa fa-times" />
            </h3>
          </div>
        </div>
      </div>

      <div className="row bottom-30">
        <div className="col-md-2" />
        <div className="col-md-8">
          <form onSubmit={handleSubmit} className="bottom-30">
            <div className="form-group row">
              <div className="col-lg-12">
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  placeholder="Escribe tu correo electrónico"
                  onChange={handleEmailChange}
                  value={email}
                />
              </div>
            </div>
            <div className="form-group row bottom-20">
              <div className="col-lg-12">
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  placeholder="Escribe tu contraseña"
                  onChange={handlePasswordChange}
                  value={password}
                />
              </div>
            </div>
            <div className="color-red center bottom-10">
              {authError ? <span>{authError}</span> : null}
            </div>
            <p className="align-center bottom-20">
              <span onClick={handleForgotPw} className="color-default" style={{ cursor: "pointer", textDecoration: "underline" }}>
                ¿Olvidaste tu contraseña?
              </span>
            </p>
            <button type="submit" className="btn btn-success btn-block bottom-30">
              Ingresar
            </button>
            {loadingLogin ? <Loading /> : null}
          </form>

          <button
            type="button"
            onClick={handleSignInWithFacebook}
            className="btn btn-info btn-block"
          >
            <i className="fab fa-facebook-f right-10" /> Ingresa con Facebook
          </button>
          <button
            type="button"
            onClick={handleSignInWithGoogle}
            className="btn btn-danger btn-block"
          >
            <i className="fab fa-google right-10" /> Ingresa con Google
          </button>
          <br />          
          <p className="align-center color-default bottom-0 top-10">
            ¿No te has registrado?
          </p>
          <p className="align-center bottom-10">
            <span
              onClick={handleChangeAlert}
              className="color-green"
              style={{ cursor: "pointer" }}
            >
              Ingresa aquí
            </span>
          </p>          
          <NavLink to="/terminos" onClick={handleClose}>
            <p className="align-center bottom-0">
              <span className="color-green align-center" style={{ cursor: "pointer" }}>
                  Ver terminos y condiciones
              </span>
            </p>            
          </NavLink>
        </div>
        <div className="col-md-2" />
      </div>
    </div>
  );
};

LoginModal.propTypes = {
  handleChangeAlert: PropTypes.func,
  handleClose: PropTypes.func
};

LoginModal.defaultProps = {
  handleChangeAlert: () => {},
  handleClose: () => {}
};

export default LoginModal;
