import React from "react";
import PropTypes from 'prop-types';
import { isBrowser } from 'react-device-detect';

import LayoutDesktop from "./LayoutDesktop";
import LayoutMobile from "./LayoutMobile";
import Footer from "../../components/layout/components/Footer";

const Layout = ({ children }) => {
  const Layout = isBrowser ? LayoutDesktop : LayoutMobile;

  return (
    <>
      <Layout>
        {children}
      </Layout>
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node
};

Layout.defaultProps = {
  children: null
};

export default Layout;
