import React from "react";
// import { Link } from "react-router-dom";
import CarouselProductsDesktop from "../../../components/carousels/products/CarouselProducts";

class ProductDetailDesktop extends React.Component {
  render() {
    return (
      <React.Fragment>
        <header className="page-header" style={{ zIndex: 0 }}>
          <h2>Nombre del producto</h2>
        </header>

        <div className="row">
          <div className="col-lg-12 mb-3">
            <section className="content-pages">
              <CarouselProductsDesktop />
            </section>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ProductDetailDesktop;
