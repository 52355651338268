import React, { useEffect } from "react";

import { useDispatch } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { updateStatusDrawer } from 'store/domains/config/configActions'
import { useHistory } from "react-router";
import {
    createOrderRequest
} from 'store/domains/shoppingCart/shoppingCartActions';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
//import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
//import { makeStyles } from '@material-ui/core/styles';

const ResponseDesktop = ({ response, referenceResponse, priceResponse, payMethodResponse, createResponse }) => {
    
    /*const useStyles = makeStyles({
        table: {
          minWidth: 650,
        },
    });*/
    const dispatch = useDispatch();
    const history = useHistory();
    useEffect(() => {
        if (response === "APPROVED") {
            dispatch(createOrderRequest());
            dispatch(updateStatusDrawer(true));
        }
    }, [response, dispatch])

    const handleBack = () => {
        history.push("/");
    }

    return (
        response === "APPROVED" ?
            <Grid container spacing={3}>
                <Grid item xs={12} className="m-5">
                    <Paper className="text-center paper-approved p-3">
                        <span className="text-approved">TRANSACCIÓN APROBADA</span>
                        <span>Su transaccion se realizó correctamente, puede seguir disfrutando de nuestro catálogo</span>

                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableBody>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                <b>Codigo de referencia</b>
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {referenceResponse}
                                            </TableCell>
                                        </TableRow>
                                        
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                <b>Precio</b>
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {priceResponse/100}
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                <b>Metodo de pago</b>
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {payMethodResponse}
                                            </TableCell>
                                        </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>


                        <div className="mt-2">
                            <button className="btn btn-success" onClick={handleBack}>Volver</button>
                        </div>
                    </Paper>
                </Grid>
            </Grid>
            :
            <Grid container spacing={3}>
                <Grid item xs={12} className="m-5">
                    <Paper className="text-center paper-approved p-3">
                        <span className="text-approved">TRANSACCIÓN NO APROBADA</span>
                        <span>Su transaccion no se realizo correctamente</span>
                        <div className="mt-2">
                            <button className="btn btn-success" onClick={handleBack}>Volver</button>
                        </div>
                    </Paper>
                </Grid>
            </Grid>

    );
};



export default ResponseDesktop;