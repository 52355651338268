import React from "react";

class OrderDesktop extends React.Component {
    render() {
        return (
            <React.Fragment>
                <div>Esto es web</div>
            </React.Fragment>
        );
    }
}

export default OrderDesktop;
