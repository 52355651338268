import { combineActions, createActions, handleActions } from 'redux-actions';

const defaultState = {
  ui: {
    isLoading: false
  }
};

const {
  addNewAddressRequest,
  addNewAddressSuccessed,
  addNewAddressFailed,
  modifyAddressRequest,
  deleteAddressRequest,

  addNewProfileRequest,
  addNewProfileSuccessed,
  addNewProfileFailed,
  modifyProfileRequest,
  deleteProfileRequest
} = createActions({
  ADD_NEW_ADDRESS_REQUEST: (addresses) => ({ addresses }),
  ADD_NEW_ADDRESS_SUCCESSED: undefined,
  ADD_NEW_ADDRESS_FAILED: undefined,
  MODIFY_ADDRESS_REQUEST: (addresses) => ({addresses}),
  DELETE_ADDRESS_REQUEST: (addresses) => ({addresses}),

  ADD_NEW_PROFILE_REQUEST: (profile) => ({ profile }),
  ADD_NEW_PROFILE_SUCCESSED: undefined,
  ADD_NEW_PROFILE_FAILED: undefined,
  MODIFY_PROFILE_REQUEST: (profile) => ({ profile }),
  DELETE_PROFILE_REQUEST: (profile) => ({ profile }),
});

const reducer = handleActions({
  [combineActions(addNewAddressSuccessed, addNewAddressFailed, 
    addNewProfileSuccessed, addNewProfileFailed)]: (state) => ({
    ...state,
    ui: {
      isLoading: false
    }
  }),
  [combineActions(addNewAddressRequest, modifyAddressRequest, deleteAddressRequest, 
    addNewProfileRequest, modifyProfileRequest, deleteProfileRequest)]: (state) => ({
    ...state,
    ui: {
      isLoading: true
    }
  })
}, defaultState);

export {
  addNewAddressRequest,
  addNewAddressSuccessed,
  addNewAddressFailed,
  modifyAddressRequest,
  deleteAddressRequest,

  addNewProfileRequest,
  addNewProfileSuccessed,
  addNewProfileFailed,
  modifyProfileRequest,
  deleteProfileRequest,
  defaultState
}

export default reducer;