import React from "react";
import PropTypes from 'prop-types';
import { NavLink, Link } from 'react-router-dom';
import { ItemMobile } from 'components/item';
import ToolbarMobile from "components/layout/components/mobile/ToolbarMobile";
import { splitCategoria } from 'utils/format';
import InputSearchMobile from 'components/inputSearchMobile/InputSearchMobile';
import "react-alice-carousel/lib/alice-carousel.css";
import Slider from "react-slick";
import { isBrowser } from "react-device-detect";


const CategoryMobile = ({ category, products, categories }) => {

  const shouldFilterProductsPerCategory = category && category.length > 0;
  const categoryToShow = shouldFilterProductsPerCategory ? categories.filter((categorie) => (categorie.id === category)) : "";
  let nameCategory = categoryToShow[0].name !== category ? categoryToShow[0].name : category;

  const subCategories = categories.filter((categoryItem) => categoryItem.id === category)[0].subcategories;

  return (
    <>
      <ToolbarMobile isBack={true} showCart={true} />
      <div className="page-content h-fixed">
        <div className="page-login page-login-full bottom-20">
          <h2 className="regular top-0 bottom-0 font-30">{splitCategoria(nameCategory || category)}</h2>
          <form>
            <div className="page-login-field top-15">
              <InputSearchMobile />
            </div>
          </form>

          <div className="slider-margins bottom-20">
            {subCategories.map((categoried, index) => (
              <React.Fragment key={`category-${index}`}>
                <div className="bottom-10 top-30">
                  <div className="col-md-12">
                    <h3 className="color-default category-title-content">
                      {splitCategoria(categoried.subcategory)}
                      <Link to={`/subcategoria/${category}/${categoried.subcategory}`}
                        className="color-default top-5 right-10 font-13 float-right">
                        Ver más <i className="fa fa-chevron-right" />
                      </Link>
                    </h3>
                  </div>
                </div>
                <Slider {...settings(products.filter((product) => product.sub_category_id === categoried.subcategory).length)} className="slider-margins">
                  {
                    products.filter((product) => product.sub_category_id === categoried.subcategory)
                      .filter((p, count) => count < 15)
                      .map((product, index) => {
                        return (
                          <NavLink key={`item-${index}`} to={`/categoria/${product.main_category_id}/producto/${product.id_item}`} className="item-card-home">
                            <ItemMobile key={`${product["id"]}-${index}`} product={product} />
                          </NavLink>
                        );
                      })
                  }
                </Slider>
                <div className='decoration top-30'></div>
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

CategoryMobile.propTypes = {
  category: PropTypes.string,
  products: PropTypes.arrayOf(PropTypes.object),
  categories: PropTypes.arrayOf(PropTypes.object)
};

CategoryMobile.defaultProps = {
  category: '',
  products: [],
  categories: []
};


const settings = (length) => {

  var isInfinite = true;

  if (length <= 5) {
    isInfinite = false;
  }

  return {
    dots: true,
    infinite: isInfinite,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 3,
    lazyLoad: 'ondemand',
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: isBrowser ? 1100 : 1000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      }
    ]
  };
}

export default CategoryMobile;
