import React, { useEffect } from "react";

import { useDispatch } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {  incrementOrderStep, createOrderRequest } from "store/domains/shoppingCart/shoppingCartActions"
import { useHistory } from "react-router";

const ResponseMobile = ({ response }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    useEffect(() => {
        if (response === "APPROVED") {
            dispatch(incrementOrderStep());
            dispatch(createOrderRequest());
            history.push('/orden');
        }
    }, [response, dispatch, history])

/*     useEffect(() => {
        if (orderStage === 2) {
          history.push('/orden');
        }
    }, [orderStage, history]) */

    const handleBack = () => {
        history.push("/");
    }

    return (
        response === "APPROVED" ?
            <div className="page-content h-fixed">
                <Grid container spacing={3} className="mt-5">
                    <Grid item xs={12} className="m-5 pay-approved">
                        <Paper className="text-center paper-approved">
                            <span className="text-approved">TRANSACCIÓN APROBADA</span>
                            <span>Su transaccion se realizó correctamente, puede seguir disfrutando de nuestro catálogo.</span>
                            <div>
                                <button className="button-approved" onClick={handleBack}>Volver</button>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
            :
            <div className="page-content h-fixed">
                <Grid container spacing={3}>
                    <Grid item xs={12} className="m-5 pay-approved">
                        <Paper className="text-center paper-approved">
                            <span className="text-approved">TRANSACCIÓN NO APROBADA</span>
                            <span>Su transaccion no se realizó correctamente.</span>
                            <div>
                                <button className="button-approved">Volver</button>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
    );
};



export default ResponseMobile;