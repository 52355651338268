import React, { memo, useState } from "react";
import PropTypes from "prop-types";
//import { isBrowser } from "react-device-detect";
import { ItemDesktop } from "components/item";
import { splitCategoria } from "utils/format";
//import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { isBrowser } from "react-device-detect";
import Slider from "react-slick";
import FilterList from "components/filter/FilterList";

const settings = (length) => {
  var isInfinite = true;

  if (length <= 5) {
    isInfinite = false;
  }
  return {
    dots: true,
    infinite: isInfinite,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 3,
    lazyLoad: "ondemand",
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };
};
const CategoryGrid = ({ category, products, subcategory, categories }) => {
  const subCategories = categories.filter(
    (categoryItem) => categoryItem.id === category
  )[0].subcategories;

  const [displayDetail, setDisplayDetail] = useState(false);

  const toggleDetail = () => {
    setDisplayDetail(!displayDetail);
  };

  const shouldFilterProductsPerSubCategory =
    subcategory && subcategory.length > 0;

  const shouldFilterProductsPerCategory = category && category.length > 0;
  const categoryToShow = shouldFilterProductsPerCategory
    ? categories.filter((categorie) => categorie.id === category)
    : "";

  let nameCategory =
    categoryToShow[0].name !== category ? categoryToShow[0].name : category;
  nameCategory = shouldFilterProductsPerSubCategory
    ? subcategory
    : nameCategory;

  //const currentCity = useSelector((state) => (state.cities.city));
  //const city = currentCity? currentCity.toLowerCase() : "medellin";
  const [selectedOption, setSelectedOption] = useState(null);
  return (
    <div className="container">
      <h2>{splitCategoria(nameCategory || category)}</h2>
      {subCategories.map((categoried, index) => {
        const productsFilter = products
          .filter(
            (product) => product.sub_category_id === categoried.subcategory
          )
          .filter((p, count) => count < 15);
        const handleSelect = (selectedOption) => {
          setSelectedOption(selectedOption);
          if (selectedOption === "smallest") {
            products.sort((a, b) => {
              return a.price - b.price;
            });
          }
          if (selectedOption === "alphabetically") {
            products.sort((a, b) => {
              if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
              if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
              return 0;
            });
          }
          if (selectedOption === "largest") {
            products.sort((b, a) => {
              return a.price - b.price;
            });
          }
          if (selectedOption === "popular") {
            products.sort((b, a) => {
              return a.discount - b.discount;
            });
          }
        };
        return (
          <React.Fragment key={`category-${index}`}>
            <div className="row bottom-10">
              <div className="col-md-12">
                <h3 className="color-default category-title-content">
                  {splitCategoria(categoried.subcategory)}
                  <Link
                    to={`/subcategoria/${category}/${categoried.subcategory}`}
                    className="color-default left-40 font-13"
                  >
                    Ver más <i className="fa fa-chevron-right" />
                  </Link>
                </h3>
              </div>
            </div>
            {productsFilter.length === 0 && (
              <div className="alert alert-info">
                <span>No se han encontrado datos para mostrar.</span>
              </div>
            )}
            {productsFilter.length !== 0 && (
              <FilterList
                handleSelect={handleSelect}
                isBrowser={isBrowser}
                selectedOption={selectedOption}
              />
            )}
            <Slider
              {...settings(productsFilter.length)}
              className="slider-margins"
            >
              {productsFilter.map((product, index) => {
                return (
                  <div key={`item-${index}`} className="item-card-home">
                    <ItemDesktop product={product} handleClose={toggleDetail} />
                  </div>
                );
              })}
            </Slider>
          </React.Fragment>
        );
      })}
    </div>
  );
};

CategoryGrid.propTypes = {
  category: PropTypes.string,
  products: PropTypes.arrayOf(PropTypes.object),
  subCategory: PropTypes.string,
  categories: PropTypes.arrayOf(PropTypes.object),
};

CategoryGrid.defaultProps = {
  category: "",
  products: [],
  subCategory: "",
  categories: [],
};

export default memo(CategoryGrid);
