import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import Image from "components/image/Image";
import { useDetailedProductBehavior } from "hooks";
import { Counter } from "components/counter";
import {
  formatCurrency,
  parseFloatWithComma,
  capitalizeString,
  getPrice,
  getPum,
  formatCurrencyPUM,
} from "utils/format";
import { Tabs, Tab } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useStyles = makeStyles({
  root: {
    color: "#EA4D0D",
  },
  indicator: {
    Color: "#EA4D0D",
    "&:hover": {
      outline: "none",
    },
    "&:focus": {
      outline: "none",
    },
  },
});

// TODO: replace shortcircuit operator with display-if
const DetailProductDesktop = ({ handleClose, product, show }) => {
  const currentCity = useSelector((state) => state.cities.city);

  const classes = useStyles();

  let city_price = getPrice(currentCity, product);
  product.price = city_price;

  const pum = getPum(currentCity, product);
  product.pum = pum;

  const {
    comments,
    count,
    handleChangeComments,
    handleUnityTypeChange,
    handleClick,
    unityType,
    unityString,
    maturity,
    handleMaturity,
    handleSubmit,
    shouldDisplayDiscountData,
    shouldRenderMatureSelector,
    shouldRenderMeetFruverSelector,
  } = useDetailedProductBehavior(product, handleClose);
  // asignar el uni_gram por defecto
  product.unit_gram = product.unit_gram === undefined ? 0 : product.unit_gram;
  const {
    unit_gram,
    discount,
    id_item,
    price,
    title,
    unit_measurement_pum,
    unit_measurement_full,
    sub_category_id,
  } = product;

  return (
    <Modal
      show={show}
      onHide={handleClose}
      animation={true}
      size="lg"
      id={"box-producto"}
      dialogClassName="modal-block rounded-lg modal-block-lg mfp-hide"
      centered
    >
      <section className="box-modal-content">
        <div className="row modal-padding">
          <div className="col-md-12 top-10 bottom-10">
            <div className="row ">
              <div className="col-md-4">
                {shouldDisplayDiscountData && (
                  <div className="label-offer">{discount}%</div>
                )}
                <div className="img-detail-modal">
                  <Image id={id_item} className="img-detail" />
                </div>
              </div>
              <div className="col-md-8">
                <div className="row">
                  <div className="col-md-9">
                    <h2 className="color-default font-30">{title}</h2>
                  </div>
                  <div className="col-md-3 align-right">
                    <h3 className="color-default">
                      <button
                        style={{
                          cursor: "pointer",
                          fontSize: "28px",
                          position: "absolute",
                          right: 0,
                          top: 0,
                        }}
                        className="color-default left-10 btn"
                        onClick={handleClose}
                      >
                        <i className="fas fa-times" />
                      </button>
                    </h3>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-5">
                    <p className="bottom-0 font-18">SKU: {id_item}</p>
                  </div>
                  <div className="col-md-7">
                    <p className="bottom-0">
                      <span className="color-orange font-18">
                        {formatCurrency(city_price)}
                      </span>
                      {shouldDisplayDiscountData && (
                        <span className="color-gray left-20 old-price">
                          {formatCurrency(price)}
                        </span>
                      )}
                    </p>
                  </div>
                  <div className="col-md-12 top-10 bottom-20">
                    <p className="bottom-0 color-default font-12">
                      <i className="fa fa-dollar-sign color-orange icon-round-border right-10" />
                      {capitalizeString(String(unit_measurement_pum))} a ${" "}
                      {formatCurrencyPUM(parseFloatWithComma(pum), 1)}
                      {shouldDisplayDiscountData && (
                        <span>
                          <i className="fa fa-tag color-orange icon-round-border font-9 left-10" />
                          Ahorra {formatCurrency(price - city_price)}
                        </span>
                      )}
                    </p>
                  </div>
                </div>
                {shouldRenderMatureSelector && (
                  <>
                    <p className="bottom-20 color-gray font-14">
                      Madurez de tu producto
                    </p>
                    <div className="content">
                      <RadioGroup
                        row
                        name="maturity"
                        aria-label="maturity"
                        value={maturity}
                        onChange={handleMaturity}
                        style={{ justifyContent: "space-evenly" }}
                      >
                        <FormControlLabel
                          value="0"
                          control={<Radio color="primary" />}
                          label="Verde"
                        />
                        <FormControlLabel
                          value="1"
                          control={<Radio color="primary" />}
                          label="Normal"
                        />
                        <FormControlLabel
                          value="2"
                          control={<Radio color="primary" />}
                          label="Maduro"
                        />
                      </RadioGroup>
                      <div className="clear" />
                    </div>
                  </>
                )}
                <div className="row">
                  <div className="col">
                    <div className="form-group row">
                      <div className="col-lg-12">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Observación o comentario (opcional)"
                          value={comments}
                          onChange={handleChangeComments}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {((shouldRenderMatureSelector &&
                  unit_measurement_full !== "UND") ||
                  (unit_measurement_full !== "UND" &&
                    (sub_category_id === "POLLO" ||
                      sub_category_id === "PESCADOS Y MARISCOS"))) && (
                  <div className="row top-10 color-green justify-content-center center font-12">
                    <span style={{ fontWeight: "bold" }}>
                      * El peso promedio de la unidad este producto es de{" "}
                      {unit_gram} gramos.
                    </span>
                  </div>
                )}

                <div className="row">
                  <div className="col-md-2" />
                  <div className="col-md-8 top-20 tabs-products">
                    <Tabs
                      className={classes.root}
                      value={unityType}
                      onChange={handleUnityTypeChange}
                      aria-label="unity type tabs"
                      variant="fullWidth"
                      TabIndicatorProps={{
                        style: {
                          backgroundColor: "#ea4d0d",
                        },
                      }}
                    >
                      <Tab
                        label="Unidad"
                        disabled={
                          sub_category_id === "RES Y CERDO" &&
                          unit_measurement_full !== "UND"
                        }
                        className={classes.indicator}
                      />
                      {shouldRenderMeetFruverSelector && (
                        <Tab
                          label="Peso"
                          disabled={unit_measurement_full === "UND"}
                          className={classes.indicator}
                        />
                      )}
                    </Tabs>
                    <div className="top-20 align-center">
                      <Counter
                        handleDecrement={() => handleClick("minus")}
                        handleIncrement={() => handleClick("plus")}
                        value={count ? count : 0}
                      />
                      {unityString}
                    </div>
                  </div>
                </div>
                <div className="row bottom-20">
                  <div className="col-md-2" />
                  <div className="col-md-8 top-20">
                    <button
                      type="button"
                      onClick={() => {
                        handleSubmit(true);
                      }}
                      className="btn btn-warning btn-block"
                    >
                      {((shouldRenderMatureSelector &&
                        unit_measurement_full !== "UND") ||
                        (unit_measurement_full !== "UND" &&
                          (sub_category_id === "POLLO" ||
                            sub_category_id === "PESCADOS Y MARISCOS"))) &&
                      unityType === 0
                        ? `Añadir al carrito ${formatCurrency(
                            pum * unit_gram * count
                          )}`
                        : `Añadir al carrito ${formatCurrency(
                            unityType === 1
                              ? parseFloatWithComma(pum) * count
                              : city_price * count
                          )}`}
                    </button>
                  </div>
                  <div className="col-md-2" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Modal>
  );
};

DetailProductDesktop.propTypes = {
  handleClose: PropTypes.func,
  product: PropTypes.object,
  show: PropTypes.bool,
};

DetailProductDesktop.defaultProps = {
  handleClose: () => {},
  product: {},
  show: false,
};

export default DetailProductDesktop;
