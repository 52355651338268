import React from 'react';
import { useSelector } from 'react-redux';
import { isBrowser } from "react-device-detect";
import AddressesMobile from "./AddressesMobile";
import AddressesDesktop from "./AddressesDesktop";
import { addressesSelector } from 'selectors';

const Addresses = () => {
  const addresses = useSelector(addressesSelector);
  const AddressesLayout = isBrowser ? AddressesDesktop : AddressesMobile;

  return (<AddressesLayout addresses={addresses} />);
};

export default Addresses;
