import React, { useState }  from "react";
import {useParams} from "react-router-dom";
import PropTypes from 'prop-types';
import algoliasearch from 'algoliasearch/lite';
import {
    InstantSearch,
    Configure,
    //Hits,
    connectHits,
    //RefinementList,
    //ClearRefinements,
    connectSearchBox
} from 'react-instantsearch-dom';
import { detailedProductSelector } from 'selectors';
import { useSelector } from 'react-redux';
import ItemDesktop from '../../../components/item/ItemDesktop';

const VirtualSearchBox = connectSearchBox(() => null);
const searchClient = algoliasearch(
    //'3M0QM2451W',
    // '9df91abf9c6bf66e6027c2261d806829'
);

const SearchDesktop = () => {
    const currentCity = useSelector((state) => (state.cities.city));
    const cityFilter = currentCity? currentCity.toLowerCase() : "medellin";

    const { name } = useParams();
    
    const product = useSelector(detailedProductSelector);

    return (
        <div className="container ais-InstantSearch">
            <h1>Resultados de la búsqueda</h1> 
            <div className="row">

            <InstantSearch 
                searchClient={searchClient} 
                indexName="products" 
            >
                {/*
                    <div className="col-3">
                        <ClearRefinements />
                        <h2>Marcas</h2>
                        <Configure hitsPerPage={8} />
                        <RefinementList
                            attribute="brand"
                            showMore
                            transformItems={items => {
                                    console.log(items);
                                    return items.map(item => ({
                                        ...item,
                                        label: " "+splitCategoria(item.label.toUpperCase()),
                                    }))
                                }
                            }
                        />
                    </div>
                */}
                <div className="col-12">
                    <Configure 
                        hitsPerPage={500}
                        filters={`stock_${cityFilter}>0`}
                    />
                    <VirtualSearchBox defaultRefinement={name} />
                    <Hit />
                </div>
            </InstantSearch>

            </div>
        </div>
    );

}

const Hit = connectHits(({ hits }) => {
    ///const dispatch = useDispatch();
    const items = hits.map((hit, index) => {
        return (
            <div key={`item-${index}`} >
                <ItemDesktop product={hit} handleClose={() => {}}/>
            </div>
        );
    });
    return (items.length>0?
        <div className="ais-Hits-list">{items}</div>
        :
        <div>
            <div className="alert alert-info">
                No se han encontrado productos para mostrar.
            </div>
        </div>
    );
});

Hit.propTypes = {
    hit: PropTypes.object.isRequired,
};

export default SearchDesktop;