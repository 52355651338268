import { createActions, handleActions } from 'redux-actions';

const defaultState = {
  list: [],
  ui: {
    isLoading: false
  }
};

const {
  loadCategoriesRequest,
  loadCategoriesSuccess,
  loadCategoriesFailed
} = createActions({
  LOAD_CATEGORIES_REQUEST: undefined,
  LOAD_CATEGORIES_SUCCESS: (categories) => ({ categories }),
  LOAD_CATEGORIES_FAILED: undefined
});

const reducer = handleActions({
  [loadCategoriesRequest]: (state) => ({
    ...state,
    ui: {
      isLoading: true
    }
  }),
  [loadCategoriesSuccess]: (state, { payload: { categories }}) => ({
    ...state,
    list: categories,
    ui: {
      isLoading: false
    }
  }),
  [loadCategoriesFailed]: (state)=> ({
    ...state,
    ui: {
      isLoading: false
    }
  })
}, defaultState);

export {
  defaultState,
  loadCategoriesRequest,
  loadCategoriesSuccess,
  loadCategoriesFailed
};

export default reducer;
