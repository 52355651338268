import React, { useState } from "react";
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { signUp } from "../store/actions/authActions";
import { authSelector } from 'selectors';
import { Loading } from "../../../components/common/Loading";
import { clearCurrentOrder } from '../../../store/domains/orders/ordersActions';

const RegisterModal = ({
  handleClose,
  handleChangeAlert
}) => {
  const dispatch = useDispatch();
  const { authError, loadingLogin } = useSelector(authSelector);
  const signUpAction = (newUser) => dispatch(signUp(newUser));
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [userData, setUserData] = useState({
    acceptTerms: false,
    name: '',
    last_name: '',
    email: '',
    password: '',
    repeat_password: '',
    phone: ''
  });
  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(clearCurrentOrder())
    signUpAction(userData);
  };
  const handleChange = (event) => {
    setUserData({
      ...userData,
      [event.target.id]: event.target.value
    });

    if (event.target.id === 'password') {
      setPasswordMatch(event.target.value === userData.repeat_password ? true : false);
    }

    if (event.target.id === 'repeat_password') {
      setPasswordMatch(event.target.value === userData.password ? true : false);
    }
  }
  const handleToggleCheckbox = (event) => {
    setUserData({
      ...userData,
      acceptTerms: !userData.acceptTerms
    });
  }

  return (
    <div className="col-md-7 bottom-20 top-10">
      <div className="row bottom-10">
        <div className="col-md-2" />
        <div className="col-md-8 align-center">
          <h2 className="color-default">Crea tu cuenta</h2>
        </div>
        <div className="col-md-2">
          <div
            onClick={handleClose}
            className="color-default"
            style={{ cursor: "pointer" }}
          >
            <h3 className="color-default">
              <i className="fa fa-times" />
            </h3>
          </div>
        </div>
      </div>
      <div className="row bottom-30">
        <div className="col-md-2" />
        <div className="col-md-8">
          <form onSubmit={handleSubmit} className="bottom-30">
            <div className="form-group row">
              <div className="col-lg-12">
                <input
                  type="text"
                  id="name"
                  className="form-control"
                  placeholder="Nombre"
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-12">
                <input
                  type="text"
                  id="last_name"
                  className="form-control"
                  placeholder="Apellido"
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-12">
                <input
                  type="text"
                  id="phone"
                  className="form-control"
                  placeholder="Teléfono"
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-12">
                <input
                  type="text"
                  id="email"
                  className="form-control"
                  placeholder="Correo electrónico"
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-12">
                <input
                  type="password"
                  id="password"
                  className="form-control"
                  placeholder="Contraseña"
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-12">
                <input
                  type="password"
                  id="repeat_password"
                  className="form-control"
                  placeholder="Confirma tu contraseña"
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="color-red center">
              {!passwordMatch && <span>Las contraseñas no coinciden</span>}
            </div>
            <div className="color-red center">
              {authError ? <span>{authError}</span> : null}
            </div>
            <div className="checkbox-custom checkbox-success bottom-10">
              <input type="checkbox" id="recordar" onChange={handleToggleCheckbox} />
              <label htmlFor="recordar">Al crear tu cuenta estás aceptando los Términos y condiciones y la política de privacidad.</label>
            </div>
            <NavLink to="/terminos" onClick={handleClose}>
              <p className="align-center bottom-20">
                <span className="color-green align-center" style={{ cursor: "pointer" }}>
                    Ver terminos y condiciones
                </span>
              </p>            
            </NavLink>
            <Button className="btn btn-success btn-block" disabled={!userData.acceptTerms || userData.password !== userData.repeat_password} type="submit" >
              Crear cuenta
            </Button>
            {loadingLogin ? <Loading /> : null}
          </form>
        </div>
        <div className="col-md-2" />
      </div>

      <p className="align-center color-default bottom-0">
        ¿Ya tienes cuenta?
      </p>
      <p className="align-center bottom-0">
        <span
          onClick={handleChangeAlert}
          className="color-green"
          style={{ cursor: "pointer" }}
        >
          Ingresa aquí
        </span>
      </p>      
    </div>
  );
};

export default RegisterModal;
