import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import AddressModalDesktop from 'components/modals/AddressModalDesktop';
import { addNewAddressRequest, modifyAddressRequest, deleteAddressRequest } from 'store/domains/user/userActions';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, DialogContentText } from '@material-ui/core';

const AddressesDesktop = ({ addresses }) => {
	const dispatch = useDispatch();
	const [displayDetail, setDisplayDetail] = useState({ open: false, action: 'new', index: null });
	const [currentAddress, setCurrentAddress] = useState({ value: '', alias: '', neighborhood: '', zone: 'Norte', city: 'Cali' })
	const [dialogState, setDialogState] = useState({ open: false, address: '', index: null });
	const toggleDetail = (action, index) => {
		if (action === 'new') {
			setCurrentAddress({ value: '', alias: '', neighborhood: '', zone: 'Norte', city: 'Cali' });
		}
		setDisplayDetail({ open: !displayDetail.open, action, index });
	};
	const handleSubmit = () => {
		dispatch(addNewAddressRequest([...addresses, {
			value: currentAddress.value,
			alias: currentAddress.alias,
			neighborhood: currentAddress.neighborhood,
			zone: currentAddress.zone,
			city: currentAddress.city
		}]));
		// close Modal
		toggleDetail();
	};
	const handleChange = (index) => {
		addresses[index] = currentAddress;
		dispatch(
			modifyAddressRequest(addresses)
		);
		toggleDetail();
	}

	const handleCurrentAddress = (index) => {
		setCurrentAddress(addresses[index]);
		// display modal
		toggleDetail('change', index);
	};

	const handleDelete = (itemIndex) => {
		dispatch(
			deleteAddressRequest(
				addresses.filter((item, index) => index !== itemIndex)
			)
		);
	}

	const handleDialogClose = () => {
		setDialogState({ open: false, address: '', index: null });
	};

	return (
		<>
			<AddressModalDesktop
				address={currentAddress}
				changeAddressData={setCurrentAddress}
				show={displayDetail.open}
				handleClose={toggleDetail}
				handleSubmit={handleSubmit}
				handleChange={handleChange}
				action={displayDetail.action}
				addressIndex={displayDetail.index}
			/>
			<header className="page-header page-title">
				<h2>Mis direcciones</h2>
			</header>
			<div className="row">
				<div className="col-lg-12 mb-3 content-padding">
					<section className="card">
						<div className="card-body">
							{addresses.map(({ alias, value, neighborhood, zone, city }, index) => (
								<div className="row">
									<div className="col-md-10">
										<div className="row">
											<div className="col-sm">
												<p className="bottom-0 font-12">Nombre</p>
												<p className="color-default">{alias}</p>
											</div>
											<div className="col-sm">
												<p className="bottom-0 font-12">Dirección</p>
												<p className="color-default">{value}</p>
											</div>
											<div className="col-sm">
												<p className="bottom-0 font-12">Ciudad</p>
												<p className="color-default">{city}</p>
											</div>
											<div className="col-sm">
												<p className="bottom-0 font-12">Barrio</p>
												<p className="color-default">{neighborhood}</p>
											</div>
											<div className="col-sm">
												<p className="bottom-0 font-12">Zona</p>
												<p className="color-default">{zone}</p>
											</div>
										</div>
									</div>
									<div className="col-md-1 align-right top-15">
										<p className="color-green bottom-0" style={{ cursor: 'pointer' }} onClick={() => handleCurrentAddress(index)}>Cambiar</p>
									</div>
									<div className="col-md-1 align-right top-15">
										<p onClick={() => { setDialogState({ open: true, address: alias, index: index }) }} className="color-green bottom-0" style={{ cursor: 'pointer' }}>Eliminar</p>
									</div>
								</div>
							))}
							{addresses.length === 0 &&
								<div className='alert alert-info'>
									No hemos encontrado direcciones para mostrar.
								</div>
							}
							<div className="row">
								<div className="col-md-4">
									<button onClick={() => { toggleDetail('new') }} className="btn btn-success btn-block">Añadir otra dirección</button>
								</div>
							</div>
						</div>
					</section>
				</div>
			</div>
			<Dialog
				open={dialogState.open}
				onClose={handleDialogClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">Eliminar dirección <b>'{dialogState.address}'</b></DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Desea eliminar la dirección <b>{dialogState.address}</b>?
				</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleDialogClose} color="inherit">
						Cancelar
					</Button>
					<Button onClick={() => { handleDialogClose(); handleDelete(dialogState.index) }} color="secondary">
						Eliminar
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

AddressesDesktop.propTypes = {
	addresses: PropTypes.arrayOf(PropTypes.shape({
		alias: PropTypes.string,
		value: PropTypes.string
	}))
};

AddressesDesktop.defaultProps = {
	addresses: []
};

export default AddressesDesktop;
