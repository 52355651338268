import React from "react";
import logoApp from "images/logo-blanco.png";
import Icon from '@material-ui/core/Icon';
import { Link } from "react-router-dom";

const Footer = () => {
    const date = new Date();
    return (
        <footer className="footer-info">
            <div className="section-one">
                <div>
                    <figure>
                        <img
                            src={logoApp}
                            alt="La Montaña Agromercados"
                        />
                    </figure>
                </div>
                <div>
                    <h4>Síguenos</h4>
                    <div className="d-block container-social-links">
                        <a href="https://www.facebook.com/La-Monta%C3%B1a-Agromercados-425827374914600/" target="_blank" rel="noopener noreferrer" >
                            <Icon className="fab fa-facebook-square" />
                        </a>
                        <a href="https://www.instagram.com/lamontanaagromercados/" target="_blank" rel="noopener noreferrer">
                            <Icon className="fab fa-instagram" />
                        </a>
                    </div>
                </div>
                <div className="containner-whatsapp">
                    <h4>Chatea con nosotros</h4>
                    <a href="http://wa.me/573108323232" target="_blank" rel="noopener noreferrer">
                        <Icon className="fab fa-whatsapp" />
                    </a>
                </div>
            </div>
            <div className="section-two info">
                <h3>Información legal</h3>
                <ol className="list-legal-information">
                    <li>
                        <Icon className="fa fa-chevron-right" />
                        <Link to='/quienes-somos'>
                            Quiénes somos
                        </Link>
                    </li>
                    <li>
                        <Icon className="fa fa-chevron-right" />
                        <Link to='/terminos'>
                            Términos y condiciones generales de uso
                        </Link>
                    </li>
                    <li>
                        <Icon className="fa fa-chevron-right" />
                        <Link to='/preguntas_frecuentes'>
                            Preguntas frecuentes
                        </Link>
                    </li>
                    {/*
                    <li>
                        <Icon className="fa fa-chevron-right" />
                        <Link to='/terminos'>
                            Políticas de cambios y devoluciones
                        </Link>
                    </li>
                    <li>
                        <Icon className="fa fa-chevron-right" />
                        <Link to='/terminos'>
                            Solicitud de reversiones
                        </Link>
                    </li>
                    <li>
                        <Icon className="fa fa-chevron-right" />
                        <Link to='/terminos#proteccion-datos'>
                            Política manejo de información y datos personales
                        </Link>
                    </li>
                    */}
                </ol>
            </div>
            <div className="section-three">
                © { date.getFullYear() } La Montaña Agromercados
            </div>
        </footer>
    )
}

export default Footer;