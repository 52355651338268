import React from "react";

class ShoppingCartDesktop extends React.Component{
    render() {
        return (
           <div>
               <h1>Esto es web</h1>
           </div>
        );
    }
}

export default ShoppingCartDesktop;