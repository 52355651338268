import React from 'react';
import {isBrowser, isMobile } from 'react-device-detect';
import SearchDesktop from "./SearchDesktop";
import SearchMobile from "./SearchMobile";

class ProductDetail extends React.Component {
    render() {

        if (isBrowser) {
            return <SearchDesktop/>;
        }

        if (isMobile) {
            return <SearchMobile/>;
        }
    }
}

export default ProductDetail;
