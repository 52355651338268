import React from 'react';
import { isBrowser } from 'react-device-detect';
import ToolbarMobile from "components/layout/components/mobile/ToolbarMobile";
import { Grid } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import fruver from "images/quienes-somos.jpg";
import { useSelector } from 'react-redux';

export const Aboutus = () => {
    const currentCity = useSelector((state) => (state.cities.city));
    const city = currentCity? currentCity.toLowerCase() : "medellin";
    return (
        <React.Fragment>
            {!isBrowser && <ToolbarMobile isBack={true} showCart={false} />}
            <div className={`container h-fixed page-content page-aboutus` }>
                <Grid container spacing={1}>
                    <Grid container item xs={12} sm={7} spacing={3} className="container-image-fruver">
                        <figure>
                            <img src={fruver} alt="Fruver" /> 
                        </figure>
                    </Grid>
                    <Grid container item xs={12} sm={5} spacing={3}>
                        <div style={{padding: '2rem', fontSize:'20px'}}>
                            <p>
                                Somos un Agromercado que te ofrece el servicio y comodidad de las grandes superficies, con la frescura, variedad y precio de la plaza. Buscamos ofrecerte lo mejor, seleccionando las frutas y verduras directamente del campo a tu mesa.
                            </p>
                            <p>
                                Estamos ubicados en La Central Mayorista, en el Bloque Naranja; punto comercial de gran flujo e importancia que ha revolucionado la dinámica comercial de la región y que ofrece una experiencia de compra única. En la que contarás con servicios, tiendas, zonas de comidas y parqueaderos.
                            </p>
                        </div> 
                    </Grid>
                </Grid>
                <Grid container spacing={1} className="mt-3 pt-3">
                    <Grid container item xs={12} sm={4} spacing={3} className="aboutus-container">
                        <div className="w-100">
                            <div className="about-icon-wrapper">
                                <div>
                                    <Icon className="fas fa-location-arrow" />
                                </div>
                            </div>
                            <h4 className="aboutus-title">DÓNDE ESTAMOS</h4>
                            {
                                city==="medellin"?
                                <React.Fragment>
                                    <span className="aboutus-description">
                                    La Mayorista, Bloque Naranja <br />
                                    Local 20, Primer piso <br />
                                    Calle 85 # 48-01
                                    </span>
                                    <a className="aboutus-link" href="https://g.page/montanaagromercados" target="_blank" rel="noopener noreferrer">
                                        ¿Cómo llegar?
                                    </a>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <span className="aboutus-description">
                                        Cl 8 #25-24, Cali, Valle del Cauca
                                    </span>
                                    <a className="aboutus-link" href="https://g.page/lamontanacali?share" target="_blank" rel="noopener noreferrer">
                                        ¿Cómo llegar?
                                    </a>
                                </React.Fragment>   
                            }

                        </div>
                    </Grid>
                    <Grid container item xs={12} sm={4} spacing={3} className="aboutus-container">
                        <div className="w-100">
                            <div className="about-icon-wrapper">
                                <div>
                                    <Icon className="fas fa-phone" />
                                </div>
                            </div>
                            <h4 className="aboutus-title">CONTACTO</h4>
                            <span className="aboutus-description">
                                comercial@lamontanaagromercados.com<br />
                                3108323232
                            </span>
                        </div>
                    </Grid>
                    <Grid container item xs={12} sm={4} spacing={3} className="aboutus-container">
                        <div className="w-100">
                            <div className="about-icon-wrapper">
                                <div>
                                    <Icon className="far fa-clock" />
                                </div>
                            </div>
                            <h4  className="aboutus-title">HORARIO DE ATENCIÓN</h4>
                            {
                            city==="medellin"?
                            <span  className="aboutus-description">
                                <b>Lunes a sábado</b> de 6:00 a.m. a 5:00 p.m. <br></br>
                                <b>Domingos y festivos</b> 7:00 a.m. a 3:00 p.m.
                            </span>
                            :
                            <span  className="aboutus-description">
                                <b>Lunes a sábado</b> de 7:00 a.m. a 7:00 p.m. <br></br>
                                <b>Domingos y festivos</b> 7:00 a.m. a 3:00 p.m.
                            </span>
                            }

                        </div>
                    </Grid>
                </Grid>
            </div>
        </React.Fragment>
    )
}
