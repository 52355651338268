import { createActions, handleActions } from 'redux-actions';

const defaultState = {
  showAlert: undefined,
  list: [],
  ui: {
    isLoading: false
  }
};

const {
    hideAlert,
} = createActions({
    HIDE_ALERT: (showAlert) => ({ showAlert })
});

const reducer = handleActions({
    [hideAlert]: (state, { payload: { showAlert } } ) => ({
        ...state,
        showAlert: showAlert
    })
}, defaultState);

export {
    hideAlert
}

export default reducer;