import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { currentOrderRequest } from 'store/domains/orders/ordersActions';
import { currentOrderSelector } from 'selectors';

const availableStatuses = ['PAGO EN PROCESO', 'PAGADO', 'CREADO', 'CONFIRMADO', 'ALISTAMIENTO', 'VALOR_A_PAGAR', 'DESPACHADO', 'FINALIZADO', 'CANCELADO'];
const hourFormatter = (date) => (date.format('h:mm a'));

const useTrackingOrderBehavior = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { currentOrderId, currentOrderData } = useSelector(currentOrderSelector);
  const {
    id_order,
    createdAt,
    createPayedAt,
    payToProcessAt,
    confirmedAt,
    separatedAt,
    priceToPayedAt,
    dispatchedAt,
    observations_order,
    price_domicile,
    price_total,
    status,
    observations_admin,
    domiciliary,
    domiciliary_id,
    statusDates,
    cancel_description,
    client_response,
    amountPaidAt,
  } = currentOrderData;
  const createdAtDate = hourFormatter(moment(createdAt));
  const confirmedAtDate = hourFormatter(moment(confirmedAt));
  const separatedAtDate = hourFormatter(moment(separatedAt));
  const priceToPayedAtDate = hourFormatter(moment(priceToPayedAt));
  const dispatchedAtDate = hourFormatter(moment(dispatchedAt));
  const payToProcessAtDate = hourFormatter(moment(payToProcessAt));
  const createPayedAtDate = hourFormatter(moment(createPayedAt));
  const deliveryDate = hourFormatter(moment(createdAt).add(1, 'hours'));
  const maxDeliveryDate = hourFormatter(moment(createdAt).add(1.5, 'hours'));
  const amountPaidDate = hourFormatter(moment(amountPaidAt));
  // TODO: refactor this to use a curried function to get the state
  const createdStatus = status === availableStatuses[7] || status === availableStatuses[6] || status === availableStatuses[5] || status === availableStatuses[4] || status === availableStatuses[3] || status === availableStatuses[2];
  const confirmedStatus = status === availableStatuses[7] || status === availableStatuses[6] || status === availableStatuses[5] || status === availableStatuses[4] || status === availableStatuses[3];
  const onEnlistmentStatus = status === availableStatuses[7] || status === availableStatuses[6] || status === availableStatuses[5] || status === availableStatuses[4];
  const onAmountToPaidStatus = status === availableStatuses[7] || status === availableStatuses[6] || status === availableStatuses[5];
  const onTransitStatus = status === availableStatuses[7] || status === availableStatuses[6];
  const onFinalizedStatus = status === availableStatuses[7];
  const cancelStatus = status === availableStatuses[8];
  const payToProcessStatus = status === availableStatuses[7] || status === availableStatuses[6] || status === availableStatuses[5] || status === availableStatuses[4] || status === availableStatuses[3] || status === availableStatuses[1] || status === availableStatuses[0];
  const createPayedStatus = status === availableStatuses[7] || status === availableStatuses[6] || status === availableStatuses[5] || status === availableStatuses[4] || status === availableStatuses[3] || status === availableStatuses[1];
  const declinedStatus = status === "DECLINADO";
  const errorStatus = status === "ERROR";
  const orderId = id_order;

  useEffect(() => {
    dispatch(currentOrderRequest(currentOrderId));
  }, [currentOrderId, dispatch]);

/*   useEffect(() => {
    if (status === availableStatuses[8]) {
      dispatch(clearShoppingCart());
      dispatch(clearCurrentOrder());
      dispatch(cancelOrderRequest(currentOrderId));
      history.push('/');
    }
  }, [status, currentOrderId, dispatch, history]) */

  return {
    orderId,
    currentOrderId,
    createdAtDate,
    confirmedAt,
    confirmedAtDate,
    deliveryDate,
    maxDeliveryDate,
    createdStatus,
    confirmedStatus,
    onEnlistmentStatus,
    onAmountToPaidStatus,
    onTransitStatus,
    onFinalizedStatus,
    cancelStatus,
    payToProcessStatus,
    createPayedStatus,
    declinedStatus,
    errorStatus,
    status,
    observations_order,
    price_domicile,
    price_total,
    observations_admin,
    domiciliary,
    domiciliary_id,
    statusDates,
    separatedAtDate,
    priceToPayedAtDate,
    dispatchedAtDate, 
    payToProcessAtDate,
    createPayedAtDate,
    cancel_description,
    client_response,
    amountPaidDate
  }
};

export default useTrackingOrderBehavior;
