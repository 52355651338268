import React, { Fragment, memo, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
// import CarouselAlliesDesktop from "components/carousels/allies/CarouselAlliesDesktop";
import CarouselProductsDesktop from "components/carousels/products/CarouselProducts";
import { splitCategoria } from "utils/format";
import CarouselImageDesktop from "components/carousels/allies/CarouselImageDesktop";
import { useExistsProducts } from "hooks";
import CarouselOfferProducts from "components/carousels/products/CarouselOfferProducts";

const HomeDesktopPage = ({ categories, currentCity }) => {
  const { setExists, exists, existsProducts } = useExistsProducts();
  return (
    <>
      <CarouselImageDesktop />
      <div className="container_products">
        <div className="row">
          <div className="col-lg-12 mb-3">
            {/* <section className="content-pages content-allies"  >
            <h3 className="color-default category-title-content">
                  Ingresa a nuestras tiendas oficiales
                </h3>
          <CarouselAlliesDesktop />
          </section> */}

            <section className="content-pages" tabIndex={1}>
              <div className="row bottom-10">
                <div className="col-md-12">
                  <h3 className="color-default category-title-content">
                    {splitCategoria("Ofertas")}
                  </h3>
                </div>
              </div>
              { categories.length > 0&&
              <CarouselOfferProducts
              categories={categories}
              />
              }

              {categories.length > 0&&categories.map(
                (category, index) =>
                  currentCity &&
                  !exists.find((cat) => cat === category.id) && (
                    <Fragment key={`category-${index}`}>
                      <div className="row bottom-10">
                        <div className="col-md-12">
                          <h3 className="color-default category-title-content">
                            {splitCategoria(category.name || category.id)}
                            <Link
                              to={`/categoria/${category.id}`}
                              className="color-default left-40 font-13"
                            >
                              Ver más <i className="fa fa-chevron-right" />
                            </Link>
                          </h3>
                        </div>
                      </div>
                      <CarouselProductsDesktop
                        categories={categories}
                        category={category.id}
                        existsProducts={existsProducts}
                      />
                    </Fragment>
                  )
              )}
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

HomeDesktopPage.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object),
};

HomeDesktopPage.defaultProps = {
  categories: [],
};

export default memo(HomeDesktopPage);
