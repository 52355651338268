import React from 'react';
import { Link, Redirect } from "react-router-dom";
import { isMobile } from 'react-device-detect';

import { connect } from "react-redux";
import { signUp } from "../store/actions/authActions";
import { routes } from "../../../router/router";
import { Snackbar } from '@material-ui/core';
import {
    clearAuth
} from "../store/actions/authActions";
import ToolbarMobile from "components/layout/components/mobile/ToolbarMobile";
import MuiAlert from '@material-ui/lab/Alert';
import { clearCurrentOrder } from '../../../store/domains/orders/ordersActions';

class RegisterMobile extends React.Component {

    state = {
        name: '',
        last_name: '',
        email: '',
        password: '',
        repeat_password: '',
        phone: '',
        password_match: true,
        valid_data: false,
    };

    handleSubmit = (e) => {
        e.preventDefault();
        console.log(this.state);
        this.props.clearCurrentOrder();
        this.props.signUp(this.state);
    };

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value,
        });

        if (e.target.id === 'password') {
            this.setState({ password_match: e.target.value === this.state.repeat_password ? true : false });
        }

        if (e.target.id === 'repeat_password') {
            this.setState({ password_match: e.target.value === this.state.password ? true : false });
        }
        
    };

    // Snackbar handle close
    handleClose = (e, reason) => {
        this.props.clearAuth();
    }

    render() {

        const { auth, authError } = this.props;
        if (auth.uid) return <Redirect to={routes.home.path} />;

        if (isMobile) {
            return (
                <React.Fragment>
                    <ToolbarMobile isBack={true} showCart={true} />
                    <div id="page-transitions" className="page-build light-skin highlight-blue">
                        <div className="page-content h-fixed">
                            <div className="page-login page-login-full bottom-20">
                                <form onSubmit={this.handleSubmit}>
                                    <div className="page-login-field top-15">
                                        <input type="text" placeholder="Nombre" id="name"
                                            onChange={this.handleChange} required />
                                    </div>
                                    <div className="page-login-field top-15">
                                        <input type="text" placeholder="Apellido" id="last_name"
                                            onChange={this.handleChange} required />
                                    </div>
                                    <div className="page-login-field top-15">
                                        <input type="text" placeholder="Teléfono" id="phone"
                                            onChange={this.handleChange} required />
                                    </div>
                                    <div className="page-login-field">
                                        <input type="text" placeholder="Correo electrónico" id="email"
                                            onChange={this.handleChange} required />
                                    </div>
                                    <div className="page-login-field">
                                        <input type="password" placeholder="Contraseña" id="password"
                                            onChange={this.handleChange} required />
                                    </div>
                                    <div className="page-login-field">
                                        <input type="password" placeholder="Confirma tu contraseña" id="repeat_password"
                                            onChange={this.handleChange} required />
                                    </div>
                                    {
                                        !this.state.password_match &&
                                        <span className="color-red top-10 center-text">
                                            Las contraseñas deben coincidir
                                        </span>
                                    }
                                    <div className="page-login-links bottom-30 top-15">
                                        <p className="center-text">
                                            Al crear tu cuenta estás aceptando los<br />Términos y condiciones y la política de privacidad.
                                    </p>
                                        <div className="clear" />
                                        <Link to="/terminos">
                                            <p className="center-text top-10">
                                                <span className="color-green">
                                                    Ver terminos y condiciones
                                            </span>
                                            </p>
                                        </Link>
                                    </div>
                                    <button
                                        disabled={!this.state.password_match}
                                        className={`button button-green button-full button-rounded button-sm font-15 shadow-small`}>
                                        Crear cuenta
                                </button>
                                    <p className="center-text bottom-10 top-25 font-16">¿Ya tienes una cuenta?</p>
                                    <Link to={routes.login.path}
                                        className="forgot button-center button-full font-16 center-text">
                                        Ingresa aquí
                                </Link>
                                    <Snackbar open={authError ? true : false} autoHideDuration={6000} onClose={this.handleClose} >
                                        <div className="alert">
                                            <MuiAlert elevation={6} variant="filled" severity="error" onClose={this.handleClose}>
                                                {authError}
                                            </MuiAlert>
                                        </div>
                                    </Snackbar>
                                </form>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            );
        }

        return (
            <div>Esto es la web</div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        authError: state.auth.authError
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        clearCurrentOrder: () => dispatch(clearCurrentOrder()),
        signUp: (newUser) => dispatch(signUp(newUser)),
        clearAuth: () => dispatch(clearAuth())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterMobile);
