import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Item from 'components/item/ItemDesktop';
import bucanero_banner from "images/bucanero_banner.jpg";
import bavaria_banner from "images/bavaria_banner.jpg";
import nestle_banner from "images/nestle_banner.png";
import dona_lupe_banner from "images/banner-dona-lupe.png";
import brinsa_banner from 'images/brinsa-banner.png';
import huevos_oro_banner from 'images/huevos_oro_banner.png';
import lativital_banner from 'images/lativital_banner.png';
import pg_banner from 'images/pg_banner.png';


const AlliesGridDesktop = ({ category, products }) => (
  <div className="container">
    <h2>{name(category)}</h2>
    <div className="container align-center">
      <img src={images(category)} alt="banner" className='img-banner'/>
    </div>
    <div className="row mx-auto" style={{ marginTop: 30 }}>
      {products.map((product) => (
        <div className="col-md-3">
          <Item product={product} />
        </div>))}
    </div>
  </div>
);

function images(category) {

  if (category === "KOPPS COMMERCIAL S A S")
    return bavaria_banner
  if (category === "POLLOS EL BUCANERO S.A.")
    return bucanero_banner
  if (category === "NESTLE DE COLOMBIA S.A.")
    return nestle_banner
  if (category === "DONA LUPE")
    return dona_lupe_banner
  if (category === "BRINSA S.A.")
    return brinsa_banner
  if (category === "NUTRIENTES AVICOLAS S.A.")
    return huevos_oro_banner
  if (category === "LA TINAJA PRODUCTOS ALIMENTICIOS SAS")
    return lativital_banner
  if (category === "PROCTER & GAMBLE COLOMBIA LTDA")
    return pg_banner  

}

function name(category) {

  if (category === "KOPPS COMMERCIAL S A S")
    return "Bavaria"
  if (category === "POLLOS EL BUCANERO S.A.")
    return "Pollos Bucanero"
  if (category === "DONA LUPE")
    return "Doña Lupe"
  if (category === "NESTLE DE COLOMBIA S.A.")
    return "NESTLE"
  if (category === "BRINSA S.A.")
    return "Brinsa"
  if (category === "NUTRIENTES AVICOLAS S.A.")
    return "Huevos Oro"
  if (category === "LA TINAJA PRODUCTOS ALIMENTICIOS SAS")
    return "Lativital"
  if (category === "PROCTER & GAMBLE COLOMBIA LTDA")
    return "P&G"  

}


AlliesGridDesktop.propTypes = {
  category: PropTypes.string,
  products: PropTypes.object
};

AlliesGridDesktop.defaultProps = {
  category: '',
  products: []
};

export default memo(AlliesGridDesktop);
