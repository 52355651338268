import React, { useState, useEffect } from 'react'
import "react-alice-carousel/lib/alice-carousel.css"
import Slider from "react-slick";
import { isBrowser } from 'react-device-detect';
import { configSelector } from 'selectors';
import { useSelector } from 'react-redux';

const CarouselImageDesktop = () => {

    const configs = useSelector(configSelector);

    const [image, setImage] = useState(undefined);

    useEffect(() => {
        if (configs.length > 0) {
            setImage(configs.filter((item) => item.photos_slides !== undefined)[0].photos_slides)
        }
    }, [configs])


    const galleryItems = image ? image.map((i) => (<Item key={i} data={i} />)) : "";


    const settings = {
        infinite: true,
        speed: 1000,
        autoplaySpeed: 6000,
        cssEase: "linear",
        autoplay: true,
        row: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: isBrowser ? 1000 : 1920,
                settings: {
                    slidesToShow: 1,

                }
            },
            {
                breakpoint: isBrowser ? 1000 : 1300,
                settings: {
                    slidesToShow: 1,

                }
            },
            {
                breakpoint: isBrowser ? 1100 : 1000,
                settings: {
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: isBrowser ? 700 : 500,
                settings: {
                    slidesToShow: 1,

                }
            }
        ]

    };

    return (
        <Slider {...settings} className="img-slider">
            {galleryItems}
        </Slider>
    )

}

function Item(props) {

    return (
        <div className="container align-center">
            <img src={props.data} draggable="false" alt="img" className='img-banner-slider' />
        </div>
    );
}

export default CarouselImageDesktop;