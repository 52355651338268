import ReduxSagaFirebase from 'redux-saga-firebase';
import { all } from 'redux-saga/effects';
import { categoriesSaga } from './domains/category/categorySagas';
import { categoryDetailSaga } from './domains/categoryDetail/categoryDetailSagas';
import { productsSaga } from './domains/products/productsSagas';
import { shoppingCartSaga } from './domains/shoppingCart/shoppingCartSagas';
import { ordersSaga } from './domains/orders/ordersSagas';
import { userSaga, loginRootSaga } from './domains/user/userSagas';
import { configsSaga } from './domains/config/configSagas';
import firebaseApp from '../config/firebaseConfig';

export const reduxSagaFirebase = new ReduxSagaFirebase(firebaseApp);

export default function* rootSaga() {
  yield all([
    categoriesSaga(),
    categoryDetailSaga(),
    productsSaga(),
    shoppingCartSaga(),
    ordersSaga(),
    userSaga(),
    loginRootSaga(),
    configsSaga()
  ]);
}
