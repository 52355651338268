import React from "react";

class DeliveryDetailsDesktop extends React.Component {
    render() {
        return (
            <React.Fragment>
                <div>Esto es web</div>
            </React.Fragment>
        );
    }
}

export default DeliveryDetailsDesktop;
