import React from 'react';
import {isBrowser, isMobile } from 'react-device-detect';
import ResetPasswordSuccessDesktop from "./ResetPasswordSuccessDesktop";
import ResetPasswordSuccessMobile from "./ResetPasswordSuccessMobile";

class ResetPasswordSuccess extends React.Component {
    render() {
        if (isBrowser) {
            return <ResetPasswordSuccessDesktop/>
        }

        if (isMobile) {
            return <ResetPasswordSuccessMobile/>;
        }
    }
}

export default ResetPasswordSuccess;

