import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';
import 'firebase/messaging';

// Initialize Firebase
var config = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGIN_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID
    // measurementId: "G-0FEF7ZLGRG"
};

firebase.initializeApp(config);
firebase.firestore();

// firebase.firestore().enablePersistence({experimentalTabSynchronization: true});

let messaging;

window.isUpdateAvailable = new Promise(function (resolve, reject) {
    if ('serviceWorker' in navigator) { // && ['localhost', '127'].indexOf(location.hostname) === -1) {
        window.addEventListener('load', async () => {
            await navigator.serviceWorker.register('worker.js')
                .then(reg => {
                    if (firebase.messaging.isSupported()) {
                        messaging = firebase.messaging();
                        messaging.useServiceWorker(reg);
                    } else {
                        console.log('Unsupported browser for firebasase messaging');
                    }
                    reg.onupdatefound = () => {
                        const installingWorker = reg.installing;
                        installingWorker.onstatechange = () => {
                            if (installingWorker.state === 'installed') {
                                console.log(navigator.serviceWorker.controller)
                                if (navigator.serviceWorker.controller) {
                                    // new update available
                                    resolve(true);
                                } else {
                                    // no update available
                                    console.log('No update available ');
                                    resolve(false);

                                }
                            } else {
                                console.log('No installed state:' + installingWorker.state);
                            }
                        };
                    };
                })
                .catch(err => console.error('[SW ERROR]', err));
        });
    }
});


if (!("Notification" in window)) {
    console.log("Not Notification")
} else if (Notification.permission === "granted") {
    console.log("Granted Notification")
} else if (Notification.permission !== "denied") {
    Notification.requestPermission().then(function (permission) {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
            //var notification = new Notification("Hi there!");
        }
    });
}


export default firebase;
