import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Highlight, connectAutoComplete } from 'react-instantsearch-dom';
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import Autosuggest from 'react-autosuggest';
import styles from './Autocomplete.module.css';

const AutoComplete = (props) => {
  
  const [value, setValue] = useState( props.currentRefinement );
  const currentCity = useSelector((state) => (state.cities.city));
  const cityFilter = currentCity? currentCity.toLowerCase() : "medellin";

  let history = useHistory();

  const onChange = (_, { newValue }) => {
    if (!newValue) {
      props.onSuggestionCleared();
    }

    setValue(newValue);
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    props.refine(value);
  };

  const onSuggestionsClearRequested = () => {
    props.refine();
  };

  const getSuggestionValue = (hit) => {
    return hit.name;
  }

  const handleClick = () => {
    if(value){
      history.push(`/buscar/${ value }`);
    }
  }

  const renderSuggestionsContainer = ({ containerProps, children, query }) => {
    return (
      <div {...containerProps}>
        {children}
        <div className={styles['search-more']} onClick={ handleClick }>
          {/* Press Enter to search <strong>{query}</strong> */}
          Buscar <strong>{query}</strong>
        </div>
      </div>
    );
  }

 
  const renderInputComponent = inputProps => (
    <div className="inputContainer">
      <input {...inputProps} />
      <button className="btn btn-warning" style={{position: 'absolute', height: 42, marginLeft: 14}} onClick={ handleClick } >
        <i className="fa fa-search color-white form-icon mr-2"></i>
        <span>Buscar</span>
      </button>
    </div>
  );

  const renderSuggestion = (hit) => {

    //console.log(hit);
    const notFoundImage = 'https://firebasestorage.googleapis.com/v0/b/prueba-lamontana.appspot.com/o/Imagen-no-encontrada-app-la-monta%C3%B1a.jpg?alt=media&token=3ac58f10-11c7-457f-850a-fcadbef87275';

    return (

      <div className={`row ${ styles['main-container'] }`}>
        <div className={`col-2 ${ styles['image-container']}`} >
          <img 
            className={ styles.image }
            alt={`product-${ hit.id_item }`} 
            src={`https://res.cloudinary.com/carvajal/image/upload/dpr_auto,q_50,f_auto,w_auto/productos/${ hit.id_item }`} 
            onError={(e)=>{e.target.onerror = null; e.target.src=notFoundImage}} 
          />
        </div>
        <div className={`col ${ styles['product-info-container'] }`}>
          <p className={ styles['product-name'] }>
            <Highlight attribute="name" hit={hit} tagName="mark"/> 
          </p>

          <b className={ styles['product-price'] }>
            ${ hit[`price_${cityFilter}`] ? hit[`price_${cityFilter}`] : hit.price }
          </b>
          <p className={ styles['product-unit-price'] }>
            { hit.unit_measurement_pum } a ${ hit.pum }
          </p>

        </div>
        <div className={`col-3 ${ styles['add-button-container'] }`}>
          <button type="submit" className={`btn btn-success`}>
            Ver Producto
          </button>
        </div>
      </div>
    )
  }

  const { hits, onSuggestionSelected } = props;

  const inputProps = {
    placeholder: 'Buscar productos',
    onChange: onChange,
    value,
  };

  return (
    <Autosuggest
      suggestions={hits}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      onSuggestionSelected={onSuggestionSelected}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      renderSuggestionsContainer={renderSuggestionsContainer}
      inputProps={inputProps}
      renderInputComponent={renderInputComponent}
    />
  );
}

AutoComplete.propTypes = {
  hits: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentRefinement: PropTypes.string.isRequired,
  refine: PropTypes.func.isRequired,
  onSuggestionSelected: PropTypes.func.isRequired,
  onSuggestionCleared: PropTypes.func.isRequired,
};

export default connectAutoComplete(AutoComplete);
