import { createActions, handleActions, combineActions } from 'redux-actions';

export const defaultState = {
  status_order: "NO CREADO",
  createAt: "",
  link: false,
  list: [],
  currentOrder: {},
  ui: {
    isLoading: true
  }
};

const {
  currentOrderRequest,
  currentOrderSuccessed,
  currentOrderFailed,
  clearCurrentOrder,
  orderListRequest,
  orderListSuccessed,
  orderListFailed,
  finishOrderRequest,
  finishOrderSuccessed,
  finishOrderFailed,
  cancelOrderRequest,
  cancelOrderSuccessed,
  cancelOrderFailed,
  updateStatusOrder,
  updateCreateAt,
  createLinkToPay
} = createActions({
  CURRENT_ORDER_REQUEST: (orderId) => ({ orderId }),
  CURRENT_ORDER_SUCCESSED: (orderData) => ({ orderData }),
  CURRENT_ORDER_FAILED: undefined,
  CLEAR_CURRENT_ORDER: undefined,
  ORDER_LIST_REQUEST: undefined,
  ORDER_LIST_SUCCESSED: (orders) => ({ orders }),
  ORDER_LIST_FAILED: undefined,
  FINISH_ORDER_REQUEST:  (orderId) => ({ orderId }),
  FINISH_ORDER_SUCCESSED: (orderData) => ({ orderData }),
  FINISH_ORDER_FAILED: undefined,
  CANCEL_ORDER_REQUEST:  (orderId, statusDates) => ({ orderId, statusDates }),
  CANCEL_ORDER_SUCCESSED: (orderData) => ({ orderData }),
  CANCEL_ORDER_FAILED: undefined,
  UPDATE_STATUS_ORDER: (status) => ({status}),
  UPDATE_CREATE_AT: (createAt) => ({createAt}),
  CREATE_LINK_TO_PAY: (link) => ({ link })
});

const reducer = handleActions({
  [combineActions(
    currentOrderRequest,
    orderListRequest,
    finishOrderRequest,
    cancelOrderRequest)]: (state) => ({
    ...state,
    ui: {
      isLoading: true
    }
  }),
  [finishOrderSuccessed]: (state) => {
    return({
    ...state,
    list: [],
    currentOrder: {},
    ui: {
      ...state.ui,
      isLoading: false
    }
  })},
  [cancelOrderSuccessed]: (state) => ({
    ...state,
    list: [],
    currentOrder: {},
    ui: {
      ...state.ui,
      isLoading: false
    }
  }),
  [currentOrderSuccessed]: (state, { payload: { orderData }}) => ({
    ...state,
    currentOrder: {
      ...state.currentOrder,
      ...orderData
    },
    ui: {
      isLoading: false
    }
  }),
  [combineActions(
    currentOrderFailed,
    orderListFailed,
    finishOrderFailed,
    cancelOrderFailed)]: (state) => ({
    ...state,
    ui: {
      isLoading: false
    }
  }),
  [orderListSuccessed]: (state, { payload: { orders }}) => ({
    ...state,
    list: orders,
    ui: {
      isLoading: false
    }
  }),
  [updateStatusOrder]: (state, { payload: { status }}) => ({
    ...state,
    status_order: status
  }),
  [updateCreateAt]: (state, { payload: { createAt }}) => ({
    ...state,
    createAt: createAt
  }),
  [clearCurrentOrder]: () => ({ ...defaultState, currentOrder: {} }),
  [createLinkToPay]: (state, { payload: { link } }) => ({
    ...state,
    link: link,
    ui: {
      isLoading: false
    }
  })
}, defaultState);

export {
  currentOrderRequest,
  currentOrderSuccessed,
  currentOrderFailed,
  clearCurrentOrder,
  orderListRequest,
  orderListSuccessed,
  orderListFailed,
  finishOrderRequest,
  finishOrderSuccessed,
  finishOrderFailed,
  cancelOrderRequest,
  cancelOrderSuccessed,
  cancelOrderFailed,
  updateStatusOrder,
  updateCreateAt,
  createLinkToPay
};

export default reducer;
