import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setCurrentCity, setModalState } from 'store/domains/cities/citiesAction'

const ChangeCitiesHome = () => {
    const dispatch = useDispatch();
    
    const [city, setCity] = useState(undefined);

    const handleChangeCity = (city_selected) => {
        setCity(city_selected);
    }

    useEffect(() => {
        if( city ){
            dispatch(setCurrentCity(city))
        }
    },[ city, dispatch ])

    const handleToggleModal = (modal_state) => {
        dispatch( setModalState(modal_state) )
    }

    return {
        handleChangeCity,
        handleToggleModal
    }
}

export default ChangeCitiesHome;