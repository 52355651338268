import { createActions, handleActions } from 'redux-actions';

const defaultState = {
  products: [],
  ui: {
    isLoading: false
  }
};

const {
  loadProductsPerCategoryRequest,
  loadProductsPerCategorySuccess,
  loadProductsPerCategoryFailed
} = createActions({
  LOAD_PRODUCTS_PER_CATEGORY_REQUEST: undefined,
  LOAD_PRODUCTS_PER_CATEGORY_SUCCESS: (products) => ({ products }),
  LOAD_PRODUCTS_PER_CATEGORY_FAILED: undefined
});

const reducer = handleActions({
  [loadProductsPerCategoryRequest]: (state) => ({
    ...state,
    ui: {
      isLoading: true
    }
  }),
  [loadProductsPerCategorySuccess]: (state, { payload: { products }}) => ({
    ...state,
    products: products,
    ui: {
      isLoading: false
    }
  }),
  [loadProductsPerCategoryFailed]: (state)=> ({
    ...state,
    ui: {
      isLoading: false
    }
  })
}, defaultState);

export {
  defaultState,
  loadProductsPerCategoryRequest,
  loadProductsPerCategorySuccess,
  loadProductsPerCategoryFailed
};

export default reducer;
