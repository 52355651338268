export const cities = [
  {
    code: "20011",
    name: "Aguachica",
  },
  {
    code: "05045",
    name: "Apartadó",
  },
  {
    code: "63001",
    name: "Armenia",
  },
  {
    code: "68081",
    name: "Barrancabermeja",
  },
  {
    code: "08001",
    name: "Barranquilla",
  },
  {
    code: "05088",
    name: "Bello",
  },
  {
    code: "11001",
    name: "Bogotá",
  },
  {
    code: "68001",
    name: "Bucaramanga",
  },
  {
    code: "76109",
    name: "Buenaventura",
  },
  {
    code: "76111",
    name: "Buga",
  },
  {
    code: "76001",
    name: "Cali",
  },
  {
    code: "13001",
    name: "Cartagena",
  },
  {
    code: "52694",
    name: "Cartago",
  },
  {
    code: "23162",
    name: "Cereté",
  },
  {
    code: "66594",
    name: "Chía",
  },
  {
    code: "23189",
    name: "Ciénaga",
  },
  {
    code: "54001",
    name: "Cúcuta",
  },
  {
    code: "66170",
    name: "Dosquebradas",
  },
  {
    code: "15238",
    name: "Duitama",
  },
  {
    code: "05266",
    name: "Envigado",
  },
  {
    code: "25269",
    name: "Facatativá",
  },
  {
    code: "18001",
    name: "Florencia",
  },
  {
    code: "68276",
    name: "Floridablanca",
  },
  {
    code: "25286",
    name: "Funza",
  },
  {
    code: "25290",
    name: "Fusagasugá",
  },
  {
    code: "25307",
    name: "Girardot",
  },
  {
    code: "68307",
    name: "Girón",
  },
  {
    code: "73001",
    name: "Ibagué",
  },
  {
    code: "52356",
    name: "Ipiales",
  },
  {
    code: "52356",
    name: "Itagüí",
  },
  {
    code: "76364",
    name: "Jamundí",
  },
  {
    code: "23417",
    name: "Lorica",
  },
  {
    code: "25430",
    name: "Madrid",
  },
  {
    code: "13430",
    name: "Magangué",
  },
  {
    code: "44430",
    name: "Maicao",
  },
  {
    code: "08433",
    name: "Malambo",
  },
  {
    code: "17001",
    name: "Manizales",
  },
  {
    code: "05001",
    name: "Medellín",
  },
  {
    code: "23001",
    name: "Montería",
  },
  {
    code: "25473",
    name: "Mosquera",
  },
  {
    code: "41001",
    name: "Neiva",
  },
  {
    code: "54498",
    name: "Ocaña",
  },
  {
    code: "76520",
    name: "Palmira",
  },
  {
    code: "52001",
    name: "Pasto",
  },
  {
    code: "66001",
    name: "Pereira",
  },
  {
    code: "68547",
    name: "Piedecuesta",
  },
  {
    code: "41551",
    name: "Pitalito",
  },
  {
    code: "19001",
    name: "Popayán",
  },
  {
    code: "27001",
    name: "Quibdó",
  },
  {
    code: "44001",
    name: "Riohacha",
  },
  {
    code: "68615",
    name: "Rionegro",
  },
  {
    code: "05628",
    name: "Sabanalarga",
  },
  {
    code: "23660",
    name: "Sahagún",
  },
  {
    code: "47001",
    name: "Santa Marta",
  },
  {
    code: "19698",
    name: "Santander de Quilichao",
  },
  {
    code: "70001",
    name: "Sincelejo",
  },
  {
    code: "25754",
    name: "Soacha",
  },
  {
    code: "15759",
    name: "Sogamoso",
  },
  {
    code: "08758",
    name: "Soledad",
  },
  {
    code: "76834",
    name: "Tuluá",
  },
  {
    code: "52835",
    name: "Tumaco",
  },
  {
    code: "15001",
    name: "Tunja",
  },
  {
    code: "13836",
    name: "Turbaco",
  },
  {
    code: "05837",
    name: "Turbo",
  },
  {
    code: "44847",
    name: "Uribia",
  },
  {
    code: "20001",
    name: "Valledupar",
  },
  {
    code: "54874",
    name: "Villa del Rosario",
  },
  {
    code: "50001",
    name: "Villavicencio",
  },
  {
    code: "85001",
    name: "Yopal",
  },
  {
    code: "76892",
    name: "Yumbo",
  },
  {
    code: "25899",
    name: "Zipaquirá",
  },
];

export const stores = ["Ingenio", "Limonar", "Cra 1"];

export const deliveryTimes = [
  "10:00 AM",
  "10:30 AM",
  "11:00 AM",
  "11:30 AM",
  "12:00 PM",
  "12:30 PM",
  "01:00 PM",
  "01:30 PM",
  "02:00 PM",
  "02:30 PM",
  "03:00 PM",
  "03:30 PM",
  "04:00 PM",
  "04:30 PM",
  "05:00 PM",
  "05:30 PM",
  "06:00 PM",
];

export const deliveryDays = [
  {
    code: 1,
    day: "Lunes",
    close: "04:00 PM",
  },
  {
    code: 2,
    day: "Martes",
    close: "04:00 PM",
  },
  {
    code: 3,
    day: "Miercoles",
    close: "04:00 PM",
  },
  {
    code: 4,
    day: "Jueves",
    close: "04:00 PM",
  },
  {
    code: 5,
    day: "Viernes",
    close: "04:00 PM",
  },
  {
    code: 6,
    day: "Sabado",
    close: "04:00 PM",
  },
  {
    code: 0,
    day: "Domingo",
    close: "00:00 PM",
  },
];

export const options = [
  { value: "alphabetically", label: "Orden alfabético" },
  { value: "largest", label: "Mayor a menor precio" },
  { value: "smallest", label: "Menor a mayor precio" },
  { value: "popular", label: "Productos populares" },
];
