import { clearShoppingCart } from 'store/domains/shoppingCart/shoppingCartActions';

export const LOGIN_SUCCESS = "[AUTH] LOGIN_SUCCESS";
export const LOGIN_ERROR = "[AUTH] LOGIN_ERROR";
export const HANDLE_SHOW = "[MODAL] HANDLE_SHOW";
export const HANDLE_CLOSE = "[MODAL] HANDLE_CLOSE";
export const SIGNOUT_SUCCESS = "[AUTH] SIGNOUT_SUCCESS";
export const SIGNUP_SUCCESS = "[AUTH] SIGNUP_SUCCESS";
export const SIGNUP_ERROR = "[AUTH] SIGNUP_ERROR";
export const LOADING_LOGIN = "[AUTH] LOADING_LOGIN";
export const FORGOTPW_SUCCESS = "[AUTH] FORGOTPW_SUCCESS";
export const FORGOTPW_ERROR = "[AUTH] FORGOTPW_ERROR";


export const signInWithCredentials = (credentials) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firebase = getFirebase();
        dispatch({type: LOADING_LOGIN});

        firebase.auth().signInWithEmailAndPassword(
            credentials.email,
            credentials.password
        ).then(() => {            
            dispatch({type: LOGIN_SUCCESS});
        }).catch((err) => {
            dispatch({type: LOGIN_ERROR, err});
        });
    }
};

export const signInWithGoogle = () => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firebase = getFirebase();
        const firestore = getFirestore();

        dispatch({type: LOADING_LOGIN});

        var provider = new firebase.auth.GoogleAuthProvider();
        provider.setCustomParameters({
            prompt: 'select_account'
        });

        signInFirebaseProvider(firebase, firestore, provider, dispatch);
    }
};

export const signInWithFacebook = () => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firebase = getFirebase();
        const firestore = getFirestore();

        dispatch({type: LOADING_LOGIN});

        var provider = new firebase.auth.FacebookAuthProvider();

        signInFirebaseProvider(firebase, firestore, provider, dispatch);
    }
};

export const signInWithPhone = (newUser) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firebase = getFirebase();
        const firestore = getFirestore();

        const applicationVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container');

        dispatch({type: LOADING_LOGIN});

        firebase.auth().signInWithPhoneNumber(
            newUser.phone,
            applicationVerifier
        ).then(resp => {            
            firestore.collection('users').doc(resp.user.uid).set({
                ...newUser,
                uid: resp.user.uid,
                created_date: firestore.FieldValue.serverTimestamp(),
            }, { merge: true });
        }).then(() => {
            dispatch({type: SIGNUP_SUCCESS});
        }).catch((err) => {
            dispatch({type: SIGNUP_ERROR, err});
        });

        //const verificationCode = /* implement your own logic to get the user's verification code */
        // const credentials = yield call(confirmationResult.confirm, verificationCode);
    }
};

export const clearAuth = () => {
    return (dispatch, getState) => {
        dispatch({type: null});
    }
}

function signInFirebaseProvider(firebase, firestore, provider, dispatch) {
    firebase.auth().signInWithPopup(provider).then(resp => {        
        firestore.collection('users').doc(resp.user.uid).set({
            name: resp.user.displayName,
            photoURL: resp.user.photoURL,
            email: resp.user.email,
            uid: resp.user.uid,
            created_date: firestore.FieldValue.serverTimestamp(),
        }, { merge: true });
    }).then(() => {
        dispatch({type: LOGIN_SUCCESS});
    }).catch(err => {
        dispatch({type: LOGIN_ERROR, err});
    });
}

export const handleShow = () => {
    return (dispatch, getState) => {
        dispatch({type: HANDLE_SHOW});
    }
};

export const handleClose = () => {
    return (dispatch, getState) => {
        dispatch({type: HANDLE_CLOSE});
    }
};

export const signOut = () => {
    return (dispatch, getState, {getFirebase}) => {
        const firebase = getFirebase();
        // const history = useHistory();

        dispatch({type: LOADING_LOGIN});

        firebase.auth().signOut().then(() => {
            dispatch({type: SIGNOUT_SUCCESS});
            //clear shopping cart
            dispatch(clearShoppingCart());

            // dispatch( history.push('/'));
        });
    }
};

export const signUp = (newUser) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firebase = getFirebase();
        const firestore = getFirestore();

        dispatch({type: LOADING_LOGIN});
        firebase.auth().createUserWithEmailAndPassword(
            newUser.email,
            newUser.password
        ).then(resp => { 
            firestore.collection('users').doc(resp.user.uid).set({
                name: newUser.name,
                last_name: newUser.last_name,
                acceptTerms: true,
                email: newUser.email,
                uid: resp.user.uid,
                created_date: firestore.FieldValue.serverTimestamp(),
                phone: newUser.phone
            }, { merge: true });
        }).then(() => {
            dispatch({type: SIGNUP_SUCCESS});
        }).catch((err) => {
            dispatch({type: SIGNUP_ERROR, err});
        });
    }
};

export const forgotPassword = (email) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firebase = getFirebase();
        dispatch({type: LOADING_LOGIN});
        
        firebase.auth().sendPasswordResetEmail(email)
            .then(() => {
                dispatch({type: FORGOTPW_SUCCESS});
            }).catch((err) => {
                dispatch({type: FORGOTPW_ERROR, err});
            });
    }
}