import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isBrowser } from "react-device-detect";
import { useParams } from 'react-router-dom';
import { loadProductsPerCategoryRequest } from 'store/domains/categoryDetail/categoryDetailActions';
import { loadProductsRequest } from "store/domains/products/productsActions";
import AlliesGridDesktop from './AlliesGridDesktop';
import AlliesGridMobile from './AlliesGridMobile';
import { Loading } from 'components/common/Loading';


const Allies = () => {
	const { categoryName } = useParams();
	const products = useSelector((state) => (state.categoryDetail));
	const dispatch = useDispatch();
	const CategoryLayout = isBrowser ? AlliesGridDesktop : AlliesGridMobile;
	const currentCity = useSelector((state) => (state.cities.city));
	const city = currentCity? currentCity.toLowerCase() : "medellin";
	useEffect(() => {
		// fetch all products per category, and filter in category layout if a subcategory is provided
		dispatch(loadProductsPerCategoryRequest(categoryName,city));
		dispatch(loadProductsRequest());
	}, [dispatch, categoryName]);


	return (
		products.ui.isLoading ?
			<Loading />
			:
			<CategoryLayout products={products.products} category={categoryName} />)
};

export default Allies;

