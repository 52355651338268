import React from 'react';
import {isBrowser, isMobile } from 'react-device-detect';
import AccountCreatedMobile from "./AccountCreatedMobile";
import AccountCreatedDesktop from "./AccountCreatedDesktop";

class AccountCreated extends React.Component {
    render() {

        if (isBrowser) {
            return <AccountCreatedDesktop/>;
        }

        if (isMobile) {
            return <AccountCreatedMobile/>;
        }
    }
}

export default AccountCreated;