import { createActions, handleActions } from 'redux-actions';

const defaultState = {
  city: 'Medellin',
  openModal: false,
  visibility:'visible',
  list: [],
  ui: {
    isLoading: false
  }
};

const {
    setCurrentCity,
    setModalState
} = createActions({
    SET_CURRENT_CITY: (city) => ({ city }),
    SET_MODAL_STATE: (openModal) => ({ openModal })
});

const reducer = handleActions({
    [setCurrentCity]: (state, { payload: { city } } ) => ({
        ...state,
        city: city,
        openModal: false
    }),
    [setModalState]: (state, { payload: {openModal} }) => ({
        ...state,
        openModal: openModal
    })
}, defaultState);

export {
    setCurrentCity,
    setModalState
}

export default reducer;