import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { isBrowser } from "react-device-detect";
import OrderDetailDesktop from "./OrderDetailDesktop";
import OrderDetailMobile from "./OrderDetailMobile";
// import { detailedProductSelector } from 'selectors';
import { orderListRequest } from "store/domains/orders/ordersActions";

const OrderDetail = () => {
  const orders = useSelector((state) => (state.orders.list));
  // const product = useSelector(detailedProductSelector);
  const dispatch = useDispatch();
  const OrderDetailLayout = isBrowser ? OrderDetailDesktop : OrderDetailMobile;

  const { id } = useParams();

  //let existentProduct = product.listPreview[categoryName].find(product => product.id_item === productId);

  const order = orders.find( (item) => item.id === id)

  useEffect(() => {
    dispatch(orderListRequest());
  }, [dispatch]);
  return (<OrderDetailLayout order={order} />);
};

export default OrderDetail;