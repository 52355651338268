import React from "react";
import { useSelector } from 'react-redux';
import { Link, withRouter } from "react-router-dom";
import { routes } from "../../../../router/router";
import logoApp from "../../../../images/logo-splash.png";
//import { updateStatusDrawer } from 'store/domains/config/configActions';

import AlertTerminos from 'components/alert/AlertTerminos';

const AVAILABLE_ORDER_STAGES = [routes.shopping_cart.path, routes.delivery_detail.path, routes.order.path];

const ToolbarMobile = ({ history, isBack, showCart: shouldShowCart, product = null, backToRoot = false, dismissUser = false }) => {
  const {countOrderItems, auth} = useSelector((state) => ({countOrderItems: state.shoppingCart.order.length, auth: state.firebase.auth,}));
  
  const orderStage = useSelector((state) => (state.shoppingCart.ui.orderStage));

  const handleBack = () => {
    if (backToRoot) {
      history.push('/');
    } else {
      history.goBack();
    }    
  }

  const leftIcon = (isBack) => {
    if (dismissUser === true) {
      return;
    }
    if (isBack) {
      return (
        <p onClick={() => {handleBack()}} className="header-icon header-icon-1">
          <i className="fa fa-arrow-left" />
        </p>
      );
    }
    if (auth.uid) {
      return (
        <Link to={routes.menu.path} className="header-icon header-icon-1">
          <i className="fa fa-user" />
        </Link>
      );
    }else{
      return (
        <Link to="/ingreso" className="header-icon header-icon-1">
          <i className="fa fa-user" />
        </Link>
      )
    }


  };
  // const addFavorite = (product) => {
  //   if (product !== null) {
  //     return (
  //       <a href data-menu="menu-find" className="header-icon header-icon-3">
  //         <i className="fa fa-heart" />
  //       </a>
  //     );
  //   }
  // };
  const showCart = (showCart) => {
    const CurrentOrderStage = AVAILABLE_ORDER_STAGES[orderStage];
    if (showCart) {
      return (
        <Link to={CurrentOrderStage} data-menu="menu-list" className="header-icon header-icon-4">
          <i className="fa fa-shopping-cart" />
          <div className="label-cart-order">{countOrderItems}</div>
        </Link>
      );
    }
  };

  return (
    <div className="demo-header shadow-tiny no-border header-line-1 header-logo-center fixed">
      <AlertTerminos />
      <div>
        <Link to={routes.home.path} className="header-logo-title">
          <img src={logoApp} alt="logoApp" />
        </Link>
        {leftIcon(isBack)}
        {/* {addFavorite(product)} */}
        {showCart(shouldShowCart)}
      </div>
    </div>
  );
} 

export default withRouter(ToolbarMobile);
