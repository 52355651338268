import { useState, useEffect } from 'react';
import {useDispatch} from 'react-redux';
import { addItemToOrder } from 'store/domains/shoppingCart/shoppingCartActions';
import Swal from 'sweetalert2';

const useDetailedProductBehavior = (product, handleClose) => {
  const dispatch = useDispatch();
  const [count, setCount] = useState(1);
  const [maturity, setMaturity] = useState("0");
  const [unityType, setUnityType] = useState(0);
  const [unityString, setUnityString] = useState('Unidad');
  const [comments, setComments] = useState('');
  const {
    unit_gram,
    discount,
    id_item,
    price,
    price_old,
    stock,
    title,
    unit_measurement,
    main_category_id, 
    quantity_pum,
    pum,
    sub_category_id,
    sub2_category_id,
    unit_measurement_full,
    brand
  } = product;

  const shouldRenderMatureSelector = main_category_id === '0004-FRUTAS Y VERDURAS';
  const shouldRenderMeetFruverSelector =  main_category_id === '0004-FRUTAS Y VERDURAS' || main_category_id === 'CARNES';
  const shouldDisplayDiscountData = +discount !== 0;
  const discountPercent = (price_old - price) / price_old;
  const handleClick = (operation) => {
    let value = count;

    if(operation === 'plus') {
      const inc = unityType === 0 ? count + 1 : count + unit_gram;
      value = inc;
      //value = inc > (unityType === 0 ? stock : quantity_pum * stock) ? count : inc;
    }
    
    if(operation === 'minus') {
      const dec = unityType === 0 ? count - 1 : count - unit_gram;
      value = dec > 0 ? dec : count;
    }

    if (value > 1 && unityType === 0) {
      setUnityString('Unidades');
    } else if (value === 1 && unityType === 0){
      setUnityString('Unidad');
    }
    setCount(value);
  };
  const handleChangeComments = (event) => setComments(event.target.value);
  
  const handleMaturity = (event) => setMaturity(event.target.value);

  const handleSubmit = ( isDesktop = false ) => {
    const item = {
      unit_gram,
      id_item,
      price,
      unit_measurement,
      stock,
      title,
      count,
      unityType,
      quantity_pum,
      pum,
      maturity,
      comments, 
      main_category_id,
      sub_category_id,
      sub2_category_id,
      unit_measurement_full,
      brand
    };
    if (sub2_category_id === undefined) {
      item.sub2_category_id = ''
    }

    if (main_category_id === 'LICORES'|| sub2_category_id==='BEBIDAS ALCOHOLICAS CERVEZA NACIONAL'|| sub2_category_id==='BEBIDAS ALCOHOLICAS CERVEZA NACIONAL') {

      if(isDesktop) {
        handleClose();
      }

      Swal.fire({
        title: '¿Eres mayor de edad?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Si',
        confirmButtonColor: '#068442',
        cancelButtonText: 'No',
        cancelButtonColor: '#B53737'
      })
      .then((result) => {
        if (result.value) {        
          dispatch(addItemToOrder(item));
        }
      })
      .finally(() => {handleClose()})
    } else {
      dispatch(addItemToOrder(item));
      handleClose();
    }
    
  };

  useEffect(() => {
    //Set unity to 1 and gr to 25
    setCount(unityType === 0 ? 1 : unit_gram);
    setUnityString(unityType === 0 ? 'Unidad' : 'Gramos');
  }, [unityType, unit_gram])

  useEffect(() => {
    setUnityType(shouldRenderMeetFruverSelector && unit_measurement_full !== 'UND' ? 1 : 0)
  }, [shouldRenderMeetFruverSelector, unit_measurement_full, sub_category_id ])

  const handleUnityTypeChange = (e, newValue) => {
    setUnityType(newValue);
    setUnityString(newValue === 0 ? 'Unidad' : 'Gramos');
  }

  return {
    count,
    comments,
    discountPercent,
    handleChangeComments,
    handleClick,
    handleSubmit,
    shouldDisplayDiscountData,
    handleUnityTypeChange,
    unityType,
    unityString,
    maturity,
    handleMaturity,
    shouldRenderMatureSelector,
    shouldRenderMeetFruverSelector
  }
};

export default useDetailedProductBehavior;
