import React from "react";
import products from '../../../images/productos.jpg';
import ToolbarMobile from "../../../components/layout/components/mobile/ToolbarMobile";

class FavoritesMobile extends React.Component {
    render() {
        return (
            <React.Fragment>

                <ToolbarMobile isBack={true} showCart={false}/>

                <div className="page-content h-fixed">

                    <div className="page-login page-login-full bottom-20">

                        <h2 className="regular top-0 bottom-30 font-30">Mis favoritos</h2>

                        <div className="store-cart-1 order">
                            <img className="preload-image rounded-image " src={products}
                                 data-src={products} alt="img"/>
                            <strong>Producto de prueba...</strong>
                            <span className="color-gray font-14"> 500ml</span>
                            <em className="color-green">$8.550</em>
                            <span className="right-15"><i className="fa fa-dollar-sign color-green"/> $3.5 por ml <i
                                className="fa fa-tag color-green left-15"/> Ahorra $1.710</span>
                        </div>
                        <div className="decoration top-5 bottom-5"/>
                        <div className="store-cart-1 order">
                            <img className="preload-image rounded-image " src={products}
                                 data-src={products} alt="img"/>
                            <strong>Producto de prueba...</strong>
                            <span className="color-gray font-14"> 500ml</span>
                            <em className="color-green">$8.550</em>
                            <span className="right-15"><i className="fa fa-dollar-sign color-green"/> $3.5 por ml <i
                                className="fa fa-tag color-green left-15"/> Ahorra $1.710</span>
                        </div>
                        <div className="decoration top-5 bottom-5"/>
                        <div className="store-cart-1 order">
                            <img className="preload-image rounded-image " src={products}
                                 data-src={products} alt="img"/>
                            <strong>Producto de prueba...</strong>
                            <span className="color-gray font-14"> 500ml</span>
                            <em className="color-green">$8.550</em>
                            <span className="right-15"><i className="fa fa-dollar-sign color-green"/> $3.5 por ml <i
                                className="fa fa-tag color-green left-15"/> Ahorra $1.710</span>
                        </div>
                        <div className="decoration top-5 bottom-5"/>
                        <div className="store-cart-1 order">
                            <img className="preload-image rounded-image " src={products}
                                 data-src={products} alt="img"/>
                            <strong>Producto de prueba...</strong>
                            <span className="color-gray font-14"> 500ml</span>
                            <em className="color-green">$8.550</em>
                            <span className="right-15"><i className="fa fa-dollar-sign color-green"/> $3.5 por ml <i
                                className="fa fa-tag color-green left-15"/> Ahorra $1.710</span>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default FavoritesMobile;
