import React from 'react'

const SearchNotFound = () => {
    return (
        <div className="notification-large notification-has-icon notification-blue">
          <div className="notification-icon btn btn-lin">
            <i className="fa fa-info notification-icon" />
          </div>
          <span style={{color: '#fff'}}>No se han encontrado datos para tu busqueda.</span>
        </div>
    )
}

export default SearchNotFound