import React from 'react';
import { isBrowser } from 'react-device-detect';
import '../../styles/mobile/framework-store.css';
import ShoppingCartDesktop from "./ShoppingCartDesktop";
import ShoppingCartMobile from "./ShoppingCartMobile";

const ShoppingCart = () => {
	const ShoppingCartLayout = isBrowser ? ShoppingCartDesktop : ShoppingCartMobile;

	return (<ShoppingCartLayout />);
};

export default ShoppingCart;
