import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import ToolbarMobile from "components/layout/components/mobile/ToolbarMobile";
import Image from 'components/image/Image';
import LazyLoad from 'react-lazyload';
import { formatCurrency, getMaturity, parseFloatWithComma } from 'utils/format';
import {useDispatch} from 'react-redux';
import { addItemToOrder } from 'store/domains/shoppingCart/shoppingCartActions';

const OrderDetailMobile = ({order}) => {

    const history = useHistory();
    const dispatch = useDispatch();

    const { id_order, status, products, address } = order;

    const handleToOrder = () => {
        products.map((product, index) => product.title !== 'OTROS PRODUCTOS' && dispatch(addItemToOrder(product)));
        history.push('/carrito_compras');
    }

    useEffect(() => {
        
    }, [])

    const orderPrice = products.reduce((acm, item) => (acm + (item.unityType === 0 ? item.price * item.count : parseFloatWithComma(item.pum) * item.count)), 0);
    const statusColor = status === 'CANCELADO' ? 'color-red' : status === 'FINALIZADO' ? 'color-blue' : 'color-green';
    const statusButton = status === 'CANCELADO' || status === 'FINALIZADO' ? 'button-green' : '';

    return (
        <React.Fragment>
            <ToolbarMobile isBack={true} showCart={false} />
            <div className="page-content h-fixed">
                <div className="page-login page-login-full bottom-20">
                    <h2 className="regular top-0 bottom-0 font-30">Detalle orden #{id_order}</h2>
                    <p className="font-20 top-10 bottom-30 color-default">{products.length} productos - Estado: <b className={statusColor}>{status}</b></p>
                    Dirección: {address}

                    {/* Product details */}
                    {products.map((product, index) => {
                        
                        const {
                            count,
                            id_item,
                            main_category_id,
                            maturity,
                            price,
                            pum,
                            title,
                            unityType,
                            unit_measurement_full,
                            comments
                        } = product;

                        return (
                            <div key={`product-${index}`}>                            
                                <div className="store-cart-1">
                                    <LazyLoad once>
                                        <Image id={id_item} isOthers={title === 'OTROS PRODUCTOS'} className="preload-image rounded-image product-cart" />
                                    </LazyLoad>
                                    <strong className="bottom-10">{title}</strong>
                                    {main_category_id === '0004-FRUTAS Y VERDURAS' && 
                                        <span className="color-gray font-14">              
                                            Madurez: <b>{getMaturity(maturity)}</b> 
                                        </span>}
                                    <span className="color-gray font-14">Medido en: { product.title === 'OTROS PRODUCTOS' ? 'No aplica' : (unityType === 0 ? <b>Unidades ({unit_measurement_full})</b> : <b>Gramos</b>)}</span>
                                    <span className="color-gray font-14 top-10 bottom-20">Comentarios: {comments}</span>
                                    <em className="color-green"><b>{formatCurrency(unityType === 0 ? price * count : parseFloatWithComma(pum) * count)}</b></em>
                                    <div className="store-cart-qty">
                                        <div className="font-16 top-10 align-text-left">Cantidad: <b>{count}</b></div>
                                    </div>
                                </div>
                                <div className="decoration top-10" />    
                            </div>
                        )                        
                    })}
                    
                    {/* Order details */}
                    <div className="row top-10 bottom-20">
                        <div className="col-6">
                            <p className="bottom-10 font-15 color-gray">Subtotal</p>
                            <p className="bottom-10 font-15 color-gray">Domicilio</p>
                        </div>
                        <div className="col-6">
                            <p className="bottom-10 font-15 color-gray right-text">{formatCurrency(orderPrice)}</p>
                            <p className="bottom-10 font-15 color-gray right-text">{formatCurrency(order.price_domicile)}</p>
                        </div>
                    </div>
                    <div className="row top-10 bottom-20">
                        <div className="col-6">
                            <p className="bottom-10 font-18 color-green font-weight-bold">Total</p>
                        </div>
                        <div className="col-6">
                            <p className="bottom-10 font-18 color-green right-text font-weight-bold">{formatCurrency(orderPrice + order.price_domicile)}</p>
                        </div>
                    </div>

                    <button className={`button bottom-15 button-full button-rounded shadow-small ${statusButton}`} onClick={handleToOrder} disabled={status === 'FINALIZADO' || status === 'CANCELADO' ? false : true}>Volver a pedir</button>

                </div>
            </div>
        </React.Fragment>
    )
}

OrderDetailMobile.propTypes = {
    order: PropTypes.object
};

OrderDetailMobile.defaultProps = {
    order: []
};

export default OrderDetailMobile;