import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { cities } from "utils/const";

const AddressModalDesktop = ({
  address,
  addressIndex,
  changeAddressData,
  handleClose,
  handleSubmit,
  handleChange,
  action,
  show
}) => (
  <Modal
    show={show}
    onHide={handleClose}
    animation={true}
    size="lg"
    id={"box-address"}
    dialogClassName="modal-blocactionk rounded-lg modal-block-lg mfp-hide"
    centered
  >
    <Modal.Header closeButton>
      <Modal.Title className='color-green'>{ action === 'new' ? 'Agregar dirección' : 'Editar dirección' }</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <form onSubmit={ (e) => { e.preventDefault(); action === 'new' ? handleSubmit() : handleChange(addressIndex)} }>
        <div className="form-row">
          <div className="col-md-4 mb-3">
            <label htmlFor="validationDefault01">Nombre personalizado</label>
            <input
              type="text"
              className="form-control"
              id="validationDefault01"
              value={address.alias}
              onChange={(event) => changeAddressData({ ...address, alias: event.target.value})}
              required
            />
          </div>
          <div className="col-md-4 mb-3">
            <label htmlFor="validationDefault02">Dirección</label>
            <input
              type="text"
              className="form-control"
              id="validationDefault02"
              onChange={(event) => changeAddressData({ ...address, value: event.target.value})}
              value={address.value}
              required
            />
          </div>
          <div className="col-md-4 mb-3">
            <label htmlFor="city-select">Ciudad</label>
            <select id="city-select" className="form-control" defaultValue='Cali' name="city-select" value={address.city} onChange={(event) => changeAddressData({ ...address, city: event.target.value})}>
              {
                cities.map((city) => <option value={city.name}>{city.name}</option>)
              }              
            </select>
          </div>
          <div className="col-md-4 mb-3">
            <label htmlFor="validationDefault03">Barrio</label>
            <input
              type="text"
              className="form-control"
              id="validationDefault03"
              onChange={(event) => changeAddressData({ ...address, neighborhood: event.target.value})}
              value={address.neighborhood}
              required
            />
          </div>
          <div className="col-md-4 mb-3">
            <label htmlFor="validationDefault04">Zona</label>
            <select id="validationDefault04" className="form-control" name="validationDefault04" value={address.zone} onChange={(event) => changeAddressData({ ...address, zone: event.target.value})}>
              <option value="Norte">Norte</option>
              <option value="Sur">Sur</option>
              <option value="Oriente">Oriente</option>
              <option value="Oeste">Oeste</option>
            </select>
          </div>
        </div>
        <div className="float-right">
          <button className="btn btn-success" type="submit">
            {action === 'new' ? 'Agregar dirección' : 'Guardar cambios'}
          </button>
        </div>
      </form>
    </Modal.Body>   
  </Modal>
);

AddressModalDesktop.propTypes = {
  address: PropTypes.object,
  addressIndex: PropTypes.number,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleChange: PropTypes.func,
  changeAddressData: PropTypes.func,
  show: PropTypes.bool,
  action: PropTypes.string
};

AddressModalDesktop.defaultProps = {
  address: {},
  addressIndex: null,
  handleClose: () => {},
  handleSubmit: () => {},
  handleChange: () => {},
  changeAddressData: () => {},
  show: false,
  action: 'new'
};

export default AddressModalDesktop;
