import React from 'react';
import {isBrowser, isMobile } from 'react-device-detect';
import ResetPasswordMobile from "./ResetPasswordMobile";
import ResetPasswordDesktop from "./ResetPasswordDesktop";

class ResetPassword extends React.Component {
    render() {

        if (isBrowser) {
            return <ResetPasswordDesktop/>
        }

        if (isMobile) {
            return <ResetPasswordMobile/>;
        }
    }
}

export default ResetPassword;
