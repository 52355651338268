import React from "react";
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { NavLink } from 'react-router-dom';
import { ItemMobile } from 'components/item';
import ToolbarMobile from "components/layout/components/mobile/ToolbarMobile";
import { splitCategoria } from 'utils/format';
import InputSearchMobile from 'components/inputSearchMobile/InputSearchMobile';


const SubCategoryMobile = ({ products, category, subcategory, categories }) => {
/* 
  const shouldFilterProductsPerCategory = category && category.length > 0;
  const categoryToShow = shouldFilterProductsPerCategory ? categories.filter((categorie) => (categorie.id === category)) : "";
  
  let nameCategory = categoryToShow[0].name !== category ? categoryToShow[0].name : category; */

  return (
    <>
      <ToolbarMobile isBack={true} showCart={true} />
      
      <div className="page-content h-fixed">
        <div className="page-login page-login-full bottom-20">
          <h2 className="regular top-0 bottom-0 font-30">{splitCategoria(subcategory)}</h2>
          <form>
            <div className="page-login-field top-15">
              {/* <i className="fa fa-search color-gray" />
              <input type="text" placeholder="Busca lo que más te guste" className="pl-30" /> */}
              <InputSearchMobile />
            </div>
          </form>
          <div className="slider-margins bottom-20">
            {products.filter((product) => product.sub_category_id === subcategory).map((product, index) => {
              const itemClassNames = classnames('one-half', {
                'last-column': (index + 1) % 2 === 0
              });

              return (
                <NavLink key={`item-${index}`} to={`/categoria/${category}/producto/${product.id_item}`} className={itemClassNames}>
                  <ItemMobile product={product} />
                </NavLink>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

SubCategoryMobile.propTypes = {
  category: PropTypes.string,
  products: PropTypes.arrayOf(PropTypes.object),
  categories: PropTypes.arrayOf(PropTypes.object)
};

SubCategoryMobile.defaultProps = {
  category: '',
  products: [],
  categories: []
};

export default SubCategoryMobile;