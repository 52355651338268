import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useShoppingCartValues, useLoggedUserStatus } from 'hooks';
import ToolbarMobile from "components/layout/components/mobile/ToolbarMobile";
import ShoppingCartMobileItem from 'components/item/ShoppingCartMobileItem';
import { formatCurrency } from 'utils/format';
import { incrementOrderStep } from 'store/domains/shoppingCart/shoppingCartActions';
import { configSelector } from 'selectors';

const ShoppingCartMobile = () => {
  const configs = useSelector(configSelector);
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    deliveryCost,
    order,
    subtotalValue,
    totalValue,
    itemCount,
    gramCount,
    handleBagsCount
  } = useShoppingCartValues();
  
  const isUserLogged = useLoggedUserStatus();
  const [openToast, setOpenToast] = useState(false);
  const [closeNotification, setCloseNotification] = useState(false);
  const currentCity = useSelector((state) => (state.cities.city));
  //const [disclaimer, setDisclaimer] = useState(false);
  const [valminM, setValminM] = useState(0);
  const [valminC, setValminC] = useState(0);
  useEffect(() => {
    if(configs[3]){
      setValminM(configs[3].valuemin_medellin);
      setValminC(configs[3].valuemin_cali);
    }
  }, [configs])
  const formatterDolar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0
  });
  const toastClassnames = classnames('menu-box menu-bottom', {
    'menu-box-active': openToast
  });
  const notificationClassnames = classnames('notification-large notification-has-icon notification-red', {
    'hide-notification': closeNotification
  });
  const handleCloseNotification = (event) => {
    event.preventDefault();
    setCloseNotification(!closeNotification);
  };
  const handleConfirmOrder = () => {
    const nextRoute = isUserLogged ? '/detalle_entrega' : '/ingreso';
    if (isUserLogged) {
      dispatch(incrementOrderStep());
    }
    history.push(nextRoute);
  };

  const [observations_client, setObservations_client] = useState('');
  const handleObservationsClient = (event) => {
    setObservations_client(event.target.value);
  };

  const handleOpenToast = () => {
    if(checkShoppingCart()){
      return false;
    }
    if(order.length !== 0){
      setOpenToast(true)
    }
  }
  const checkShoppingCart = () => {
    return subtotalValue === 0 || subtotalValue < 50000
  }

  const gramTotal = () => {
    const gramTotal= gramCount === 0 ? 0 : Math.ceil(gramCount/configs[0].gr_per_bag);
    return gramTotal
  }

  const ItemTotal = () => {
    const ItemTotal= itemCount === 0 ? 0 : Math.ceil(itemCount/configs[0].product_per_bag);
    return ItemTotal
  }

  const TotalBags = () => {
    handleBagsCount(ItemTotal() + gramTotal(), configs[0].bag_price);
    return ItemTotal() + gramTotal()
  }

  /*useEffect(() => {
    order.forEach((item) => {
      if ((item.unit_measurement_full !== 'unidad' && item.main_category_id === '0004-FRUTAS Y VERDURAS' && item.unityType === 0)
      || (item.unit_measurement_full !== 'unidad' && (item.sub_category_id === 'POLLO' || item.sub_category_id === 'PESCADOS Y MARISCOS') && item.unityType === 0))  {
        setDisclaimer(true);
      }
    });
  }, [order])*/

  return (
    <>
      <ToolbarMobile isBack={true} showCart={true} />
      <div className="page-content h-fixed">
        <div className="page-login page-login-full bottom-20">
          <h2 className="regular top-0 bottom-0 font-30">Mi canasta de compra</h2>
          <p className="font-20 top-10 bottom-20">{order.length} productos añadidos</p>
          {order.map((item, index) => (
            <ShoppingCartMobileItem key={`shopping-cart-item-${index}`} index={index} item={item}/>
          ))}
          {/* {disclaimer &&

            <div className="row top-10 color-green justify-content-center center font-12">
              <span>* Su valor sera calculado al momento de alistar su pedido ya que cada unidad puede tener diferente peso, o si lo prefiere lo puede pedir por la opcion de peso en gramos.</span>
            </div>
          } */}
          {
            currentCity === 'Medellin'?
            subtotalValue < valminM &&
            <div className="notification-large notification-has-icon notification-yellow">
              <div className="notification-icon btn btn-lin">
                <i className="fa fa-exclamation-triangle notification-icon" />
              </div>
              <span>El valor del pedido debe ser minimo de <strong>{formatterDolar.format(valminM)}</strong></span>
            </div>
            :
            subtotalValue < valminC &&
            <div className="notification-large notification-has-icon notification-yellow">
              <div className="notification-icon btn btn-lin">
                <i className="fa fa-exclamation-triangle notification-icon" />
              </div>
              <span>El valor del pedido debe ser minimo de <strong>{formatterDolar.format(valminC)}</strong></span>
            </div>
          }
          <div className="row top-20 bottom-20">
            <div className="col-6">
              <p className="bottom-10 font-14 color-gray">Subtotal</p>
              <p className="color-gray bottom-0">Total Valor por bolsas: </p>
              <p className="bottom-10 font-14 color-gray">Domicilio mínimo desde</p>
            </div>
            <div className="col-6">
              <p className="bottom-10 font-14 color-gray right-text">{formatCurrency(subtotalValue)}</p>
              <p className="bottom-10 font-14 color-gray right-text">{formatCurrency(TotalBags()*configs[0].bag_price) } ({TotalBags()} Bolsas)</p>
              <p className="bottom-10 font-14 color-gray right-text">{formatCurrency(deliveryCost)}</p>
            </div>
          </div>
          <div className="row top-20 bottom-20">
            <div className="col-6">
              <p className="bottom-10 font-17 color-green font-weight-bold">Total aproximado</p>
            </div>
            <div className="col-6">
              <p className="bottom-10 font-17 color-green right-text font-weight-bold">{formatCurrency(totalValue)}</p>
            </div>
          </div>
          {
            currentCity === "Medellin"?
              <button data-menu="menu-cart" className={`button bottom-15 button-full button-rounded shadow-small ${order.length !== 0 || subtotalValue >= valminM ? 'button-green' : 'button-dark2'} `} onClick={handleOpenToast}>Confirmar</button>
            :
              <button data-menu="menu-cart" className={`button bottom-15 button-full button-rounded shadow-small ${order.length !== 0 || subtotalValue >= valminC ? 'button-green' : 'button-dark2'} `} onClick={handleOpenToast}>Confirmar</button>
          }
          
        </div>
        {/** TODO: should add an overlay to avoid changes in shopping cart */}
        <div id="menu-cart" className={toastClassnames}>
          <div className="content bottom-0">
            <div className="row top-20 bottom-20 contactForm">
              <textarea onChange={handleObservationsClient} value={observations_client} className="textarea" name="" id="" placeholder="¿No está tu producto?, dinos que necesitas" rows="4"></textarea>
            </div>
            {/* <div className="row top-20 bottom-20">
              <div className="col-6">
                <p className="bottom-10 font-14 color-gray">Subtotal</p>
                <p className="bottom-10 font-14 color-gray">Domicilio mínimo desde</p>
              </div>
              <div className="col-6">
                <p className="bottom-10 font-14 color-gray right-text">{formatCurrency(subtotalValue)}</p>
                <p className="bottom-10 font-14 color-gray right-text">{formatCurrency(deliveryCost)}</p>
              </div>
            </div>
            <div className="row top-20 bottom-20">
              <div className="col-6">
                <p className="bottom-10 font-17 color-green font-weight-bold">Total aproximado</p>
              </div>
              <div className="col-6">
                <p className="bottom-10 font-17 color-green right-text font-weight-bold">{formatCurrency(totalValue)}</p>
              </div>
            </div> */}
            <div className={notificationClassnames}>
              <div className="notification-icon btn btn-lin">
                <i className="fa fa-exclamation-triangle notification-icon" />
              </div>
              <p>Al finalizar el pedido serás contactado por un representante para confirmar tu pedido e informarte el precio final.</p>
              <button onClick={handleCloseNotification} className="close-notification"><i className="fa fa-times" /></button>
            </div>
            <div className="menu-cart-total">
              <button onClick={handleConfirmOrder} className="button bottom-15 button-full button-rounded shadow-small button-green">Finalizar pedido</button>
              <button onClick={() => history.push('/')} className="button bottom-15 forgot button-center bottom-20 button-full center-text button-blue">Seguir comprando</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShoppingCartMobile;
