const routes = {
  home: {
    path: "/",
    title: "Inicio",
    requireAuth: false,
  },
  login: {
    path: "/ingreso",
    title: "Ingreso",
    requireAuth: false,
  },
  register: {
    path: "/registro",
    title: "Registro",
    requireAuth: false,
  },
  reset_password: {
    path: "/recuperar_contrasena",
    title: "Recuperar Contraseña",
    requireAuth: false,
  },
  account_created: {
    path: "/cuenta_creada",
    title: "Cuenta",
    requireAuth: false,
  },
  reset_password_success: {
    path: "/email_recuperar_enviado",
    title: "Recuperar contraseña",
    requireAuth: false,
  },
  menu: {
    path: "/menu",
    title: "Menu",
    requireAuth: false,
  },
  product_detail: {
    path: "/categoria/:categoryName/producto/:productId",
    title: "Detalle del producto",
    requireAuth: false,
  },
  addresses: {
    path: "/direcciones",
    title: "Direcciones",
    requireAuth: true,
  },
  addresses_action: {
    path: "/direcciones/:action/:index",
    title: "Direcciones",
    requireAuth: false,
  },
  search: {
    path: "/buscar/:name",
    title: "Buscar",
    requireAuth: false,
  },
  order_history: {
    path: "/historial_compras",
    title: "Historial de compras",
    requireAuth: true,
  },
  order_detail: {
    path: "/detalle_orden/:id",
    title: "Ver Orden",
    requireAuth: false,
  },
  shopping_cart: {
    path: "/carrito_compras",
    title: "Carrito de compras",
    requireAuth: false,
  },
  allies: {
    path: "/aliados/:categoryName",
    title: "Aliados",
    requireAuth: false,
  },
  category: {
    path: "/categoria/:categoryName",
    title: "Ver Categoría",
    requireAuth: false,
  },
  category_with_subcategory: {
    path: "/categoria/:categoryName/:subcategoryName",
    title: "Ver Subcategoría",
    requireAuth: false,
  },
  delivery_detail: {
    path: "/detalle_entrega",
    title: "Detalle de entrega",
    requireAuth: false,
  },
  order: {
    path: "/orden",
    title: "Orden",
    requireAuth: false,
  },
  profile: {
    path: "/perfil",
    title: "Mi Perfil",
    requireAuth: true,
  },
  favorites: {
    path: "/favoritos",
    title: "Favoritos",
    requireAuth: true,
  },
  tracing: {
    path: "/seguimiento",
    title: "Seguimiento",
    requireAuth: false,
  },
  terms: {
    path: "/terminos",
    title: "Terminos y condiciones",
    requireAuth: false,
  },
  subcategory: {
    path: "/subcategoria/:categoryName/:subcategoryName",
    title: "Subcategoría",
    requireAuth: false,
  },
  response: {
    path: "/response/",
    title: "Respouesta",
    requireAuth: false,
  },
  about_us: {
    path: "/quienes-somos",
    title: "Quienes somos",
    requireAuth: false,
  },
  questions: {
    path: "/preguntas_frecuentes",
    title: "Preguntas Frecuentes",
    requireAuth: false,
  },
  NotFound: {
    title: "Pagina no encontrada",
    requireAuth: false,
  },
};

export { routes };
