import React from 'react';
import { isBrowser } from 'react-device-detect';
import '../../styles/mobile/framework-store.css';
import TrackingOrderDesktop from "./TrackingOrderDesktop";
import TrackingOrderMobile from "./TrackingOrderMobile";

const TrackingOrder = () => {
	const TrackingOrderLayout = isBrowser ? TrackingOrderDesktop : TrackingOrderMobile;

	return (<TrackingOrderLayout />);
};

export default TrackingOrder;
