import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { deleteAddressRequest } from "store/domains/user/userActions";
import ToolbarMobile from "components/layout/components/mobile/ToolbarMobile";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, DialogContentText } from '@material-ui/core';

const AddressesMobile = ({ addresses }) => {

  const dispatch = useDispatch();

  const [dialogState, setDialogState] = useState({open: false, address: '', index: null});

  const handleDelete = (itemIndex) => {
    dispatch(
      deleteAddressRequest(
        addresses.filter((i, index) => index !== itemIndex)
      )
    );
  };

  const handleDialogClose = () => {
    setDialogState({open: false, address: '', index: null});
  };

  return (
    <>
      <ToolbarMobile isBack={true} showCart={false} />
      <div className="page-content h-fixed">
        <div className="page-login page-login-full bottom-20">
          <header className="page-header">
            <h2>Mis direcciones</h2>
          </header>
          <NavLink to="/direcciones/nueva/0" className="button bottom-15 top-20 button-full button-rounded shadow-small button-green">Añadir nueva dirección</NavLink>
          <div className="column">
            {addresses.map(({ alias, value }, index) => (
              <section key={index} className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-10">
                      <p className="bottom-0 font-15"><b>{alias}</b></p>
                      <p className="color-default bottom-10">{value}</p>
                    </div>
                  </div>
                </div>
                <div className="row row-reverse bottom-0">
                  <div className="col-1 left-20 right">
                    <p onClick={() => {setDialogState({open: true, address: alias, index: index})}} className="color-red bottom-0 ">Eliminar</p>
                  </div>
                  <div className="col-1 ">
                    <NavLink to={`/direcciones/cambiar/${index}`}>
                      <p className="color-green bottom-0">
                        Cambiar
                      </p>
                    </NavLink>
                  </div>
                </div>
              </section>
            ))}
            {addresses.length === 0 &&
              <div className='alert alert-info'>
                No hemos encontrado direcciones para mostrar.
              </div>
            }
          </div>
        </div>
      </div>
      <Dialog
        open={dialogState.open}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Eliminar dirección <b>'{dialogState.address}'</b></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Desea eliminar la dirección <b>{dialogState.address}</b>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="inherit">
            Cancelar
          </Button>
          <Button onClick={() => {handleDialogClose(); handleDelete(dialogState.index)}} color="secondary">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>

    </>
  );
};

AddressesMobile.propTypes = {
  addresses: PropTypes.arrayOf(PropTypes.shape({
    alias: PropTypes.string,
    value: PropTypes.string
  }))
};

AddressesMobile.defaultProps = {
  addresses: []
};

export default AddressesMobile;
