import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import ToolbarMobile from "components/layout/components/mobile/ToolbarMobile";
import { addNewAddressRequest, modifyAddressRequest } from "store/domains/user/userActions";
import { addressesSelector } from "selectors";
import { cities } from "utils/const";

const AddressFormMobile = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { action, index } = useParams();
  const addresses = useSelector(addressesSelector);
  const [currentAddress, setCurrentAddress] = useState({
    value: '',
    alias: '',
    neighborhood: '',
    zone: 'Norte',
    city: 'Cali'
  });

  const [validation, setValidation] = useState(false)

  const handleSubmit = e => {
    e.preventDefault();
    if (validation) {
      if (action === 'cambiar') {
        addresses[index] = currentAddress;
        dispatch(
          modifyAddressRequest(addresses)
        );
      } else {
        const addressStored = addresses === undefined ? [] : addresses;
        dispatch(
          addNewAddressRequest([
            ...addressStored,
            {
              value: currentAddress.value,
              alias: currentAddress.alias,
              neighborhood: currentAddress.neighborhood,
              zone: currentAddress.zone,
              city: currentAddress.city
            }
          ])
        );
      }
      history.goBack();
    }
  };

  useEffect(() => {
    if (action === "cambiar") {
      setCurrentAddress(addresses[index]);
    }
  }, [action, addresses, index]);

  // Current Address validations
  useEffect(() => {
    if (currentAddress.alias !== '' && currentAddress.neighborhood !== '' && currentAddress.value !== '' & currentAddress.zone !== '') {
      setValidation(true);
    } else {
      setValidation(false);
    }
  }, [currentAddress])

  return (
    <div>
      <ToolbarMobile isBack={true} showCart={false} />
      <div className="page-content h-fixed">
        <div className="page-login page-login-full bottom-20">
          <header className="page-header">
            <h2>
              {action === "cambiar" ? "Cambiar dirección" : "Nueva dirección"}
            </h2>
          </header>
          <div className="column">
            <form onSubmit={handleSubmit}>
              <div className="page-login-field top-15">
                <input
                  autoFocus={action === "nueva"}
                  type="text"
                  placeholder="Nombre personalizado"
                  className="form-control"
                  onChange={event =>
                    setCurrentAddress({
                      ...currentAddress,
                      alias: event.target.value
                    })
                  }
                  value={currentAddress.alias}
                  required
                />
              </div>
              <div className="page-login-field">
                <input
                  type="text"
                  placeholder="Dirección"
                  className="form-control"
                  onChange={event =>
                    setCurrentAddress({
                      ...currentAddress,
                      value: event.target.value
                    })
                  }
                  value={currentAddress.value}
                  required
                />
              </div>
              <div className="page-login-field">
                <input
                  type="text"
                  placeholder="Barrio"
                  className="form-control"
                  onChange={event =>
                    setCurrentAddress({
                      ...currentAddress,
                      neighborhood: event.target.value
                    })
                  }
                  value={currentAddress.neighborhood}
                  required
                />
              </div>
              <div className="page-login-field">
                <select className="form-control" defaultValue='Cali' required value={currentAddress.city} 
                onChange={event =>
                  setCurrentAddress({
                    ...currentAddress,
                    city: event.target.value
                  })
                }>
                  {
                    cities.map((city) => <option value={city.name}>{city.name}</option>)
                  }
                </select>
              </div>              
              <div className="page-login-field">
                <select className="form-control" required value={currentAddress.zone} onChange={event =>
                  setCurrentAddress({
                    ...currentAddress,
                    zone: event.target.value
                  })
                }>
                  <option value="Norte">Zona Norte</option>
                  <option value="Sur">Zona Sur</option>
                  <option value="Oriente">Zona Oriente</option>
                  <option value="Oeste">Zona Oeste</option>
                </select>
              </div>
              <input
                type="submit"
                className={`button ${validation ? 'button-green' : 'button-gray'} button-full button-rounded button-sm font-15 top-30`}
                value="Guardar Cambios"
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

AddressFormMobile.propTypes = {
  addresses: PropTypes.arrayOf(
    PropTypes.shape({
      alias: PropTypes.string,
      value: PropTypes.string
    })
  )
};

AddressFormMobile.defaultProps = {
  addresses: []
};

export default AddressFormMobile;
