import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isBrowser } from "react-device-detect";
import ProductDetailDesktop from "./ProductDetailDesktop";
import ProductDetailMobile from "./ProductDetailMobile";
import { loadDetailedProductRequest, loadDetailedProductSuccessed } from "store/domains/products/productsActions";
import { detailedProductSelector } from 'selectors';
import { detailedProduct2Selector } from 'selectors';
import { Loading } from 'components/common/Loading';

const ProductDetail = () => {
  const { categoryName, productId } = useParams();
  const product = useSelector(detailedProductSelector);
  const dispatch = useDispatch();
  const ProductDetailLayout = isBrowser ? ProductDetailDesktop : ProductDetailMobile;
  const products = useSelector(detailedProduct2Selector);
  const productOffer = product.listOffer.find(product => product.id_item === productId);
  const temporalProduct = productOffer? productOffer : product.listPreview[categoryName].find(product => product.id_item === productId);

  const finalProduct = temporalProduct ? temporalProduct : products.filter(product => product.id_item === productId)[0];

  useEffect(() => {
    let pFind = product.listPreview;
    
    if (pFind) {
      pFind = finalProduct;
    }

    if (pFind) {
      dispatch(loadDetailedProductSuccessed());
    } else{
      dispatch(loadDetailedProductRequest(categoryName, productId));
    } 
  }, [dispatch, categoryName, productId, products, finalProduct, product.listPreview])


  return (product.ui.isLoading ? 
  <Loading/> 
  : 
  <ProductDetailLayout product={finalProduct} />);
};

export default ProductDetail;
