import React from "react";
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ItemMobile } from 'components/item';
import ToolbarMobile from "components/layout/components/mobile/ToolbarMobile";
import InputSearchMobile from 'components/inputSearchMobile/InputSearchMobile';
import { NavLink } from 'react-router-dom';

import bucanero_banner from "images/bucanero_banner.jpg";
import bavaria_banner from "images/bavaria_banner.jpg";
import nestle_banner from "images/nestle_banner.png";
import dona_lupe_banner from "images/banner-dona-lupe.png";
import brinsa_banner from 'images/brinsa-banner.png';
import huevos_oro_banner from 'images/huevos_oro_banner.png';
import lativital_banner from 'images/lativital_banner.png';
import pg_banner from 'images/pg_banner.png';


const AlliesGridMobile = ({ category, products }) => {
  return (
    <>
      <ToolbarMobile isBack={true} showCart={true} />
      <div className="page-content h-fixed">
        <div className="page-login page-login-full bottom-20">
          <h2 className="regular top-0 bottom-0 font-30">{name(category)}</h2>
          <form>
            <div className="page-login-field top-15">
              {/* <i className="fa fa-search color-gray" />
              <input type="text" placeholder="Busca lo que más te guste" className="pl-30" /> */}
              <InputSearchMobile />
            </div>
          </form>
          <img src={images(category)} alt="banner" class="image-banner-mobile" />
          <div className="slider-margins bottom-20 top-30">
           
            {products.map((product, index) => {
              const itemClassNames = classnames('one-half', {
                'last-column': (index + 1) % 2 === 0
              });

              return (
                <NavLink key={`item-${index}`} to={`/categoria/${product.main_category_id}/producto/${product.id_item}`} className={itemClassNames}>
                  <ItemMobile product={product} />
                </NavLink>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

AlliesGridMobile.propTypes = {
  category: PropTypes.string,
  products: PropTypes.arrayOf(PropTypes.object)
};

AlliesGridMobile.defaultProps = {
  category: '',
  products: []
};

function images(category) {

  if (category === "KOPPS COMMERCIAL S A S")
    return bavaria_banner
  if (category === "POLLOS EL BUCANERO S.A.")
    return bucanero_banner
  if (category === "NESTLE DE COLOMBIA S.A.")
    return nestle_banner
  if (category === "DONA LUPE")
    return dona_lupe_banner
  if (category === "BRINSA S.A.")
    return brinsa_banner
  if (category === "NUTRIENTES AVICOLAS S.A.")
    return huevos_oro_banner
  if (category === "LA TINAJA PRODUCTOS ALIMENTICIOS SAS")
    return lativital_banner
  if (category === "PROCTER & GAMBLE COLOMBIA LTDA")
    return pg_banner  
}


function name(category) {

  if (category === "KOPPS COMMERCIAL S A S")
    return "Bavaria"
  if (category === "POLLOS EL BUCANERO S.A.")
    return "Pollos Bucanero"
  if (category === "DONA LUPE")
    return "Doña Lupe"
  if (category === "NESTLE DE COLOMBIA S.A.")
    return "NESTLE"
  if (category === "BRINSA S.A.")
    return "Brinsa"
  if (category === "NUTRIENTES AVICOLAS S.A.")
    return "Huevos Oro"
  if (category === "LA TINAJA PRODUCTOS ALIMENTICIOS SAS")
    return "Lativital"  
  if (category === "PROCTER & GAMBLE COLOMBIA LTDA")
    return "P&G"
}

export default AlliesGridMobile;
