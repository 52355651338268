import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const useExistsProducts = () => {
  const [exists, setExists] = useState([]);
  const currentCity = useSelector((state) => state.cities.city);
  let list = [];
  const existsProducts = (existsTemp, category) => {
    if (!existsTemp) {
      /* const list = [...exists, category]; */
      list = exists;
      list.push(category)
      setExists([...new Set(list)]);
       console.log(exists)
    } /* else{
      console.log("entra")
      setExists((item) =>
      item.filter((cat) => cat !== category)
      );
      console.log(exists)
    } */
  };
  useEffect(() => {
    setExists([]);
  }, [currentCity]);

  return {
    setExists,
    exists,
    existsProducts,
  };
};

export default useExistsProducts;
