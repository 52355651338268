import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, useHistory } from 'react-router-dom';
import Image from 'components/image/Image';
import LazyLoad from 'react-lazyload';
import moment from 'moment';
import 'moment/locale/es';
import { formatCurrency, getMaturity, parseFloatWithComma, capitalizeString } from 'utils/format';
import {useDispatch} from 'react-redux';
import { addItemToOrder } from 'store/domains/shoppingCart/shoppingCartActions';

const OrderDetailDesktop = ({ order }) => {

    const dispatch = useDispatch();
    const history = useHistory();
    
    const { id_order, status, products, address, domiciliary, createdAt, paymentMethod } = order;
    const orderPrice = products.reduce((acm, item) => (acm + (item.unityType === 0 ? item.price * item.count : parseFloatWithComma(item.pum) * item.count)), 0);
    
    const handleToOrder = () => {
        products.map((product, index) => product.title !== 'OTROS PRODUCTOS' && dispatch(addItemToOrder(product)));
        history.goBack();
    }
    
    return (
        <React.Fragment>
            <header className="page-header  page-title">
                <h2><NavLink to='/historial_compras'><b>◄</b></NavLink> Orden <b>#{id_order}</b> - Estado: {status}</h2>                
            </header>    
            <div className="row top-10">
                <div className="col-lg-12">                
                    <section className="content-info font-15">
                        <div className="content-details">
                            <span><b>Cantidad de productos:</b></span>
                            <span><b>Dirección:</b></span>  
                        </div>
                        <div className="content-details">                            
                            <span>{products.length}</span>
                            <span>{address}</span>
                        </div>
                        <div className="content-details">                            
                            <span><b>Fecha:</b></span>
                            <span><b>Domiciliario:</b></span> 
                        </div>
                        <div className="content-values">                            
                            <span>{capitalizeString(moment(createdAt).locale('es').format('MMMM D, YYYY'))}</span>
                            <span>{domiciliary}</span>                            
                        </div>
                    </section>
                </div>                
            </div>        
            <div>
                <div className="col-lg-12 content-padding">
                    <section className="content-tables">
                        <table className="table table-responsive-md table-hover mb-0">
                            <thead>
                            <tr>
                                <th>Producto</th>
                                <th>Comentarios</th>
                                <th>Madurez</th>
                                <th>Tipo de unidad</th>
                                <th>Cantidad</th>
                                <th>Total</th>
                            </tr>
                            </thead>
                            <tbody>
                            {products.map((product, index) => {
                                const {
                                    count,
                                    id_item,
                                    comments,
                                    main_category_id,
                                    maturity,
                                    price,
                                    pum,
                                    title,
                                    unityType,
                                    unit_measurement_full
                                } = product;

                                return (
                                <tr key={`product-${index}`}>
                                    <td>
                                        <div className="img-list-container">
                                            <LazyLoad once>
                                                <Image id={id_item} isOthers={title === 'OTROS PRODUCTOS'} className="img-list"/>
                                            </LazyLoad>
                                            <b>{title}</b> 
                                        </div>                                
                                    </td>
                                    <td>{comments}</td>
                                    <td>{main_category_id === '0004-FRUTAS Y VERDURAS' ? getMaturity(maturity) : 'No aplica'}</td>
                                    <td>{product.title === 'OTROS PRODUCTOS' ? 'No aplica' : (unityType === 0 ? `Unidades (${unit_measurement_full})` : 'Gramos')}</td>
                                    <td>{count}</td>
                                    <td><b className="color-green">{formatCurrency(unityType === 0 ? price * count : parseFloatWithComma(pum) * count)}</b></td>
                                </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    </section>
                </div>
            </div>
            <div className="decoration top-20" />            
            <div className="row top-10">
                <div className="col-lg-12">                
                    <section className="content-info font-15">
                        <div>
                        { (status === 'FINALIZADO' || status === 'CANCELADO') &&
                            <button onClick={handleToOrder} className="btn btn-success">Volver a pedir</button>                               
                        }                       
                        </div>
                        <div>                        
                        </div>
                        <div className="content-details"> 
                            <span>Forma de pago: </span>                          
                            <span className="top-10">Subtotal: </span>
                            <span className="top-10">Domicilio: </span>
                            <span className="top-10 font-16 color-green">Total: </span>
                        </div>
                        <div className="content-values">
                            <span>{capitalizeString(paymentMethod)}</span>                              
                            <span className="top-10">{formatCurrency(orderPrice)}</span>
                            <span className="top-10">{formatCurrency(order.price_domicile)}</span>
                            <span className="top-10 font-16 color-green"><b>{formatCurrency(orderPrice + order.price_domicile)}</b></span>
                        </div>
                    </section>
                </div>                
            </div>
        </React.Fragment>
    )
}

OrderDetailDesktop.propTypes = {
    order: PropTypes.object
};

OrderDetailDesktop.defaultProps = {
    order: []
};

export default OrderDetailDesktop;
