import React from 'react';
import '../../styles/mobile/framework-store.css';

class AccountCreatedDesktop extends React.Component {
    render() {
        return <h1>Esto es la web</h1>;
    }
}

export default AccountCreatedDesktop;
