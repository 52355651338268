import React, { useState } from "react";
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ItemDesktop } from 'components/item';
import { splitCategoria } from 'utils/format';
import { isBrowser, isMobile, isTablet } from "react-device-detect";


const SubCategoryDesktop = ({ products, category, subcategory, categories }) => {
    const [displayDetail, setDisplayDetail] = useState(false);

    const toggleDetail = () => {
        setDisplayDetail(!displayDetail);
    };
    
/* 
  const shouldFilterProductsPerCategory = category && category.length > 0;
  const categoryToShow = shouldFilterProductsPerCategory ? categories.filter((categorie) => (categorie.id === category)) : "";
  
  let nameCategory = categoryToShow[0].name !== category ? categoryToShow[0].name : category; */
    const productsToShow =  products.filter((product) => product.sub_category_id === subcategory)
    return (
        <>
            <div className="container">
                <h2>{splitCategoria(subcategory)}</h2>
                <div className="row mx-auto">
                    {productsToShow.length===0 &&
                        <div className="col-12">
                        <div className="alert alert-info">
                            No se han encontrado productos para mostrar.
                        </div>
                        </div>
                    } 
                    {productsToShow.map((product, index) => {
                    const itemClassNames = classnames('one-half', {
                        'col-md-3': isBrowser || isTablet,
                        'one-half': isMobile,
                        'last-column': isMobile && (index + 1) % 2 === 0
                    });

                    return (
                        <div key={`item-${index}`} className={itemClassNames}>
                            <ItemDesktop product={product} handleClose={toggleDetail}/>
                        </div>
                    );
                    })}
                </div>
            </div>
        </>
    );
};

SubCategoryDesktop.propTypes = {
    category: PropTypes.string,
    products: PropTypes.arrayOf(PropTypes.object),
    categories: PropTypes.arrayOf(PropTypes.object)
};

SubCategoryDesktop.defaultProps = {
    category: '',
    products: [],
    categories: []
};

export default SubCategoryDesktop;