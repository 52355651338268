import React from 'react';
import {isMobile} from 'react-device-detect';
import avatar from '../../images/public_avatar.png'
import { Link } from "react-router-dom";
import { routes } from "../../router/router";
import { useDispatch, useSelector } from 'react-redux';
import { signOut } from "pages/auth/store/actions/authActions";
import { NavLink } from 'react-router-dom';
import { useHistory } from "react-router-dom";


const Menu = () => {

  const dispatch = useDispatch();
  const history = useHistory();

  const {
    auth,
    profile,
  } = useSelector((state) => ({
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  }));

  const handleSignOut = () => {
    dispatch(signOut());
    history.push("/");
  }

  if (auth.uid) {
    if (isMobile) {
      return (
        <div id="sidebar-left-full" data-load="" className="menu-box menu-load menu-sidebar-left-full">
          <div className="menu-title">
            <span className="color-highlight" />
            <Link to={`/`} href="/" className="menu-hide"><i className="fa fa-times" /></Link>
          </div>
          <div className="menu-account top-30">
            <div className="avatar-menu"><img src={profile.photoURL || avatar} alt="avatar" /></div>
            <p className="font-24 left-90">{profile.name}<br />
            <NavLink  className="font-14" to="/perfil">
              <span>Editar cuenta</span>
              </NavLink>
            </p>
          </div>
          <div className="menu-page top-30">
            <ul className="menu-list bottom-20">
              <li id="menu-components">
                  <NavLink to="/perfil">
                    <i className='fa fa-user' />
                    <span>Mi Perfil</span>
                  </NavLink>
              </li>
              <li id="menu-components">
              <NavLink to="/historial_compras">
                  <i className='fa fa-file-invoice-dollar' />
                  <span>Historial de pedidos</span>
                </NavLink>
              </li>
              <li id="menu-components">
              <NavLink to="/direcciones">
                  <i className='fa fa-map-marker' />
                  <span>Mis direcciones</span>
                </NavLink>
              </li>
               {/* <li id="menu-components">
                                <a href>
                                    <i className='fa fa-heart' />
                                    <span>Mis favoritos</span>
                                </a>
                            </li>
                            <li id="menu-components">
                                <a href>
                                    <i className='fa fa-share-alt' />
                                    <span>Comparte con un amigo</span>
                                </a>
                            </li>  */}

            </ul>

            <div className="decoration top-20 bottom-20"></div>

          </div>

          <div className="menu-boton-cuenta">

            <div className="page-login page-login-full bottom-20">
              <button href="" className="button bottom-0 button-full button-rounded shadow-small button-green"
                onClick={handleSignOut} >Cerrar sesión</button>
            </div>

          </div>

          <div className="menu-footer">
            <div className="menu-footer-shadow"></div>

            <Link to="/terminos">Términos y condiciones</Link>
            <span className="left-10 right-10">|</span>
            <a href="https://wa.me/573107191675" rel="noopener noreferrer" target="_blank">Soporte</a>

          </div>
        </div>
      );
    }

    return <div>Esto es WEB</div>;
  }

  return (
    <div id="sidebar-left-full" data-load="" class="menu-box menu-load menu-sidebar-left-full">
      <div className="menu-title">
        <span className="color-highlight" />
        <Link to={`/`} href="/" className="menu-hide"><i className="fa fa-times" /></Link>
      </div>
      <div class="menu-page top-30">
        <ul class="menu-list bottom-20">
          <li id="menu-index">
            <Link to={`/`} href="/" >
              <i class='fa fa-home'></i>
              <span>Inicio</span>
            </Link>
          </li>
          <li id="menu-components">
          </li>

        </ul>

        <div class="decoration top-20 bottom-20"></div>


      </div>

      <div class="menu-boton-cuenta">

        <div class="page-login page-login-full bottom-20">
          <Link to={routes.login.path} class="button bottom-0 button-full button-rounded shadow-small button-green" href="ingresar.html">Iniciar sesión</Link>
        </div>

        <Link to={routes.register.path} class="forgot button-center button-full center-text color-default" href="registro.html">Crea tu cuenta</Link>

      </div>

      <div className="menu-footer">
        <div className="menu-footer-shadow"></div>

        <Link to={routes.menu.path}>Términos y condiciones</Link>
        <span className="left-10 right-10">|</span>
        <a href="https://wa.me/573107191675" rel="noopener noreferrer" target="_blank">Soporte</a>

      </div>

    </div>
  );
}

export default Menu;