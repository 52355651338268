import React from "react";
import ReactDOM from "react-dom";
import { PersistGate } from "redux-persist/integration/react";
import { isBrowser } from "react-device-detect";
import store, { persistor } from "./store";
import { Provider } from "react-redux";
import App from "./App";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const container = document.getElementById("root");

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2a9137'
    },
    secondary: {
      main: '#ea4d0d'
    },
  },
});

store.firebaseAuthIsReady.then(() => {
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <link rel="stylesheet" type="text/css" href={isBrowser && 'https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css'} />
        <link rel="stylesheet" type="text/css" href={isBrowser ? '/styles/theme.css' : '/styles/style.css'} />
        <link rel="stylesheet" type="text/css" href={isBrowser ? '/styles/custom.css' : '/styles/framework.css'} />
        <link rel="stylesheet" type="text/css" href={isBrowser ? '/fonts_desktop/css/all.min.css' : '/fonts_mobile/css/fontawesome-all.min.css'} />
        <link rel="stylesheet" type="text/css" href='/styles/citiesModal.css' />
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </PersistGate>
    </Provider>,
    container
  );
});
