import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { routes } from "./router/router";
import Home from "./pages/home/Home";
import Login from "./pages/auth/mobile/LoginMobile";
import ResetPassword from "./pages/users/resetPassword/ResetPassword";
import AccountCreated from "./pages/users/accountCreated/AccountCreated";
import ResetPasswordSuccess from "./pages/users/resetPasswordSuccess/ResetPasswordSuccess";
import Register from "./pages/auth/mobile/RegisterMobile";
import NotFound from "./pages/utils/notFound/NotFound";
import ProductDetail from "./pages/products/productDetail/ProductDetail";
import Search from "./pages/products/search/Search";
import ShoppingCart from "./pages/shopping/shoppingCart/ShoppingCart";
import Category from "./pages/products/category/Category";
import SubCategory from "./pages/products/category/SubCategory";
import DeliveryDetails from "./pages/shopping/deliveryDetails/DeliveryDetails";
import TrackingOrder from "./pages/shopping/trackingOrder/TrackingOrder";
import Tracing from "./pages/shopping/tracing/Tracing";
import Menu from "./pages/home/Menu";
import Profile from "./pages/users/profile/Profile";
import Favorites from "./pages/products/favorites/Favorites";
import Layout from "./components/layout/Layout";
import Addresses from "./pages/users/addresses/Addresses";
import OrderHistory from "./pages/shopping/orderHistory/OrderHistory";
import withSplashScreen from "./components/withSplashScreen";
import Allies from "pages/products/allies/Allies";
import AddressFormMobile from "pages/users/addresses/AddressFormMobile";
import OrderDetail from "pages/shopping/orderDetail/OrderDetail";
import { Terms } from "pages/terms/Terms";
import Response from "pages/products/response/Response";
import { Aboutus } from "pages/aboutus/Aboutus";
import { QuestionsPage } from "pages/question/questionsPage";

import { RouteWithTitle } from "./utils/router";

function App() {
  return (
    <BrowserRouter>
      <Layout>
        <Switch>
          <RouteWithTitle
            route={routes.home}
            exact
            path={routes.home.path}
            component={Home}
          />
          <RouteWithTitle
            route={routes.login}
            exact
            path={routes.login.path}
            component={Login}
          />
          <RouteWithTitle
            route={routes.register}
            exact
            path={routes.register.path}
            component={Register}
          />
          <RouteWithTitle
            route={routes.reset_password}
            exact
            path={routes.reset_password.path}
            component={ResetPassword}
          />
          <RouteWithTitle
            route={routes.account_created}
            exact
            path={routes.account_created.path}
            component={AccountCreated}
          />
          <RouteWithTitle
            route={routes.reset_password_success}
            exact
            path={routes.reset_password_success.path}
            component={ResetPasswordSuccess}
          />
          <RouteWithTitle
            route={routes.menu}
            exact
            path={routes.menu.path}
            component={Menu}
          />
          <RouteWithTitle
            route={routes.product_detail}
            exact
            path={routes.product_detail.path}
            component={ProductDetail}
          />
          <RouteWithTitle
            route={routes.search}
            exact
            path={routes.search.path}
            component={Search}
          />
          <RouteWithTitle
            route={routes.addresses}
            exact
            path={routes.addresses.path}
            component={Addresses}
          />
          <RouteWithTitle
            route={routes.addresses_action}
            exact
            path={routes.addresses_action.path}
            component={AddressFormMobile}
          />
          <RouteWithTitle
            route={routes.shopping_cart}
            exact
            path={routes.shopping_cart.path}
            component={ShoppingCart}
          />
          <RouteWithTitle
            route={routes.order_history}
            exact
            path={routes.order_history.path}
            component={OrderHistory}
          />
          <RouteWithTitle
            route={routes.order_detail}
            exact
            path={routes.order_detail.path}
            component={OrderDetail}
          />
          <RouteWithTitle
            route={routes.allies}
            exact
            path={routes.allies.path}
            component={Allies}
          />
          <RouteWithTitle
            route={routes.category}
            exact
            path={routes.category.path}
            component={Category}
          />
          <RouteWithTitle
            route={routes.category_with_subcategory}
            exact
            path={routes.category_with_subcategory.path}
            component={Category}
          />
          <RouteWithTitle
            route={routes.delivery_detail}
            exact
            path={routes.delivery_detail.path}
            component={DeliveryDetails}
          />
          <RouteWithTitle
            route={routes.order}
            exact
            path={routes.order.path}
            component={TrackingOrder}
          />
          <RouteWithTitle
            route={routes.profile}
            exact
            path={routes.profile.path}
            component={Profile}
          />
          <RouteWithTitle
            route={routes.favorites}
            exact
            path={routes.favorites.path}
            component={Favorites}
          />
          <RouteWithTitle
            route={routes.tracing}
            exact
            path={routes.tracing.path}
            component={Tracing}
          />
          <RouteWithTitle
            route={routes.terms}
            exact
            path={routes.terms.path}
            component={Terms}
          />
          <RouteWithTitle
            route={routes.subcategory}
            exact
            path={routes.subcategory.path}
            component={SubCategory}
          />
          <RouteWithTitle
            route={routes.response}
            exact
            path={routes.response.path}
            component={Response}
          />
          <RouteWithTitle
            route={routes.about_us}
            exact
            path={routes.about_us.path}
            component={Aboutus}
          />
          <RouteWithTitle
            route={routes.questions}
            exact
            path={routes.questions.path}
            component={QuestionsPage}
          />
          <Route exact path="/home">
            <Redirect to="/" />
          </Route>
          <RouteWithTitle title={routes.NotFound.title} component={NotFound} />
        </Switch>
      </Layout>
    </BrowserRouter>
  );
}

export default withSplashScreen(App);
