import React from "react";
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ToolbarMobile from "components/layout/components/mobile/ToolbarMobile";
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { useProductGridsBehavior } from "hooks";
import CitiesModalMobile from '../modals/CitiesModalMobile';

// TODO: should move header to this file?
const LayoutMobile = ({ children }) => {
  const currentCity = useSelector((state) => (state.cities.city));
  const showModalCities = useSelector( (state) => (state.cities.openModal));
  const {addedProduct, noAddedProduct} = useProductGridsBehavior();

  const handleClose = (e) =>{
    noAddedProduct();
  }

  let currentState = useSelector((state) => (state.alertterms.showAlert));
  
  return(
  <div id="page-transitions" className={ `page-build light-skin highlight-blue ${ currentState!==false?'with-alert-terms':''}` }>
    <CitiesModalMobile currentCity={currentCity} showModal={showModalCities} />
    <ToolbarMobile isBack={false} showCart={true} />
    {children}
    <Snackbar open={addedProduct} autoHideDuration={6000} onClose={handleClose}>
        <div className="alert">
            <MuiAlert elevation={6} variant="filled" severity="success" onClose={handleClose}>
              Producto agregado
            </MuiAlert>
        </div>
    </Snackbar>
  </div>)
};

LayoutMobile.propTypes = {
  children: PropTypes.node
};

LayoutMobile.defaultProps = {
  children: null
};

export default LayoutMobile;
