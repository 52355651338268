import React from 'react';
import PropTypes from 'prop-types';

const Counter = ({ handleDecrement, handleIncrement, value }) => (
  <div className="input-group full-width justify-content-center">
    <div className="input-group-prepend" onClick={handleDecrement} data-testid="button-minus">
      <span className="button-minus">-</span>
    </div>
    <div className="quantity-field" data-testid="counter-value">
      <h5>{value}</h5>
    </div>
    <div className="input-group-append" onClick={handleIncrement} data-testid="button-plus">
      <span className="button-plus">+</span>
    </div>
  </div>
);

Counter.propTypes = {
  handleDecrement: PropTypes.func.isRequired,
  handleIncrement: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired
};

export default Counter;
