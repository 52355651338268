import React from "react";
import { useSelector } from 'react-redux';
//import LeftSideBarDesktop from "./components/desktop/LeftSideBarDesktop";
import ToolbarDesktop from "./components/desktop/ToolbarDesktop";
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { useProductGridsBehavior } from "hooks";
import CitiesModalDesktop from '../modals/CitiesModalDesktop';

const LayoutDesktop = ({ children }) => {
  const currentCity = useSelector((state) => (state.cities.city));
  const showModalCities = useSelector( (state) => (state.cities.openModal));
  const {addedProduct, noAddedProduct} = useProductGridsBehavior();

  const handleClose = (e) =>{
    noAddedProduct();
  }
  let currentState = useSelector((state) => (state.alertterms.showAlert));

  return (
    <section className={ `body ${currentState!==false?'with-alert-terms':''}`}>
      <CitiesModalDesktop currentCity={currentCity} showModal={showModalCities} />
      <div className="inner-wrapper">
        {/*<LeftSideBarDesktop categories={categories} />*/}
        <section role="main" className="content-body">
          <ToolbarDesktop />
          {children}
        </section>
      </div>
      <Snackbar open={addedProduct} autoHideDuration={6000} onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }} >
        <div className="alert">
            <MuiAlert elevation={6} variant="filled" severity="success" onClose={handleClose}>
              Producto agregado
            </MuiAlert>
        </div>
      </Snackbar>
    </section>
  );
};

export default LayoutDesktop;
