import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import ToolbarMobile from "../../../components/layout/components/mobile/ToolbarMobile";
// new
import { useParams } from "react-router-dom";
import PropTypes from 'prop-types';
import algoliasearch from 'algoliasearch/lite';
import { detailedProductSelector } from 'selectors';
import {
    InstantSearch,
    Configure,
    connectSearchBox,
    connectHits
} from 'react-instantsearch-dom';
import { NavLink } from 'react-router-dom';
import ItemMobile from '../../../components/item/ItemMobile';
import InputSearchMobile from '../../../components/inputSearchMobile/InputSearchMobile';
import { loadProductPreview } from "store/domains/products/productsActions";

import SearchNotFound from '../../../components/search/SearchNotFound'; 

const VirtualSearchBox = connectSearchBox(() => null);
const searchClient = algoliasearch(
    // '3M0QM2451W',
    // '9df91abf9c6bf66e6027c2261d806829'
);

const SearchMobile = () => {
    const currentCity = useSelector((state) => (state.cities.city));
    const cityFilter = currentCity? currentCity.toLowerCase() : "medellin";

    const product = useSelector(detailedProductSelector);

    const { name } = useParams();

    return (
        <React.Fragment>

            <ToolbarMobile isBack={true} showCart={true} />

            <div className="page-content h-fixed">

                <div className="page-login page-login-full bottom-20">

                    <h2 className="regular top-0 bottom-0 font-30">¿Qué estás buscando?</h2>

                    <form>
                        <div className="page-login-field top-15">
                            <InputSearchMobile />
                        </div>
                    </form>

                    <p className="font-23 top-30 bottom-20">Resultados de la búsqueda</p>

                    {/* ALGOLIA */}
                    <InstantSearch searchClient={searchClient} indexName="products">
                        <Configure hitsPerPage={500} filters={`stock_${cityFilter}>0`} />
                        <VirtualSearchBox defaultRefinement={name} />
                        <Hit product={product}/>
                    </InstantSearch>

                </div>
            </div>
        </React.Fragment>
    );

}

const Hit = connectHits(({ hits, product }) => {

    const dispatch = useDispatch();

    const items = hits.map(hit => {
        const { id_item, main_category_id } = hit;

        if (main_category_id in product.listPreview) {
            let existentProduct = product.listPreview[main_category_id].find(item => item.id_item === id_item);
            if (!existentProduct) {                
                dispatch(loadProductPreview(main_category_id, hit));
            }
        } else {
            dispatch(loadProductPreview(main_category_id, hit));
        }

        return (
            <NavLink key={`item-${id_item}`} to={`/categoria/${main_category_id}/producto/${id_item}`}>
                <ItemMobile product={hit} />
            </NavLink>
        );           
    });

    return (hits.length>0?<div className="ais-Hits-list">{items}</div>:<SearchNotFound />);
});

Hit.propTypes = {
    hit: PropTypes.object.isRequired,
};

export default SearchMobile;