import React from 'react';

import "./Loader.css";

const Loader = ({title}) => {
    return (
        <div className="loader-wrapper">
            <div className="Loader-content">
                <span className="circle circle-1"></span>
                <span className="circle circle-2"></span>
                <span className="circle circle-3"></span>
                <span className="circle circle-4"></span>
                <span className="circle circle-5"></span>
                <span className="circle circle-6"></span>
                <span className="circle circle-7"></span>
                <span className="circle circle-8"></span>
            </div>
            <div className="Loader-title">
                { title || 'Cargando...' }
            </div>
        </div>
    );
}

export default Loader;