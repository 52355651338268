import React from "react";
import products from '../../../images/productos.jpg';

class FavoritesDesktop extends React.Component {
    render() {
        return (
            <React.Fragment>
                <header className="page-header">
                    <h2>Mis favoritos</h2>
                </header>
                <div className="row">
                    <div className="col-lg-12 mb-3 content-padding">

                        <section className="card">
                            <div className="card-body">

                                <div className="row">
                                    <div className="col-md-1">
                                        <img src={products} className="img-fluid" alt="img"/>
                                    </div>
                                    <div className="col-md-9">
                                        <h4 className="color-default bottom-0">
                                            <a href className="color-default">
                                                Leche Colanta deslac...
                                            </a>
                                        </h4>
                                        <p className="bottom-0">Maxilitro</p>
                                        <p className="color-default">
                                            <i className="fa fa-dollar-sign color-green icon-round-border"/>
                                            $2.5 por ml
                                        </p>
                                    </div>
                                    <div className="col-md-2 align-right top-15">
                                        <p className="color-green bottom-0 font-16">$2.500</p>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-1">
                                        <img src={products} className="img-fluid" alt="img"/>
                                    </div>
                                    <div className="col-md-9">
                                        <h4 className="color-default bottom-0">
                                            <a href className="color-default">
                                                Leche Colanta deslac...
                                            </a>
                                        </h4>
                                        <p className="bottom-0">Maxilitro</p>
                                        <p className="color-default">
                                            <i className="fa fa-dollar-sign color-green icon-round-border"/>
                                            $2.5 por ml
                                        </p>
                                    </div>
                                    <div className="col-md-2 align-right top-15">
                                        <p className="color-green bottom-0 font-16">$2.500</p>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-1">
                                        <img src={products} className="img-fluid" alt="img"/>
                                    </div>
                                    <div className="col-md-9">
                                        <h4 className="color-default bottom-0">
                                            <a href className="color-default">
                                                Leche Colanta deslac...
                                            </a>
                                        </h4>
                                        <p className="bottom-0">Maxilitro</p>
                                        <p className="color-default">
                                            <i className="fa fa-dollar-sign color-green icon-round-border"/>
                                            $2.5 por ml
                                        </p>
                                    </div>
                                    <div className="col-md-2 align-right top-15">
                                        <p className="color-green bottom-0 font-16">$2.500</p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default FavoritesDesktop;
