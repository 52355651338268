/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link,NavLink } from 'react-router-dom';
import { Dropdown } from "react-bootstrap";
import AuthModal from "pages/auth/desktop/AuthModal";
import avatar from 'images/public_avatar.png';
import {handleClose,handleShow,signOut} from "pages/auth/store/actions/authActions";
import { Loading } from "components/common/Loading";
import Drawer from '@material-ui/core/Drawer';
import RightSideBarDesktop from "./RightSideBarDesktop";
import iconShoppingCart from "../../../../images/cart.png";
import algoliasearch from 'algoliasearch/lite';
import {InstantSearch,Configure} from 'react-instantsearch-dom';
import Autocomplete from "components/autocomplete/Autocomplete";
import DetailProductDesktop from 'components/modals/DetailProductDesktop';
import { makeStyles } from '@material-ui/core/styles';
import { configDrawerSelector } from 'selectors';
import { updateStatusDrawer } from 'store/domains/config/configActions';


import logoApp from "images/logo-splash.png";
//import { routes } from "router/router";
import { splitCategoria } from "utils/format";
import AlertTerminos from 'components/alert/AlertTerminos';
import Icon from '@material-ui/core/Icon';
import { ChangeCitiesHome } from 'hooks';

const useStyles = makeStyles({
  paper: {
    overflowY: "unset"
  },
});

const ToolbarDesktop = () => {
  const dispatch = useDispatch();
  const config = useSelector(configDrawerSelector);
  const currentCity = useSelector((state) => (state.cities.city));
  const categories = useSelector((state) => (state.categories.list));
  const visibilityCities = useSelector((state) => (state.cities.visibility));
  const cityFilter = currentCity? currentCity.toLowerCase() : "Medellín";

  const {
    auth,
    countOrderItems,
    loadingLogin,
    profile,
    showModal
  } = useSelector((state) => ({
    auth: state.firebase.auth,
    countOrderItems: state.shoppingCart.order.length,
    loadingLogin: state.auth.loading,
    profile: state.firebase.profile,
    showModal: state.auth.showModal
  }));
  const [showLogin, setShowLogin] = useState(true);
  const handleCloseEvent = () => {
    dispatch(handleClose());
  };
  const handleChangeAlert = () => {
    setShowLogin(!showLogin);
  };
  const searchClient = algoliasearch(
    // '3M0QM2451W',
    // '9df91abf9c6bf66e6027c2261d806829'
  );
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
    query: '',
  });

  const [displayDetail, setDisplayDetail] = useState(false);
  const [productDetail, setProductDetail] = useState({});

  const toggleDetail = () => {
    setDisplayDetail(!displayDetail);
  };

  const toggleDrawer = (side, open) => () => {
    dispatch(updateStatusDrawer(open));
  };

  const onSuggestionSelected = (_, { suggestion }) => {
    toggleDetail();
    setProductDetail(suggestion);
    setState({
        query: suggestion.title,
      });
    };
  
  const onSuggestionCleared = () => {
    setState({
      query: '',
    });
  };

  
  const isLogged = () => {
    if (loadingLogin) {
      return <Loading />;
    };


    if (auth.uid) {
      // TODO: replace Link elements with onClick handlers and navigate programatically between pages
      return (
        <Dropdown>
          <Dropdown.Toggle as={CustomDropdown} profile={profile} />
          <Dropdown.Menu>
            <Dropdown.Item as="button">
              <NavLink to="/perfil">
                <i className='fa fa-user right-10' /><span>Mi perfil</span>
              </NavLink>
            </Dropdown.Item>
            <Dropdown.Item as="button">
              <NavLink to="/historial_compras">
                <i className='fa fa-file-invoice-dollar right-10' /><span>Historial de pedidos</span>
              </NavLink>
            </Dropdown.Item>
            <Dropdown.Item as="button">
              <NavLink to="/direcciones">
                <i className='fa fa-map-marker right-10' /><span>Mis direcciones</span>
              </NavLink>
            </Dropdown.Item>
            <Dropdown.Divider/>
            <Dropdown.Item as="button">
              <a href="#" onClick={(e) => {e.preventDefault(); dispatch(signOut())} }>
                <i className='fa fa-power-off right-10' />
                <span>Cerrar sesión</span>
              </a>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      );
    }

    return (
      <button className="btn btn-warning modal-sizes btn-block m-3" onClick={() => (dispatch(handleShow()))}>
        Iniciar sesión
      </button>
    );
  };

  
  const classes = useStyles();

  const {
    handleToggleModal
  } = ChangeCitiesHome();

  const handleShowModal = () => {
    handleToggleModal(true)
  }

  return (
    <header className="header">
      <AlertTerminos />
      <Link to="/home" className="logo">
        <img src={logoApp} alt="La Montaña Agromercados" />
      </Link>
      <AuthModal
        show={showModal}
        handleClose={handleCloseEvent}
        shouldDisplayLoginForm={showLogin}
        handleChangeAlert={handleChangeAlert}
      />
      <div className="content-center"  style={{display: 'inline-block', verticalAlign: 'middle', width: '40%'}}>
        <div className="search nav-form" style={{width: '100%'}}>
          <div className="input-group" >
            <InstantSearch indexName="products" searchClient={searchClient} >
              <Configure hitsPerPage={5} filters={`stock_${cityFilter}>0`} />
              <Autocomplete
                onSuggestionSelected={ onSuggestionSelected }
                onSuggestionCleared={ onSuggestionCleared }
              />

            </InstantSearch>
            
          </div>
        </div>
      </div>
      <div className="content-nav">
        <main>
          {
            categories.map((category, index) => (
              <div key={index}>
                <Link to={`/categoria/${category.id}`} className="logo">
                  { splitCategoria(category.name || category.id) }
                </Link>
              </div>
            ))
          }
        </main> 
      </div>
      <div className="content-city">
        {visibilityCities!=="hidden" && (
          <>
            <span>
              <Icon className="fas fa-map-marker-alt" />
            </span>
            <div className="hover" onClick={() => handleShowModal()}>
              <b className="d-block">{currentCity === 'Medellin' ? 'Medellín' : currentCity}</b>
              <span className="d-block" style={{ maxWidth: '115px',lineHeight:1 }}>¿Desde dónde te estás conectando?</span>
            </div>
          </>
        ) 
        }
      </div>
      <div className="container-shopping-button">
        <a onClick={toggleDrawer('right', true)} className="notification-icon">
          <img src={iconShoppingCart} alt="icon-shopping-cart"/>
          <span className="badge">{countOrderItems}</span>
        </a>
      </div>
      <div id="userbox" className="userbox">
        {isLogged()}
      </div>
      <Drawer anchor="right" open={config.drawerOpen} onClose={toggleDrawer('right', false)} classes={{paper: classes.paper}} className='side-bar-right'> 
        <RightSideBarDesktop isRightSidebarOpen={state.right} toggleRightSidebar={() => toggleDrawer('right', false)()} />
      </Drawer>
      <DetailProductDesktop show={displayDetail} handleClose={toggleDetail} product={productDetail} />
    </header>
  );
};

const CustomDropdown = forwardRef(({ onClick, profile }, ref) => {
  const {
    name,
    last_name,
  } = profile;

  return (
    <a
      href=""
      ref={ref}
      onClick={e => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <div>
        <figure className="profile-picture">
          <img src={profile.photoURL || avatar} alt="Profile pic" className="rounded-circle" data-lock-picture={profile.photoURL || avatar} />
        </figure>
        <div className="profile-info">
          <span className="name">{ name + last_name }</span>
        </div>
      </div>
    </a>
  );
});


CustomDropdown.propTypes = {
  onClick: PropTypes.func,
  profile: PropTypes.shape({
    name: PropTypes.string,
    last_name: PropTypes.string,
    photoUrl: PropTypes.string
  })
};

CustomDropdown.defaultProps = {
  onClick: () => { },
  profile: {
    name: '',
    last_name: '',
    photoUrl: undefined
  }
};

export default ToolbarDesktop;
