import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/es';
import { formatCurrency, capitalizeString, parseFloatWithComma } from 'utils/format';

const OrderHistoryDesktop = ({ orders }) => (
  <>
    <header className="page-header page-title">
      <h2>Historial de pedidos</h2>
    </header>
    <div className="row">
      <div className="col-lg-12 mb-3 content-padding">
        <section className="content-tables">
          {
            orders.length > 0 ?
             (
              <table className="table table-responsive-md table-hover mb-0">
                <thead>
                  <tr>
                    <th>Orden</th>
                    <th>Fecha</th>
                    <th>Estado</th>
                    <th>Total</th>
                    <th/>
                  </tr>
                </thead>
                <tbody>
                  {orders.map((order, index) => {
                    const {
                      id,
                      id_order,
                      createdAt,
                      status,
                      products,
                      price_domicile
                    } = order;
                    const statusColor = order.status === 'CANCELADO' ? 'color-red' : order.status === 'FINALIZADO' ? 'color-blue' : 'color-green';                
                    const orderPrice = products.reduce((acm, item) => (acm + (item.unityType === 0 ? item.price * item.count : parseFloatWithComma(item.pum) * item.count)), 0);               
                    return (
                      <tr key={`order-${index}`}>
                        <td>Orden <b>#{id_order}</b></td>
                        <td>{capitalizeString(moment(createdAt).locale('es').format('MMMM D, YYYY'))}</td>
                        <td><span className={statusColor}>{status}</span></td>
                        <td>{formatCurrency(orderPrice + price_domicile)}</td>
                        <td>
                          <div className="row">      
                            <NavLink to={`/detalle_orden/${id}`}> 
                              <p className="color-green text-link right-20">Ver detalle</p>
                            </NavLink>          
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
             )
             :
             (
               <div className='alert alert-info'>
                  No hemos encontrado historial de pedidos.
               </div>
             )
          }
        </section>
      </div>
    </div>
  </>
);

OrderHistoryDesktop.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.object)
};

OrderHistoryDesktop.defaultProps = {
  orders: []
};

export default OrderHistoryDesktop;
