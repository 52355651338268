import React from 'react';
import PropTypes from 'prop-types';
import Image from 'components/image/Image';
import { Counter } from 'components/counter';
import { formatCurrency, parseFloatWithComma, getMaturity, splitCategoria  } from 'utils/format';
import { useShoppingCartActions } from 'hooks';
import LazyLoad from 'react-lazyload';
import styles from './ShoppingCartItem.module.css';

const ShoppingCartItem = ({ index, item }) => {
  const {
    currentCount,
    handleDecrement,
    handleIncrement,
    handleDeleteItem
  } = useShoppingCartActions(index, item);
  const {
    unit_gram,
    id_item,
    price,
    unit_measurement,
    unityType,
    title,
    pum,  
    maturity,
    main_category_id
  } = item;
  const maturityClass = 'maturity-' + maturity;

  return (
    <div className={ styles['product-container'] }>

      {/* IMAGE CONTAINER */}
      <div className={ styles['product-image-container'] }>
        <LazyLoad once>
          <Image id={id_item} className={ `img-fluid ${ styles['product-image'] }` } />
        </LazyLoad>
      </div>

      {/* DETAILS CONTAINER */}
      <div className={ styles['product-details-container'] }>
          <p className={ styles['product-name'] }>{ title.toLowerCase() }</p>
          <p> { splitCategoria(main_category_id) }  </p>
          <p className={ styles['product-und-price'] }>
            { unit_measurement } a { formatCurrency( price ) }
          </p>
          <div className={ styles['product-fruver-meat'] }>
            {main_category_id === '0004-FRUTAS Y VERDURAS' && 
              <p className={ styles['product-fruver-meat-info'] }>
                Madurez: <span className={styles[maturityClass]}><b>{getMaturity(maturity)}</b></span>
              </p>}
            <p>Medido en: {unityType === 0 ? <b>Unidades</b> : <b>Gramos</b>}</p>
          </div>
          
      </div>

      {/* ACTION CONTAINER */}
      <div className={ styles['product-action-container'] }>
        <div className={ styles['product-action-button'] } onClick={handleDeleteItem}>
          <i className="fa fa-trash-alt"/>
        </div>
        <p className={ styles['product-price'] }>
          
        {
            ((item.unit_measurement_full !== 'UND' && item.main_category_id === '0004-FRUTAS Y VERDURAS' && item.unityType === 0)
            || (item.unit_measurement_full !== 'UND' && (item.sub_category_id === 'POLLO' || item.sub_category_id === 'PESCADOS Y MARISCOS') && item.unityType === 0))  ?
              formatCurrency(unityType === 0 ? (parseFloatWithComma(pum)  * unit_gram)* currentCount : parseFloatWithComma(pum) * currentCount)
              :
              formatCurrency(unityType === 0 ? price * currentCount : parseFloatWithComma(pum) * currentCount)
          } 
        </p>
        <div className={ styles['product-more-less-buttons'] }>
          
          <Counter
            handleDecrement={handleDecrement}
            handleIncrement={handleIncrement}
            value={currentCount} />
        </div>
      </div>

    </div>
  );
};

ShoppingCartItem.propTypes = {
  index: PropTypes.number.isRequired,
  item: PropTypes.object
};

ShoppingCartItem.defaultProps = {
  item: {}
};

export default ShoppingCartItem;
