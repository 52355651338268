import React from 'react';
import PropTypes from 'prop-types';
import Image from 'components/image/Image';
import { formatCurrency, capitalizeString, getPrice } from 'utils/format';
// import Img froms 'react-cloudinary-lazy-image';
import LazyLoad from 'react-lazyload';
import { useSelector } from 'react-redux';

const ItemMobile = ({ product, offerProduct }) => {

  const currentCity = useSelector((state) => (state.cities.city));
  const {
    discount,
    id_item,
    pum,
    title,
    unit_measurement_pum,
  } = product;
  let price_neto = getPrice(currentCity, product);
  product.price = price_neto;

  const shouldDisplayDiscountData = +discount !== 0;
  const shouldDisplayOfferImg = offerProduct;
  const isNewProduct = product.new_product === true;
  return (
    <div className="item-card">
      <div className="ima-productos rounded-lg">
        {shouldDisplayDiscountData && (<div className="label-offer">{discount}%</div>)}
        {shouldDisplayOfferImg && (
          <img
            className="offer-img"
            src={process.env.PUBLIC_URL + "/images/oferta.png"}
            alt="offer"
          />
        )}
        {isNewProduct && (
          <img
            className="new-product-img"
            src={process.env.PUBLIC_URL + "/images/Producto_nuevo.png"}
            alt="new-product"
          />
        )}
        <div className='full-width'>
          <LazyLoad once>
            <Image id={id_item} className="preload-image rounded-image img-product-mobile" />
          </LazyLoad>
        </div>
      </div>
      <div className="content-item-products" style={{ padding: 8 }}>
        <h4 className="color-default bottom-10 font-14" style={{ textAlign: "center" }}>
          {capitalizeString(title)}
        </h4>
        {/* <p className="bottom-10 font-12" style={{textAlign:"center"}}>{quantity_pum+" "+capitalizeString(unit_measurement_pum_full)}</p> */}
        <p className="bottom-10 font-12" style={{ textAlign: "center" }}>PLU: {id_item}</p>
        <p className="bottom-10" style={{ textAlign: "center" }}>
          <span className="color-green font-15" style={{ fontWeight: "bold" }}>{formatCurrency(price_neto)}</span>
          {shouldDisplayDiscountData && (<span className="color-gray left-20 old-price">{formatCurrency(product.price)}</span>)}
        </p>
        <p className="bottom-10 color-default font-12" style={{ textAlign: "center" }}>{unit_measurement_pum} a $ {pum}
          {shouldDisplayDiscountData && (
            <span>
              <i className="fa fa-tag color-green icon-round-border font-9 left-20" />
              Ahorra {formatCurrency(product.price - price_neto)}
            </span>)}
        </p>
      </div>
    </div>
  );
};

ItemMobile.propTypes = {
  product: PropTypes.object
};

ItemMobile.defaultProps = {
  product: {}
};

export default ItemMobile;
