import {
  call,
  put,
  takeEvery
} from 'redux-saga/effects';
import firebase from 'config/firebaseConfig';
import * as actions from './productsActions';
import { reduxSagaFirebase } from '../../rootSaga';

function* fetchDetailedProduct({ payload: { category, productId }}) {
  try {
    var startCount = Date.now();
    const documentRef = firebase.firestore().collection(`/categories/${category}/products/${productId}`).orderBy('description', 'asc');
    const documentSnapshot = yield call(reduxSagaFirebase.firestore.getDocument, documentRef);
    const detailedProduct = documentSnapshot.data();

    var endCount = Date.now();
    
    console.log(`Geting product ${detailedProduct.id_item} from ${category} took: ${endCount - startCount} ms`);
    
    yield put(actions.loadProductPreview(category, detailedProduct));
    yield put(actions.loadDetailedProductSuccessed(detailedProduct));
    
  } catch (error) {
    console.error(error);

    yield put(actions.loadProductsPreviewFailed());
  }
}

function* fetchProductsPreviewPerCategory({ payload: { category, city } }) {
  try {
    const origin = city==='cali'?'Cali':'Medellin'
    const documentRef = firebase.firestore().collection(`/categories/${category}/products`)
                                            .where('status', "==", true)
/*                                             .where('origin','in',[
                                              "Ambos",
                                              origin
                                            ]) */
                                            .where('stock_'+city, ">=", 1) 
/*                                             .orderBy('stock_'+city,'asc')
                                            .orderBy('description', 'asc') */
                                            .limit(15);
    const snapshot = yield call(reduxSagaFirebase.firestore.getCollection, documentRef);
    const documents = snapshot.docs;
    const products = yield documents.map((document) => (document.data()));

    yield put(actions.loadProductsPreviewSuccessed(category, products));
  } catch (error) {
    console.error(error);

    yield put(actions.loadProductsPreviewFailed());
  }
}

function* fetchProductsOffers({ payload: { categories, city } }) {
  try {

    const origin = city==='cali'?'cali':'medellin'
    let documentRef;
    let arreglo = [];
    for(let i = 0; i < categories.length; i++){
      documentRef = firebase.firestore().collection(`/categories/${categories[i].name}/products`)
                                        .where('status', "==", true)
                                        .where('stock_'+city, ">=", 1)
                                        .where('oferta_'+origin, "==", "S");
      const snapshot = yield call(reduxSagaFirebase.firestore.getCollection, documentRef);
      const documents = snapshot.docs;
      const products = yield documents.map((document) => (document.data()));
      if(products.length > 0){
        products.map(product => arreglo.push(product))
      }
    }
    yield put(actions.loadProductsOfferSuccessed(arreglo));
  } catch (error) {
    console.error(error);
    yield put(actions.loadProductsOfferFailed());
  }
}

export function* productsSaga() {
  yield takeEvery([actions.loadDetailedProductRequest], fetchDetailedProduct);
  yield takeEvery([actions.loadProductsPreviewRequest], fetchProductsPreviewPerCategory);
  yield takeEvery([actions.loadProductsOfferRequest], fetchProductsOffers);
}
