import React from 'react';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import 'moment/locale/es';
import { formatCurrency } from 'utils/format';
import '../../styles/mobile/framework-store.css';

const OrderHistoryMobile = ({ orders }) => (
    <>
        <div className="page-content h-fixed">
            <div className="page-login page-login-full bottom-20">
                <h2 className="regular top-0 bottom-0 font-30">Historial de pedidos</h2>
                <p className="font-20 top-10 bottom-30 color-default">{orders.length} pedidos</p>
                {orders.map((order, index) => {
                    
                    const {
                        id_order,
                        createdAt,
                        price_total,
                        status
                    } = order;

                    const statusColor = status === 'CANCELADO' ? 'color-red' : status === 'FINALIZADO' ? 'color-blue' : 'color-green';

                    return (
                        <NavLink to={`/detalle_orden/${order.id}`} key={`order-${index}`}>
                            <div className="row">
                                <div className="top-15 bottom-15 full-width">
                                    <div className="col1">
                                        <p className="color-default bottom-0">Orden <b>#{id_order}</b></p>
                                        <p className="bottom-0">Estado: <b className={statusColor}>{status}</b></p>
                                    </div>
                                    <div className="col2">
                                        <p className="bottom-0 align-right">Total: <b className="color-green">{formatCurrency(price_total)}</b></p>
                                        <p className="color-default font-12 bottom-0 align-right">
                                            {moment(createdAt).locale('es').format('MMMM D, YYYY')}
                                        </p>
                                    </div>
                                </div>                            
                                <div className="row align-right align-vertical-center">
                                    <i className="fa fa-angle-right color-green font-20"></i>
                                </div>                            
                            </div>   
                            <div className="decoration top-5 bottom-5"></div>
                        </NavLink>   
                    );                                  
                })}
            </div>
        </div>
    </>
);

export default OrderHistoryMobile;