import { createActions, handleActions } from 'redux-actions';

const defaultState = {
  drawerOpen: false,
  list: [],
  ui: {
    isLoading: false
  }
};

const {
  loadConfigsRequest,
  loadConfigsSuccess,
  loadConfigsFailed,
  updateStatusDrawer
} = createActions({
  LOAD_CONFIGS_REQUEST: undefined,
  LOAD_CONFIGS_SUCCESS: (configs) => ({ configs }),
  LOAD_CONFIGS_FAILED: undefined,
  UPDATE_STATUS_DRAWER: (drawerOpen) => ({drawerOpen})
});

const reducer = handleActions({
  [loadConfigsRequest]: (state) => ({
    ...state,
    ui: {
      isLoading: true
    }
  }),
  [loadConfigsSuccess]: (state, { payload: { configs }}) => ({
    ...state,
    list: configs,
    ui: {
      isLoading: false
    }
  }),
  [loadConfigsFailed]: (state)=> ({
    ...state,
    ui: {
      isLoading: false
    }
  }),
  [updateStatusDrawer]: (state, {payload: { drawerOpen }})=> ({
    ...state,
    drawerOpen,
    ui: {
      isLoading: false
    }
  })
}, defaultState);

export {
  defaultState,
  loadConfigsRequest,
  loadConfigsSuccess,
  loadConfigsFailed,
  updateStatusDrawer
};

export default reducer;