import React, { useState, useEffect } from "react";
import "date-fns";
import esLocale from "date-fns/locale/es";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import ToolbarMobile from "components/layout/components/mobile/ToolbarMobile";
import { useDeliveryDetailsBehavior, useShoppingCartValues } from "hooks";
import { userSelector } from "selectors";
import Swal from "sweetalert2";
import {
  updateStatusOrder,
  updateCreateAt,
  clearCurrentOrder,
  createLinkToPay,
} from "store/domains/orders/ordersActions";
import {
  createOrderRequest,
  updateDeliveryAndPaymentInfo,
  updateDeliveryDetail,
  updateStatesOrder,
} from "store/domains/shoppingCart/shoppingCartActions";
import { Loading } from "components/common/Loading";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { stores, deliveryTimes } from "utils/const";

const DeliveryDetailsMobile = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  // selectors:
  const user = useSelector(userSelector);
  const status = useSelector((state) => state.orders.currentOrder);
  const {
    referenceCode,
    addresses,
    deliveryAddress,
    deliveryDate,
    isStoreClosed,
    paymentMethod,
    handleDeliveryAddressChange,
    handlePaymentMethodChange,
    handlePickupStoreChange,
    handleDeliveryTimeChange,
    handleDeliveryTypeChange,
    shouldDisableConfirmButton,
    deliveryNeighborhood,
    deliveryZone,
    deliveryCity,
    pickupStore,
    deliveryTime,
    deliveryType,
  } = useDeliveryDetailsBehavior();

  const orderStage = useSelector((state) => state.shoppingCart.ui.orderStage);
  console.log(orderStage);
  const [confirmState, setConfirmState] = useState(false);

  const [message, setMessage] = useState("Confirmar");
  const [hour, setHour] = useState(new Date());
  const [hourList, setHourList] = useState([]);

  useEffect(() => {
    let MounthNow = hour.getMonth() + 1;
    let MountSelect = deliveryTime.date.getMonth() + 1;
    let DateNow = hour.getDate() + "/" + MounthNow;
    let DateSelect = deliveryTime.date.getDate() + "/" + MountSelect;
    let hourListTemp = [];
    let hourInt = parseInt(hour.getHours() + "" + hour.getMinutes()) + 100;
    let deliveryTimeTemp = 0;

    DateNow.includes(DateSelect)
      ? deliveryTimes.map((hourTemp) => {
          deliveryTimeTemp > 1200
            ? (deliveryTimeTemp =
                parseInt(
                  "0" +
                    hourTemp.split(" ")[0].split(":")[0] +
                    hourTemp.split(" ")[0].split(":")[1]
                ) + 1200)
            : (deliveryTimeTemp = parseInt(
                hourTemp.split(" ")[0].split(":")[0] +
                  hourTemp.split(" ")[0].split(":")[1]
              ));

          deliveryTimeTemp > hourInt
            ? hourListTemp.push(hourTemp)
            : console.log(hourListTemp.length);
          setHourList(hourListTemp);
        })
      : setHourList(deliveryTimes);
  }, [deliveryTime]);

  useEffect(() => {
    !deliveryTime.time.includes("default")
      ? 1 <= deliveryTime.date.getDay() &&
        deliveryTime.date.getDay() <= 5 &&
        deliveryTime.time.split(" ")[1].includes("PM")
        ? parseInt(
            deliveryTime.time.split(" ")[0].split(":")[0] +
              deliveryTime.time.split(" ")[0].split(":")[1]
          ) > 400
          ? setMessage(
              "Su pedido Sera entregado el dia de mañana en el transcurso del dia"
            )
          : setMessage("Confirmar")
        : deliveryTime.date.getDay() === 6 &&
          deliveryTime.time.split(" ")[1].includes("PM")
        ? parseInt(
            deliveryTime.time.split(" ")[0].split(":")[0] +
              deliveryTime.time.split(" ")[0].split(":")[1]
          ) > 400
          ? setMessage(
              "Su pedido sera entregado el dia Lunes en el transcurso del dia"
            )
          : setMessage("Confirmar")
        : deliveryTime.date.getDay() === 0
        ? setMessage(
            "Su pedido sera entregado el dia Lunes en el transcurso del dia"
          )
        : setMessage("Confirmar")
      : setMessage("Confirmar");
  }, [deliveryTime.time]);

  const handleConfirmClick = () => {
    if (!shouldDisableConfirmButton) {
      if (user.phone) {
        if (status) {
          dispatch(clearCurrentOrder());
        }
        dispatch(updateStatusOrder("CREADO"));
        dispatch(updateCreateAt(new Date().toISOString()));
        handleStatusChange("CREADO");
        setConfirmState(true);
        const dateTimeOfDelivery = `${deliveryTime.date.toLocaleDateString()} ${
          deliveryTime.time
        }`;
        console.log(
          "deliveryAddress: " +
            deliveryAddress +
            " paymentMethod: " +
            paymentMethod +
            " deliveryNeighborhood: " +
            deliveryNeighborhood +
            " deliveryZone: " +
            deliveryZone +
            " deliveryType: " +
            deliveryType +
            " dateTimeOfDelivery: " +
            dateTimeOfDelivery +
            " deliveryCity: " +
            deliveryCity
        );
        dispatch(
          updateDeliveryAndPaymentInfo({
            deliveryAddress,
            paymentMethod,
            deliveryNeighborhood,
            deliveryZone,
            deliveryType,
            deliveryTime: dateTimeOfDelivery,
            deliveryCity,
          })
        );
        dispatch(createOrderRequest());
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Debe registrar su teléfono para continuar con su orden",
        }).then((result) => {
          history.push("/perfil");
        });
        console.log(
          "Error: Debe registrar su teléfono para continuar con su orden"
        );
      }
    }
  };

  const handleStatusChange = (status) => {
    let changeStatusDate = "";
    let statusDates = [];
    if (new Date().getMinutes() < 10) {
      changeStatusDate =
        new Date().getDate() +
        "/" +
        (new Date().getMonth() + 1) +
        "/" +
        new Date().getFullYear() +
        " " +
        new Date().getHours() +
        ":" +
        ("0" + new Date().getMinutes());
    } else {
      changeStatusDate =
        new Date().getDate() +
        "/" +
        (new Date().getMonth() + 1) +
        "/" +
        new Date().getFullYear() +
        " " +
        new Date().getHours() +
        ":" +
        new Date().getMinutes();
    }
    if (status === "CREADO") {
      statusDates.push({
        CREADO: changeStatusDate,
      });
    }
    if (status === "PAGO EN PROCESO") {
      statusDates.push({
        PAGO_EN_PROCESO: changeStatusDate,
      });
    }

    dispatch(updateStatesOrder(statusDates));
  };

  useEffect(() => {
    if (orderStage === 2) {
      history.push("/orden");
    }
  }, [orderStage, history]);

  const { totalValue } = useShoppingCartValues();

  const handleclic = () => {
    const dateTimeOfDelivery = `${deliveryTime.date.toLocaleDateString()} ${
      deliveryTime.time
    }`;
    dispatch(
      updateDeliveryDetail({
        deliveryAddress,
        paymentMethod,
        deliveryNeighborhood,
        deliveryZone,
        deliveryType,
        deliveryTime: dateTimeOfDelivery,
        deliveryCity,
        totalValue,
      })
    );
  };

  const handleClicLine = () => {
    if (status) {
      dispatch(clearCurrentOrder());
    }
    handleStatusChange("PAGO EN PROCESO");
    dispatch(updateStatusOrder("PAGO EN PROCESO"));
    dispatch(updateCreateAt(new Date().toISOString()));
    setConfirmState(true);
    const dateTimeOfDelivery = `${deliveryTime.date.toLocaleDateString()} ${
      deliveryTime.time
    }`;
    dispatch(
      updateDeliveryAndPaymentInfo({
        deliveryAddress,
        paymentMethod,
        deliveryNeighborhood,
        deliveryZone,
        deliveryType,
        deliveryTime: dateTimeOfDelivery,
        deliveryCity,
      })
    );
    dispatch(createOrderRequest());
    // establecer el envio del pago en línea
    dispatch(createLinkToPay(true));
  };
  return (
    <>
      <ToolbarMobile
        isBack={!confirmState}
        showCart={false}
        dismissUser={confirmState && true}
      />
      <div className="page-content h-fixed">
        <div className="page-login page-login-full bottom-20">
          <h2 className="regular top-0 bottom-20 font-30">
            Detalles de entrega
          </h2>
          {isStoreClosed && (
            <div className="notification-large notification-has-icon notification-red">
              <div className="notification-icon">
                <i className="fa fa-exclamation-triangle notification-icon" />
              </div>
              <p>
                Actualmente nuestra tienda está cerrada. Nuesto hoario de
                atención es de lunes a viernes de 8am a 4pm Tu pedido llegará
                aproximadamente el {deliveryDate} en el transcurso del día.
              </p>
            </div>
          )}
          <p className="font-23 top-10 bottom-20">Lugar</p>
          <div className="content">
            <div className="checkboxes-demo">
              {addresses.map((address, index) => (
                <div
                  key={`address-${index}`}
                  className="fac fac-radio-full fac-green"
                >
                  <span />
                  <input
                    onChange={handleDeliveryAddressChange}
                    id={`address-${index}`}
                    type="radio"
                    name="addressRad"
                    value={index}
                  />
                  <label htmlFor={`address-${index}`} className="bottom-15">
                    <p className="bottom-0">
                      <span className="color-gray">
                        {" "}
                        <b>{address.city}</b> - {address.alias}
                      </span>
                      <br />
                      {address.value}
                    </p>
                  </label>
                </div>
              ))}

              <div className="fac fac-radio-full fac-green top-10 bottom-20">
                <span />
                <input
                  onChange={handleDeliveryAddressChange}
                  type="radio"
                  id="radio-pickup"
                  name="addressRad"
                  value="pickup"
                />
                <label htmlFor="radio-pickup">
                  <p className="bottom-0 color-default">
                    <b>PICK UP</b> - Recoger en supermercado
                  </p>
                </label>
              </div>
              {deliveryAddress.includes("PICK UP") && (
                <div>
                  <select
                    id="store-select"
                    className="form-control"
                    name="city-select"
                    value={pickupStore}
                    onChange={handlePickupStoreChange}
                  >
                    {stores.map((store, i) => (
                      <option key={i} value={store}>
                        {store}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>
          </div>

          {addresses.length === 0 && (
            <div className="color-gray bottom-20 align-center">
              <h3 className="color-green">
                ¡No tienes direcciones registradas!
              </h3>
              Por favor registra una dirección.
            </div>
          )}

          <NavLink
            to="/direcciones"
            className="button button-center button-rounded full-width shadow-small button-green"
          >
            Añadir dirección
          </NavLink>
          <div className="decoration top-20" />

          <p className="font-23 top-0 bottom-20">Escoge tu horario</p>

          <div className="content">
            <div className="checkboxes-demo">
              <div className="fac fac-radio-full fac-green">
                <span />
                <input
                  onChange={handleDeliveryTypeChange}
                  id="radio-express"
                  type="radio"
                  name="deliveryType"
                  value="EXPRESS"
                />
                <label htmlFor="radio-express" className="bottom-15">
                  Horario Normal
                  <br />
                  <span className="color-gray">
                    Tu pedido se enviara en el trascurso del día.
                  </span>
                </label>
              </div>
              {deliveryAddress.includes("PICK UP") && (
                <div className="fac fac-radio-full fac-green">
                  <span />
                  <input
                    onChange={handleDeliveryTypeChange}
                    id="radio-program"
                    type="radio"
                    name="deliveryType"
                    value="PROGRAMADO"
                  />
                  <label htmlFor="radio-program" className="bottom-15">
                    Programar pedido
                    <br />
                    <span className="color-gray">
                      Selecciona la fecha y hora que quieres recoger en nuestras
                      tiendas.
                    </span>
                  </label>
                </div>
              )}

              {deliveryType === "PROGRAMADO" &&
                deliveryAddress.includes("PICK UP") && (
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={esLocale}
                  >
                    <DatePicker
                      fullWidth
                      label="Fecha de recolección o envio:"
                      className="top-10 bottom-20"
                      value={deliveryTime.date}
                      onChange={(date) => {
                        handleDeliveryTimeChange(date, "date");
                      }}
                      autoOk
                    />
                    {hourList.length !== 0 ? (
                      <React.Fragment>
                        <label htmlFor="time-select" className="right-10">
                          Hora:
                        </label>
                        <select
                          name="time-select"
                          id="time-select"
                          className="form-control top-0"
                          value={deliveryTime.time}
                          onChange={(e) => {
                            handleDeliveryTimeChange(e.target.value, "time");
                          }}
                        >
                          <option value="default">Seleccionar</option>
                          {hourList.map((time, i) => (
                            <option key={i} value={time}>
                              {time}
                            </option>
                          ))}
                        </select>
                      </React.Fragment>
                    ) : (
                      <label htmlFor="time-select" className="right-10">
                        No hay mas horas programadas para el dia de hoy.
                      </label>
                    )}
                  </MuiPickersUtilsProvider>
                )}
            </div>
          </div>

          <div className="decoration top-20" />

          <p className="font-23 top-0 bottom-20">Método de pago</p>

          <div className="content">
            <div className="checkboxes-demo">
              <div className="fac fac-radio-full fac-green">
                <span />
                <input
                  onChange={handlePaymentMethodChange}
                  id="box4-fac-radio"
                  type="radio"
                  name="radio"
                  value="EFECTIVO"
                />
                <label htmlFor="box4-fac-radio" className="bottom-15">
                  Efectivo
                </label>
              </div>
              <div className="fac fac-radio-full fac-green">
                <span />
                <input
                  onChange={handlePaymentMethodChange}
                  id="box5-fac-radio"
                  type="radio"
                  name="radio"
                  value="TARJETA"
                />
                <label htmlFor="box5-fac-radio" className="bottom-15">
                  <p className="bottom-0">
                    Datafono
                    <br />
                    <span className="color-gray">
                      Datáfono en el punto de entrega
                    </span>
                  </p>
                </label>
              </div>
              <div className="fac fac-radio-full fac-green">
                <span />
                <input
                  onChange={handlePaymentMethodChange}
                  id="box6-fac-radio"
                  type="radio"
                  name="radio"
                  value="BONOS"
                />
                <label htmlFor="box6-fac-radio">Bonos</label>
              </div>

              <div className="fac fac-radio-full fac-green">
                <span />
                <input
                  onChange={handlePaymentMethodChange}
                  id="box7-fac-radio"
                  type="radio"
                  name="radio"
                  value="GLOBALPAY"
                />
                <label htmlFor="box7-fac-radio">Pago en línea</label>
              </div>
            </div>
          </div>

          <div className="decoration top-30" />
          {paymentMethod.includes("GLOBALPAY") ? (
            deliveryAddress && deliveryType ? (
              <button
                className={`button bottom-15 button-full button-rounded shadow-small button-green`}
                onClick={handleClicLine}
              >
                Pagar en línea
              </button>
            ) : (
              <span className="ml-3">
                Seleccione todos los campos obligatorios.
              </span>
            )
          ) : confirmState ? (
            <Loading caption="Creando el pedido ..." />
          ) : (
            <Button
              className={`button bottom-15 button-full button-rounded shadow-small  ${
                shouldDisableConfirmButton ? "button-gray" : "button-green"
              }`}
              onClick={handleConfirmClick}
              disabled={shouldDisableConfirmButton}
            >
              {message}
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default DeliveryDetailsMobile;
