import { call, put, takeEvery } from 'redux-saga/effects';
import {
  loadCategoriesRequest,
  loadCategoriesSuccess,
  loadCategoriesFailed
} from './categoryActions';
import firebase from 'config/firebaseConfig';
import { reduxSagaFirebase } from '../../rootSaga';


function* fetchCategories() {
  try {
    firebase.firestore();
    
    const documentRef = firebase.firestore().collection('categories').orderBy('weight','asc');
    const snapshot = yield call(reduxSagaFirebase.firestore.getCollection,  documentRef );
    const documents = snapshot.docs;
    const categories = yield documents.map((document) => (document.data()));
    yield put(loadCategoriesSuccess(categories));
  } catch (error) {
    console.error(error);
    yield put(loadCategoriesFailed());
  }
}

export function* categoriesSaga() {
  yield takeEvery([loadCategoriesRequest], fetchCategories);
}
