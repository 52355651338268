import {
    HANDLE_CLOSE,
    HANDLE_SHOW,
    LOADING_LOGIN,
    LOGIN_ERROR,
    LOGIN_SUCCESS,
    SIGNOUT_SUCCESS,
    SIGNUP_ERROR,
    SIGNUP_SUCCESS,
    FORGOTPW_SUCCESS,
    FORGOTPW_ERROR
} from "../actions/authActions";

const initState = {
    authError: null,
    showModal: false,
    loading: false,
    forgotPwSended: false,
};

const authReducer = (state = initState, action) => {

    switch (action.type) {
        case LOADING_LOGIN:
            return {
                ...state,
                loading: true,
                authError: null
            };
        case LOGIN_ERROR:
            return {
                ...state,
                authError: 'Error al iniciar sesión, ' + authErrorMessage(action.err.code),
                loading: false,
            };
        case LOGIN_SUCCESS:
            console.log('Login success');
            return {
                ...state,
                showModal: false,
                loading: false,
                authError: null
            };

        case SIGNOUT_SUCCESS:
            console.log('signout success');
            return {
                ...state,
                loading: false
            };

        case SIGNUP_SUCCESS:
            console.log('signup successs');
            return {
                ...state,
                showModal: false,
                loading: false,
                authError: null
            };

        case SIGNUP_ERROR:
            console.log('signup error');
            return {
                ...state,
                authError: 'Error al crear la cuenta, ' + authErrorMessage(action.err.code),
                loading: false,
            };

        case FORGOTPW_SUCCESS:
            return {
                ...state,
                loading: false,
                forgotPwSended: true,
                authError: null
            };

        case FORGOTPW_ERROR:
            console.log('signup error');
            return {
                ...state,
                authError: 'Error al enviar el correo de recuperación, ' + authErrorMessage(action.err.code),
                loading: false,
            };

        case HANDLE_SHOW:
            return {
                ...state,
                showModal: true
            };

        case HANDLE_CLOSE:
            return {
                ...state,
                showModal: false
            };

        default:
            return {
                ...state,
                authError: null
            };

    }
};

const authErrorMessage = errorCode => {
    switch (errorCode) {
        case 'auth/invalid-email':            
            return 'Correo electrónico no valido.';
        case 'auth/wrong-password':            
            return 'Contraseña incorrecta.';
        case 'auth/user-not-found':            
            return 'No se encontró cuenta del usuario con el correo especificado.';
        case 'auth/network-error':            
            return 'Problema al intentar conectar al servidor.';
        case 'auth/missing-email':            
            return 'Hace falta registrar un correo electrónico.';    
        case 'auth/operation-not-allowed':            
            return 'Operación no permitida.'; 
        case 'auth/weak-password':            
            return 'Contraseña muy debil o no válida.'; 
        case 'auth/email-already-in-use':            
            return 'El correo electronico especificado ya se encuentra en uso.'; 
        case 'auth/popup-closed-by-user':            
            return 'No se completo el inicio de sesión con el proveedor.'; 
        case 'auth/internal-error':            
            return 'Error interno.'; 
        case 'auth/too-many-requests':            
            return 'Ya se han enviado muchas solicitudes al servidor, espere un momento para volver a intentar.'; 
        default:
            return `Se produjo un error desconocido: ${errorCode}`;
    }
}

export default authReducer;

