import React from 'react';
import { Container,Button } from "react-bootstrap";
import { Close } from '@material-ui/icons';
import { Dialog, IconButton, Fade } from '@material-ui/core';
import { isMobile } from 'react-device-detect';

import { ChangeCitiesHome } from 'hooks';

const CitiesModalMobile = (props) => {
    const { currentCity, showModal } = props
    const [open, setOpen] = React.useState(!currentCity);
    
    React.useEffect(() => {
        setOpen(showModal);
    }, [showModal]);

    const handleClose = () => {
        handleToggleModal(false);
        // setOpen(false);
    }
    
    const {
        handleChangeCity,
        handleToggleModal
    } = ChangeCitiesHome();

    const handleButtonModal = (city) => {
        handleChangeCity(city)
        handleClose()
    }

    // const Transition = React.forwardRef(function Transition(props, ref) {
    //     return <Slide direction="up" ref={ref} {...props} />;
    // });

    const mobileStyles = {
        borderRadius: 0,
        justifyContent: 'flex-start',
        maxWidth: 'none',
        maxHeight: 'none',
        margin: 0,
        overflowY:'auto',
        paddingTop: 17,
        boxSizing:'border-box' 
    }
    return (
        <Dialog 
            className="dialog-home"
            onClose={handleClose}
            open={open}
            TransitionComponent={ Fade }
            transitionDuration={300}
            PaperProps={{
                style: isMobile ? mobileStyles : { borderRadius: 16 }
            }} 
        >
            <IconButton className='btn-exit' onClick={handleClose} style={{ zIndex:1 }}>
                <Close />
            </IconButton>
            <Container>
            <h4 className='mt-6 mb-3 text_primary'>Bienvenido</h4>
            <p className='mb-2'>¿Desde dónde te estás conectando?</p>
            <Button
                className='btn-city button bottom-15 button-green button-full button-rounded button-sm font-15 shadow-small'
                variant='outlined'
                fullWidth
                onClick={() => handleButtonModal('Cali')}
            >
                Cali
            </Button>

            <Button
                className='btn-city button bottom-15 button-green button-full button-rounded button-sm font-15 shadow-small'
                variant='outlined'
                fullWidth
                onClick={() => handleButtonModal('Medellin')}
            >
                Medellin
            </Button>
            </Container>
        </Dialog>
    );
}

export default CitiesModalMobile;