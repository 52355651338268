import React from 'react';
import { useSelector } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import question from 'images/questions.svg';
import { Grid } from '@material-ui/core';

export const QuestionsPage = () => {
    const configs = useSelector((state) => (state.configs.list));
    
    const questions = configs[1].questions_and_answers

    return (
        <div className="h-fixed page-content page-faq">
            <div className="content-title">
                <div>
                    <figure>
                        <img src={question} alt="Preguntas Frecuentes" />
                    </figure>
                    <h1 className="grid-title-question title-questionFre">
                        Preguntas Frecuentes
                    </h1>
                </div>
            </div>
            <div className="grid-title-question">
                <Grid container spacing={1}>
                    {
                        questions.map( (quest, index) => {
                            const answer = quest.answer;
                            const question = quest.question;
                            return(
                                <Grid container item xs={12} sm={12} spacing={1} key={index}>
                                    <Paper elevation={3} className="p-4 m-3 papper-question">
                                        <span className="title-content">
                                            {question}
                                        </span>
                                        <div className="answer-content">
                                            {answer} 
                                        </div>
                                    </Paper>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </div>

        </div>
    )
}
