import React, {  useState } from 'react';
import { isBrowser } from "react-device-detect";

import ResponseGridDesktop from './ResponseGridDesktop';
import ResponseGridMobile from "./ResponseGridMobile";
import { useHistory } from "react-router-dom";



const Response = () => {
	const ResponseLayout = isBrowser ? ResponseGridDesktop : ResponseGridMobile;
    const history = useHistory();
    const [wompiResponse, setWompiResponse] = useState();
    const [referenceResponse, setReferenceResponse] = useState('');
    const [priceResponse, setPriceResponse] = useState(0);
    const [payMethodResponse, setPayMethodResponse] = useState('');
    const [createResponse, setCreateResponse] = useState('');
    const [UrlResponse, setUrlResponse] = useState( () => {
        const search = history.location.search;
        const params = new URLSearchParams(search);
        let info = params.get('id')
        return info;
    });


    const axios = require('axios');

    axios.get(`https://sandbox.wompi.co/v1/transactions/${UrlResponse}`).then(resp => {
        console.log(resp['data'].data);
        setCreateResponse(resp['data'].data.created_at);
        setPayMethodResponse(resp['data'].data.payment_method_type);
        setPriceResponse(resp['data'].data.amount_in_cents);
        setReferenceResponse(resp['data'].data.reference);
        setWompiResponse(resp['data'].data.status);
    });
	return (
	    
        <div>
            <ResponseLayout response={wompiResponse} referenceResponse={referenceResponse} priceResponse={priceResponse} payMethodResponse ={payMethodResponse} createResponse={createResponse}/>
        </div>
	)
};

export default Response;