import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import logoLogin from "../../../images/logo-login.png";
import LoginModal from "./LoginModal";
import RegisterModal from "./RegisterModal";
import ForgotPwModal from "./ForgotPwModal";

const AuthModal = ({
  handleChangeAlert,
  handleClose,
  show,
  shouldDisplayLoginForm
}) => {
  const ChildrenModal = shouldDisplayLoginForm ? LoginModal : RegisterModal;

  const [showForgotPw, setShowForgotPw] = useState(false);

  const handleForgotPw = () => {
    setShowForgotPw(!showForgotPw);
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      animation={true}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="card box-modal-content">
        <div className="row">
          <div className="col-md-5 back-modal-col">
            <div className="row">
              <div className="col-md-1" />
              <div className="col-md-10 align-center">
                <img
                  src={logoLogin}
                  className="img-fluid top-50 bottom-30"
                  alt="back-img"
                />
                <h2 className="color-white top-50">
                  Bienvenido
                  <br />a La Montaña Agromercados
                </h2>
              </div>
              <div className="col-md-1" />
            </div>
          </div>
          {
            showForgotPw ?
              <ForgotPwModal 
                handleClose={handleClose}
                handleForgotPw={handleForgotPw}
                handleChangeAlert={handleChangeAlert}
              />
              :
              <ChildrenModal
                handleChangeAlert={handleChangeAlert}
                handleClose={handleClose}
                handleForgotPw={handleForgotPw}
              />
          }
        </div>
      </div>
    </Modal>
  );
};

AuthModal.propTypes = {
  handleChangeAlert: PropTypes.func,
  handleClose: PropTypes.func,
  show: PropTypes.bool,
  shouldDisplayLoginForm: PropTypes.bool
};

AuthModal.defaultProps = {
  handleChangeAlert: () => { },
  handleClose: () => { console.log('hi') },
  show: false,
  shouldDisplayLoginForm: false
};

export default AuthModal;
