import React from 'react';
import {isBrowser, isMobile } from 'react-device-detect';
import '../../styles/mobile/framework-store.css'
import TracingMobile from "./TracingMobile";
import TracingDesktop from "./TracingDesktop";

class Tracing extends React.Component {
    render() {

        if (isBrowser) {
            return <TracingDesktop/>
        }

        if (isMobile) {
            return <TracingMobile/>
        }
    }
}

export default Tracing;