import React from 'react';
import {Link} from "react-router-dom";
import icon_email from "../../../images/figu-email.png";

class ResetPasswordSuccessMobile extends React.Component {
    render() {
        return (
            <React.Fragment>
                <div className="page-content page-content-full">
                    <h2>kdsjfsjdkfl</h2>
                    <Link
                        className="get-started-custom shadow-large back-button button button-rounded button-full button-green font-15"
                        to="/ingreso">
                        Aceptar
                    </Link>
                    <div className="cover-item">
                        <div className="cover-content cover-content-center">
                            <img className="cover-icon" src={icon_email} alt="icon_email"/>
                            <h2 className=" center-text bottom-20 regular font-30">Revisa tu email</h2>
                            <p className="center-text bottom-30">Hemos enviado instrucciones para<br/>recuperar tu
                                cuenta.</p>
                        </div>
                        <div className="cover-overlay overlay"/>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ResetPasswordSuccessMobile;
