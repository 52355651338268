import { createActions, handleActions } from 'redux-actions';


export const defaultState = {
  unit_gram: 0,
  id_order:'',
  currentOrderId: '',
  statusDates: [],
  newProductAdded: false,
  order: [],
  deliveryAddress: '',
  deliveryZone: '',
  deliveryCity: '',
  paymentMethod: '',
  deliveryNeighborhood: '',
  deliveryType: '',
  deliveryTime: '',
  observations_client: '',
  ui: {
    orderStage: 0,
    isCreatingOrder: false
  },
  domiciliary: '',
  domiciliary_id: '',
  observations_admin: '',
  price_domicile: 0,
  orderObservations: '',
  client_response: '',
  bags_count: 0,
  bags_price: 0,
  totalValue: '',
  isCreated: false
};

const {
  addItemToOrder,
  noItemAdded,
  clearShoppingCart,
  createOrderRequest,
  createOrderSuccessed,
  createOrderFailed,  
  decrementItemQuantity,
  incrementItemQuantity,
  decrementOrderStep,
  incrementOrderStep,
  removeItemFromOrder,
  updateDeliveryAndPaymentInfo,
  updateObservationsClient,
  updateClientResponse,
  updateBag,
  updateDeliveryDetail,
  updateUnitGram,
  updateStatesOrder,
  creatingStatus,
} = createActions({
  ADD_ITEM_TO_ORDER: (item) => ({ item }),
  NO_ITEM_ADDED: undefined,
  CLEAR_SHOPPING_CART: undefined,
  CREATE_ORDER_REQUEST: undefined,
  CREATE_ORDER_SUCCESSED: (orderId) => ({ orderId }),
  CREATE_ORDER_FAILED: undefined,
  DECREMENT_ITEM_QUANTITY: (itemIndex, useGr) => ({ itemIndex, useGr }),
  INCREMENT_ITEM_QUANTITY: (itemIndex, useGr) => ({ itemIndex, useGr }),
  INCREMENT_ORDER_STEP: undefined,
  DECREMENT_ORDER_STEP: undefined,
  REMOVE_ITEM_FROM_ORDER: (item) => ({ item }),
  UPDATE_DELIVERY_AND_PAYMENT_INFO: ({ deliveryAddress, paymentMethod, deliveryNeighborhood, deliveryZone, deliveryType, deliveryTime, deliveryCity}) => ({ deliveryAddress, paymentMethod, deliveryNeighborhood, deliveryZone, deliveryType, deliveryTime, deliveryCity }),
  UPDATE_OBSERVATIONS_CLIENT: ({observations_client}) => ({observations_client}),
  UPDATE_CLIENT_RESPONSE: ({client_response}) => ({client_response}),
  UPDATE_BAG: (bagsCount, bagsPrice) => ({bagsCount, bagsPrice}),
  UPDATE_DELIVERY_DETAIL: ({deliveryAddress, paymentMethod, deliveryNeighborhood, deliveryZone, deliveryType, deliveryTime, deliveryCity, totalValue}) => ({ deliveryAddress, paymentMethod, deliveryNeighborhood, deliveryZone, deliveryType, deliveryTime, deliveryCity, totalValue }),
  UPDATE_UNIT_GRAM: (unit_gram) => ({unit_gram}),
  UPDATE_STATES_ORDER: (statusDates) => ({statusDates}),
  CREATING_STATUS: (isCreated) => ({isCreated})
});

const reducer = handleActions({
  [addItemToOrder]: (state, { payload: { item } }) =>
  {
    const findedItem = state.order.findIndex((p) => p.id_item === item.id_item && p.maturity === item.maturity && p.unityType === item.unityType);
    if (findedItem >= 0) {      
      state.order[findedItem].count += item.count;     
      return ({
        ...state,
        newProductAdded: true
      }) 
    } else {
      return ({
        ...state,
        newProductAdded: true,
        order: [...state.order, item]
      })
    }    
  }, 
  [noItemAdded]: (state) => ({
    ...state,
    newProductAdded: false
  }),
  [clearShoppingCart]: (state) => ({ ...defaultState }),
  [createOrderRequest]: (state) => ({
    ...state,
    ui: {
      ...state.ui,
      isCreatingOrder: true
    }
  }),
  [creatingStatus]: (state, { payload: { isCreated }}) => ({
    ...state,
    isCreated,
    ui: {
      ...state.ui,
    }
  }),
  [createOrderSuccessed]: (state, { payload: { orderId: currentOrderId }}) => ({
    ...state,
    currentOrderId,
    ui: {
      ...state.ui,
      isCreatingOrder: false
    }
  }),
  [createOrderFailed]: (state) => ({
    ...state,
    ui: {
      ...state.ui,
      isCreatingOrder: false
    }
  }),
  [decrementItemQuantity]: (state, { payload: { itemIndex, useGr } }) => {
    if (useGr) {
      state.order[itemIndex].count -= state.unit_gram;
    } else {
      state.order[itemIndex].count--;
    }

    return {
      ...state,
      order: [...state.order]
    };
  },
  [incrementItemQuantity]: (state, { payload: { itemIndex, useGr } }) => {
    if (useGr) {
      state.order[itemIndex].count += state.unit_gram;
    } else {
      state.order[itemIndex].count++;
    }

    return {
      ...state,
      order: [...state.order]
    };
  },
  [decrementOrderStep]: (state) => ({
    ...state,
    ui: {
      orderStage: state.ui.orderStage - 1
    }
  }),
  [incrementOrderStep]: (state) => ({
    ...state,
    ui: {
      orderStage: state.ui.orderStage + 1
    }
  }),
  [removeItemFromOrder]: (state, { payload: { item } }) => ({
    ...state,
    order: state.order.filter((currentItem) => (JSON.stringify(currentItem) !== JSON.stringify(item)))
  }),
  [updateDeliveryAndPaymentInfo]: (state, { payload: { deliveryAddress, paymentMethod, deliveryNeighborhood, deliveryZone, deliveryType, deliveryTime, deliveryCity }}) => ({
    ...state,
    deliveryAddress,
    paymentMethod,
    deliveryNeighborhood,
    deliveryZone,
    deliveryType,
    deliveryTime,
    deliveryCity
  }),
  [updateObservationsClient]: (state, { payload: { observations_client }}) => ({
    ...state,
    observations_client
  }),
  [updateClientResponse]: (state, { payload: { client_response }}) => ({
    ...state,
    client_response
  }),
  [updateBag]: (state, {payload: {bagsCount, bagsPrice}}) =>({
    ...state,
    bags_count: bagsCount,
    bags_price: bagsPrice
  }),
  [updateDeliveryDetail]: (state, { payload: { deliveryAddress, paymentMethod, deliveryNeighborhood, deliveryZone, deliveryType, deliveryTime, deliveryCity, totalValue }}) => ({
    ...state,
    deliveryAddress,
    paymentMethod,
    deliveryNeighborhood,
    deliveryZone,
    deliveryType,
    deliveryTime,
    deliveryCity,
    totalValue
  }),
  [updateUnitGram]: (state, { payload: { unit_gram }}) => ({
    ...state,
    unit_gram
  }),
  [updateStatesOrder]: (state, { payload: { statusDates }}) => ({
    ...state,
    statusDates
  }),
}, defaultState);

export {
  addItemToOrder,
  noItemAdded,
  clearShoppingCart,
  createOrderRequest,
  createOrderSuccessed,
  createOrderFailed,
  decrementItemQuantity,
  incrementItemQuantity,
  decrementOrderStep,
  incrementOrderStep,
  removeItemFromOrder,
  updateDeliveryAndPaymentInfo,
  updateObservationsClient,
  updateClientResponse,
  updateBag,
  updateDeliveryDetail,
  updateUnitGram,
  updateStatesOrder
};

export default reducer;
