import React, { useEffect, useState } from "react";
import { Button } from 'react-bootstrap'; 
import { useDispatch, useSelector } from 'react-redux';
import { isBrowser, isMobile } from 'react-device-detect';
import { useTrackingOrderBehavior } from 'hooks';
import { cancelOrderRequest }  from 'store/domains/orders/ordersActions';
import { updateClientResponse, clearShoppingCart, updateStatesOrder } from '../../store/domains/shoppingCart/shoppingCartActions';
import { clearCurrentOrder, createLinkToPay } from '../../store/domains/orders/ordersActions';
import ToolbarMobile from "components/layout/components/mobile/ToolbarMobile";
import { formatCurrency } from 'utils/format';
import { Scrollbars } from "react-custom-scrollbars";
import { useHistory } from "react-router-dom";
import { userSelector } from 'selectors';
import { useShoppingCartValues } from 'hooks';

// const availableStatuses = [CREADO, CONFIRMADO, ALISTAMIENTO, VALOR_A_PAGAR, DESPACHADO, FINALIZADO ]

import sha256 from 'crypto-js/sha256';
import Base64 from 'crypto-js/enc-base64';
import crypto from "crypto-js";
import Alert from '@material-ui/lab/Alert';

import Loader from 'components/loader/loader';

const TrackingOrder = ( {toggleRightSidebar} ) => {
  
  const currentCity = useSelector((state) => (state.cities.city));
  const city = currentCity? currentCity.toLowerCase() : "medellin";
  const linkToPay = useSelector((state) => (state.orders));
  // cargador
  const [loading, setLoading] = useState(linkToPay.link || false);
  const user = useSelector(userSelector);
  const paymentMethod = useSelector((state) => state.shoppingCart.paymentMethod); 
  
  const {
    totalValue,
  } = useShoppingCartValues();

  const history = useHistory();
  const dispatch = useDispatch();
  const {
    orderId,
    currentOrderId,
    createdAtDate,
    confirmedAtDate,
    separatedAtDate,
    priceToPayedAtDate,
    dispatchedAtDate,
    // deliveryDate,
    // maxDeliveryDate,
    createdStatus,
    confirmedStatus,
    onEnlistmentStatus,
    onAmountToPaidStatus,
    onFinalizedStatus,
    onTransitStatus,
    price_domicile, 
    price_total,
    domiciliary,
    domiciliary_id,
    observations_admin,
    statusDates,
    payToProcessStatus,
    payToProcessAtDate,
    createPayedStatus,
    createPayedAtDate,
    errorStatus,
    cancelStatus,
    declinedStatus,
    status,
    cancel_description,
    client_response,
    amountPaidDate
  } = useTrackingOrderBehavior();
  const [client_response_state, setClientResponse] = useState(client_response);

  const handleCancelClick = () => {
    dispatch(clearShoppingCart())
    dispatch(clearCurrentOrder())
    if (isBrowser) {
      toggleRightSidebar(); // close the aside
    } else {
      history.push('/');
    }
  }

  const handleCancelOrderClick = () => {
    let changeStatusDate = "";
    let statusDate = [];
    let date = new Date();
    if(new Date().getMinutes()<10){
      changeStatusDate = `${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()} ${date.getHours()}:0${date.getMinutes()}`; 
    }else{
      changeStatusDate = `${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`
    }
    if(statusDates){
      statusDate = statusDates;
    }

    statusDate.push(
      {
        CANCELADO: changeStatusDate
      }
    )
    dispatch(clearShoppingCart())
    dispatch(clearCurrentOrder())
    dispatch(cancelOrderRequest(currentOrderId, statusDate));
    if (isBrowser){
      toggleRightSidebar(); // close the aside
    } else {
      history.push('/');
    }
  };
  const handleClientResponse = (event) => {
    setClientResponse(event.target.value);
  };
  const handleClickToOk = () => {
    dispatch(updateClientResponse({ 
      client_response:client_response_state 
    }))

  }

  useEffect(() => {
    if(onFinalizedStatus) {
      dispatch(clearShoppingCart())
      dispatch(clearCurrentOrder())
      if (isBrowser){
        toggleRightSidebar(); // close the aside
      } else {
        history.push('/');
      }
    }
  }, [dispatch, onFinalizedStatus, toggleRightSidebar, history])
  
  const handleCloseClick = () => {
    if (isBrowser) {
      toggleRightSidebar(); // close the aside
    } else {
      history.push('/');
    }
  };
  // verificación del link de pago en linea
  useEffect( () => {
    if(linkToPay.link && totalValue && orderId){
      setLoading(true);
      // establecer el envio del pago
      dispatch(createLinkToPay(false));
      const url_pagos = process.env.REACT_APP_BASE_URL || 'http://localhost:3000';
      const $server_application_code = process.env.REACT_APP_PAY_APP_CODE;
      const $server_app_key = process.env.REACT_APP_PAY_APP_KEY;
      const $date = new Date();
      const $unix_timestamp = Math.floor( $date.getTime() / 1000 );
      const $uniq_token_string = $server_app_key+$unix_timestamp;
      const $uniq_token_hash = sha256( $uniq_token_string ); 
      const word = crypto.enc.Utf8.parse($server_application_code+";"+$unix_timestamp+";"+$uniq_token_hash);
      const $auth_token = Base64.stringify(word);
      // Simple POST request with a JSON body using fetch
      const requestOptions = {
          method: 'POST',
          headers: { 
            'Content-Type': 'application/json',
            'Auth-Token' : $auth_token 
          },
          body: JSON.stringify({
            "user": {
              "id": user.uid,
              "email": user.email,
              "name": user.name,
              "last_name": user.last_name || user.name,
            },
            "order": {
              "dev_reference": orderId,
              "description": 'Compra la montaña agromercados web',
              "amount": totalValue,
              "installments_type": 0,
              "vat": 0,
              "currency": "COP"
            },
            "configuration": {
              "partial_payment": false,
              "success_url": url_pagos,
              "failure_url": url_pagos,
              "pending_url": url_pagos,
              "review_url": url_pagos
            }
          }) 
      };
    const url = process.env.REACT_APP_PAY_URL || 'https://noccapi-stg.globalpay.com.co';
      //'https://noccapi-stg.globalpay.com.co':'https://noccapi.globalpay.com.co';
      
      fetch(`${url}/linktopay/init_order/`, requestOptions)
        .then(response =>  response.json() )
        .then(response =>  {  
          setLoading(false);
          let data = response.data;
          let url = data.payment.payment_url;
          window.location = url;
        })
        .catch( err => {
          console.log(err)
        });
    }
  }, [ linkToPay, orderId, totalValue, user]);

  useEffect(() => {
  
    let changeStatusDate = "";
    if(createPayedStatus && statusDates.length === 1) {
      if(new Date().getMinutes()<10){
        changeStatusDate = new Date().getDate()+"/"+(new Date().getMonth()+1)+"/"+new Date().getFullYear()+" "+new Date().getHours()+":"+("0"+new Date().getMinutes())
      }else{
        changeStatusDate = new Date().getDate()+"/"+(new Date().getMonth()+1)+"/"+new Date().getFullYear()+" "+new Date().getHours()+":"+new Date().getMinutes()
      }
      let statusDate = [];
      if(statusDates){
        statusDate = statusDates;
      }

      statusDate.push(
        {
          PAGADO: changeStatusDate
        }
      );
      dispatch(updateStatesOrder(statusDate));
    }
    
  }, [createPayedStatus, createPayedAtDate, statusDates])
  
  const handleReSendLinkToPay = () => {
    dispatch(createLinkToPay(true));
  }

  return (
    <div style={{height: '100%'}}>
      {isMobile && <ToolbarMobile isBack={true} showCart={false} backToRoot={true} />}       
      <div className="sidebar-right-wrapper page-content h-fixed grid-traking-order" style={{overflow: 'auto'}}>
        <div className="sidebar-widget">
          <div className="row">
            <div className="col-10 left-20">
              <h4 style={{color: '#EA4D0D', fontWeight: 'bold'}}>Pedido #{orderId}</h4>
            </div>
            {isBrowser && 
              <div className="col p-0 text-center">
              <h4 className="color-default">
                <button className="color-default close float-none" onClick={handleCloseClick}>
                  <i className="fas fa-times fa-lg" />
                </button>
              </h4>
            </div>}
          </div>
        </div>
        <div className="sidebar-widget">
{/*           <p className={`bottom-0 color-gray left-20`}>Tu pedido sera entregado en:</p> */}
          <p className={`color-default left-20`} style={{marginBottom: '1rem'}}>Por el Covid-19, su pedido sera confirmado por un representante el cual confirmara su recibido y programara su entrega, de acuerdo a la cobertura de zona y su disponibilidad del dia.</p>
          {isBrowser &&
          <Scrollbars className="" style={{ width: '100%', height: '86%' }}>
            <ul id="timeline" style={{ paddingTop: '0.3rem' }}>
            {
              paymentMethod === "GLOBALPAY" ?
              <React.Fragment>
                <li className={`timeline-item ${!payToProcessStatus ? 'off' : ''}`}>
                  <div className="timeline-icon" />
                  <div className="timeline-body-m">
                    <div className="timeline-lead">
                      <h3 className={`timeline-title ${payToProcessStatus ? 'timeline-subtitle' : ''}`}>{payToProcessStatus?`${payToProcessAtDate} - En Proceso de pago`:'En Proceso de pago'}</h3>
                    </div>
                    <p className="color-gray bottom-0">
                    {/* <div className="timeline-subtitle">En Proceso de pago</div> */}
                      <br />
                    Tu orden esta siendo pagado en linea.
                  </p>
                  </div>
                </li>
                <li className={`timeline-item ${!createPayedStatus ? 'off' : ''}`}>
                  <div className="timeline-icon" />
                  <div className="timeline-body-m">
                    <div className="timeline-lead">
                      <h3 className={`timeline-title ${createPayedStatus ? 'timeline-subtitle' : ''}`}>{createPayedStatus?`${createPayedAtDate} - Pagado`:'Pagado'}</h3>
                    </div>
                    {createPayedStatus &&
                    <p className="color-gray bottom-0">
                      {/* <div className="timeline-subtitle">Pagado</div> */}
                      <br/>
                      Tu orden a sido pagada.
                    </p>
                    }
                  </div>
                </li>
              </React.Fragment>
              :
              <React.Fragment>
                <li className={`timeline-item ${!createdStatus ? 'off' : ''}`}>
                  <div className="timeline-icon" />
                  <div className="timeline-body-m">
                    <div className="timeline-lead">
                      <h3 className={`timeline-title ${createdStatus ? 'timeline-subtitle' : '' }`}>{createdStatus?`${createdAtDate} - Creado `:'Creado'}</h3>
                    </div>
                    {createdStatus &&
                      <p className="color-gray bottom-0">
                      {/* <div className="timeline-subtitle">Creado</div> */}
                      <br />
                        Tu pedido ha sido creado.
                      </p>
                      }
                  </div>
                </li>
              </React.Fragment>
            }
              <li className={`timeline-item ${!confirmedStatus ? 'off' : ''}`}>
                <div className="timeline-icon" />
                <div className="timeline-body-m">
                  <div className="timeline-lead">
                    <h3 className={`timeline-title ${confirmedStatus ? 'timeline-subtitle' : '' }`}>{confirmedStatus? `${confirmedAtDate} - Recibido`:'Recibido'}</h3>
                  </div>
                  {confirmedStatus && (
                    <p className="color-gray bottom-0">
                      {/* <div className="timeline-subtitle">Recibido</div> */}
                      <br />
                      El pedido fue recibido por La Montaña Agromercados.
                    </p>
                  )}
                </div>
              </li>
              <li className={`timeline-item ${!onEnlistmentStatus ? 'off' : ''}`}>
                <div className="timeline-icon" />
                <div className="timeline-body-m">
                  <div className="timeline-lead">
                    <h3 className={`timeline-title ${onEnlistmentStatus ? 'timeline-subtitle' : '' }`}>{onEnlistmentStatus?`${separatedAtDate} - En alistamiento`:'En alistamiento'}</h3>
                  </div>
                  {onEnlistmentStatus && (
                    <p className="color-gray bottom-0">
                      {/* <div className="timeline-subtitle">En alistamiento</div> */}
                      <br />
                      La Montaña Agromercados esta alistando tu mercado.
                    </p>
                  )}
                </div>
              </li>
              <li className={`timeline-item ${!onAmountToPaidStatus ? 'off' : ''}`}>
                <div className="timeline-icon" />
                <div className="timeline-body-m">
                  <div className="timeline-lead">
                    <h3 className={`timeline-title ${onAmountToPaidStatus ? 'timeline-subtitle' : '' }`}>{onAmountToPaidStatus?`${amountPaidDate} - Valor total a pagar`: 'Valor total a pagar'}</h3>
                  </div>
                  {onAmountToPaidStatus && (
                    <div>
                      <p className="color-gray bottom-0">
                      {/* <div className="timeline-subtitle">Valor total a pagar</div> */}
                        <br />
                        El valor del domicilio final a pagar es: 
                        <br/>
                        <b className="prices-traking-order">{formatCurrency(price_domicile)}</b> 
                        <br />
                        El valor final a pagar es: 
                        <br/>
                        <b className="prices-traking-order">{formatCurrency(price_total)}</b> 
                        <br />
                        
                      </p>

                      <p className="color-gray bottom-0 top-20">
                        Observación de La Montaña Agromercados: <br /> {observations_admin}
                      </p>
                      <br/>

                      <input type="text" placeholder="Respuesta del cliente" value={ client_response_state } onChange={ handleClientResponse } className="form-control" />
                      <button onClick={ handleClickToOk } className={`${isBrowser?'btn btn-success btn-sm mt-2':'button button-teal button-rounded button-xs font-15 shadow-small top-10'}`}>
                        OK
                      </button>
                      {(client_response_state === client_response && client_response != '')&&
                        <span>
                          <i className="fa fa-check text-success mx-1"></i>
                          Respuesta enviada
                        </span>
                      }
                    </div>
                  )}
                </div>
              </li>
              <li className={`timeline-item ${!onTransitStatus ? 'off' : ''}`}>
                <div className="timeline-icon" />
                <div className="timeline-body-m">
                  <div className="timeline-lead">
                    <h3 className={`timeline-title ${onTransitStatus ? 'timeline-subtitle' : '' }`}>{onTransitStatus?`${dispatchedAtDate} - Despachado`:'Despachado'}</h3>
                  </div>
                  { onTransitStatus && (
                    <div>
                      <p className="color-gray bottom-0">
                      {/* <div className="timeline-subtitle">Despachado</div> */}
                          <br />
                        Tu pedido está en camino a tu dirección.
                      </p>
                      <p className="color-gray bottom-0">
                        <b>Domiciliario:</b> {domiciliary}
                      </p>
                      <p className="color-gray bottom-0">
                        <b>Cc:</b> {domiciliary_id}
                      </p>
                    </div>
                  )}
                </div>
              </li>
              <li className={`timeline-item ${!onFinalizedStatus ? 'off' : ''}`}>
                <div className="timeline-icon" />
                <div className="timeline-body-m">
                  <div className="timeline-lead">
                    <h3 className={`timeline-title ${onFinalizedStatus ? 'timeline-subtitle' : '' }`}>Finalizado</h3>
                  </div>
                  { onFinalizedStatus && (
                    <p className="color-gray bottom-0">
                      Tu pedido ha finalizado
                    </p>
                  )}
                </div>
              </li>
            </ul>

          </Scrollbars>
}

{isMobile &&
          <div className="" style={{ width: '100%', height: '86%' }}>
            <ul id="timeline" style={{ paddingTop: '0.3rem' }}>
            {
              paymentMethod === "GLOBALPAY" ?
              <React.Fragment>
                <li className={`timeline-item ${!createPayedStatus ? 'off' : ''}`}>
                  <div className="timeline-icon" />
                  <div className="timeline-body-m">
                    <div className="timeline-lead">
                      <h3 className={`timeline-title ${createPayedStatus ? 'color-default' : '' }`}>En Proceso de pago</h3>
                    </div>
                    {createPayedStatus &&
                    <p className="color-gray bottom-0">
                      {payToProcessAtDate}
                      <br />
                      Tu orden esta siendo pagado en linea.
                    </p>
                    }
                  </div>
                </li>
                <li className={`timeline-item ${!createPayedStatus ? 'off' : ''}`}>
                  <div className="timeline-icon" />
                  <div className="timeline-body-m">
                    <div className="timeline-lead">
                      <h3 className={`timeline-title ${createPayedStatus ? 'color-default' : '' }`}>Pagado</h3>
                    </div>
                    {createPayedStatus &&
                    <p className="color-gray bottom-0">
                      <br />
                      El pedido fue enviado a La Montaña Agromercados.
                    </p>
                    }
                  </div>
                </li>
              </React.Fragment>:
              <React.Fragment>
                <li className={`timeline-item ${!createdStatus ? 'off' : ''}`}>
                  <div className="timeline-icon" />
                  <div className="timeline-body-m">
                    <div className="timeline-lead">
                      <h3 className={`timeline-title ${createdStatus ? 'color-default' : '' }`}>Creado</h3>
                    </div>
                    {createdStatus &&
                    <p className="color-gray bottom-0">
                      {createdAtDate}
                      <br />
                      El pedido fue enviado a La Montaña Agromercados.
                    </p>
                    }
                  </div>
                </li>
              </React.Fragment>
            }
              <li className={`timeline-item ${!confirmedStatus ? 'off' : ''}`}>
                <div className="timeline-icon" />
                <div className="timeline-body-m">
                  <div className="timeline-lead">
                    <h3 className={`timeline-title ${confirmedStatus ? 'color-default' : '' }`}>Recibido</h3>
                  </div>
                  {confirmedStatus && (
                    <p className="color-gray bottom-0">
                      {confirmedAtDate}
                      <br />
                      El pedido fue recibido por La Montaña Agromercados.
                    </p>
                  )}
                </div>
              </li>
              <li className={`timeline-item ${!onEnlistmentStatus ? 'off' : ''}`}>
                <div className="timeline-icon" />
                <div className="timeline-body-m">
                  <div className="timeline-lead">
                    <h3 className={`timeline-title ${onEnlistmentStatus ? 'color-default' : '' }`}>En alistamiento</h3>
                  </div>
                  {onEnlistmentStatus && (
                    <p className="color-gray bottom-0">
                      {separatedAtDate}
                      <br />
                      La Montaña Agromercados esta alistando tu mercado.
                    </p>
                  )}
                </div>
              </li>
              <li className={`timeline-item ${!onAmountToPaidStatus ? 'off' : ''}`}>
                <div className="timeline-icon" />
                <div className="timeline-body-m">
                  <div className="timeline-lead">
                    <h3 className={`timeline-title ${onAmountToPaidStatus ? 'color-default' : '' }`}>Valor total a pagar</h3>
                  </div>
                  {onAmountToPaidStatus && (
                    <div>
                      <p className="color-gray bottom-0">
                        {priceToPayedAtDate}
                        <br />
                        El valor del domicilio final a pagar es: 
                        <br/>
                        <b className="prices-traking-order">{formatCurrency(price_domicile)}</b> 
                        <br />
                        El valor final a pagar es: 
                        <br/>
                        <b className="prices-traking-order">{formatCurrency(price_total)}</b> 
                        <br />
                        
                      </p>

                      <p className="color-gray bottom-0 top-20">
                        Observación de La Montaña Agromercados: <br /> {observations_admin}
                      </p>
                      <br/>

                      <input type="text" placeholder="Respuesta del cliente" value={ client_response_state } onChange={ handleClientResponse } className="form-control" />
                      <button onClick={ handleClickToOk } className={`${isBrowser?'btn btn-success':'button button-teal button-rounded button-xs font-15 shadow-small top-10'}`}>
                          OK
                      </button>

                    </div>
                  )}
                </div>
              </li>
              <li className={`timeline-item ${!onTransitStatus ? 'off' : ''}`}>
                <div className="timeline-icon" />
                <div className="timeline-body-m">
                  <div className="timeline-lead">
                    <h3 className={`timeline-title ${onTransitStatus ? 'color-default' : '' }`}>Despachado</h3>
                  </div>
                  { onTransitStatus && (
                    <div>
                      <p className="color-gray bottom-0">
                      {dispatchedAtDate}
                          <br />
                        Tu pedido está en camino a tu dirección.
                      </p>
                      <p className="color-gray bottom-0">
                        <b>Domiciliario:</b> {domiciliary}
                      </p>
                      <p className="color-gray bottom-0">
                        <b>Cc:</b> {domiciliary_id}
                      </p>
                    </div>
                  )}
                </div>
              </li>
              <li className={`timeline-item ${!onFinalizedStatus ? 'off' : ''}`}>
                <div className="timeline-icon" />
                <div className="timeline-body-m">
                  <div className="timeline-lead">
                    <h3 className={`timeline-title ${onFinalizedStatus ? 'color-default' : '' }`}>Finalizado</h3>
                  </div>
                  { onFinalizedStatus && (
                    <p className="color-gray bottom-0">
                      Tu pedido ha finalizado
                    </p>
                  )}
                </div>
              </li>
            </ul>

          </div>
}

        </div>
        <div className="sidebar-widget left-10 right-10">
          <p style={{marginBottom: '0.625rem'}}>
            Contactanos a nuestro whatsapp si tienes alguna duda o inquietud {city === "medellin"? "+57 310-832-3232": "+57 321-787-7277"} </p>
          {/* {!onTransitStatus && (
            <Button disabled={onTransitStatus} className="btn btn-danger btn-cancel btn-block button bottom-15 button-full button-rounded shadow-small button-red" onClick={handleCancelOrderClick}>
              Cancelar Pedido
            </Button>
          )} */}
          {loading &&
            <Loader title="Generando link de pago." />
          }
          {(cancel_description && !createPayedStatus )&&
            <Alert variant="filled" severity="warning" className="bottom-15"> 
              {cancel_description}
            </Alert>
          }
          {!onTransitStatus && 
            status !== undefined&&
              !errorStatus&&
              cancelStatus || declinedStatus ?
                <React.Fragment>
                  <Alert variant="filled" severity="warning" className="mb-1"> {declinedStatus? "El pago fue declinado":"La orden fue cancelado desde el Administrador"}</Alert>
                  <Button className="btn btn-cancel btn-block button bottom-15 button-full button-rounded shadow-small button-red" onClick={handleCancelClick}>
                    Volver
                  </Button>
                </React.Fragment>
                :
                paymentMethod.includes("GLOBALPAY") ?
                createPayedStatus?
                  <div>
                    <Alert variant="filled" severity="warning" className="mb-1">Para cancelar una orden paga en linea, porfavor comuniquese con nosotros.</Alert>
                  </div>
                  :
                  <React.Fragment>
                    <Button disabled={onTransitStatus} className={`${isBrowser?'btn btn-danger btn-block mb-3':'btn btn-cancel btn-block button bottom-15 button-full button-rounded shadow-small button-red'}`} onClick={handleCancelOrderClick}>
                      Cancelar Pedido
                    </Button>
                    {(cancel_description && !createPayedStatus )&&
                      <Button variant="outline-dark" className={`${isBrowser?'btn btn-outline-dark btn-block mb-3':'btn btn-cancel btn-block button bottom-15 button-full button-rounded shadow-small button-dark'}`} onClick={handleReSendLinkToPay}>
                        Intentar nuevo pago
                      </Button>
                    }
                  </React.Fragment>
                :
                createdStatus&&
                  <Button className={`${isBrowser?'btn btn-danger btn-block mb-3':'btn btn-cancel btn-block button bottom-15 button-full button-rounded shadow-small button-red'}`}  onClick={handleCancelOrderClick}>
                    Cancelar Pedido
                  </Button>
          }
        </div>
      </div>
    </div>
  );
};

export default TrackingOrder;
/*
setear el pago correcto desde el admin
*/