import React from "react";
import { useHistory } from "react-router-dom";
import ToolbarMobile from "components/layout/components/mobile/ToolbarMobile";
import Image from 'components/image/Image';
import { useDetailedProductBehavior } from 'hooks';
import { capitalizeString, formatCurrency, parseFloatWithComma, getPrice, getPum, formatCurrencyPUM } from 'utils/format';

import { Tabs, Tab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

const useStyles = makeStyles({
  root: {
    color: "#388E3C"
  },
  indicator: {
    Color: "#388E3C",
    '&:hover': {
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    }
  },
});

const ProductDetailMobile = ({ product }) => {

  const history = useHistory();
  
  const currentCity = useSelector((state) => (state.cities.city));

  const {
    comments,
    count,
    handleChangeComments,
    handleClick,
    handleUnityTypeChange,
    handleSubmit,
    shouldDisplayDiscountData,
    handleMaturity,
    maturity,
    unityType,
    unityString,
    shouldRenderMatureSelector,
    shouldRenderMeetFruverSelector
  } = useDetailedProductBehavior(product, history.goBack);
  
  product.unit_gram = product.unit_gram === undefined? 0:product.unit_gram;
  const pum = getPum(currentCity,product);
  product.pum = pum;
  
  const {
    unit_gram,
    id_item,
    discount,
    price,
    //price_neto,
    //pum,
    title,
    unit_measurement_pum,
    unit_measurement_full,
    sub_category_id
  } = product;
  let price_city = getPrice(currentCity, product);
  const classes = useStyles();

  return (
    <>
      <ToolbarMobile isBack={true} product={product} showCart={true} />
      <div>
        <Image id={id_item} className="page-content h-fixed product product-full-detail" />
        {shouldDisplayDiscountData && (<div className="label-offer-product">-{discount}%</div>)}
        <div className="page-login page-login-full bottom-20 top-10">
          <div className="store-product">
            <h2 className="regular top-0 bottom-10 font-25">{capitalizeString(title)}</h2>
            <div>
              <div className="one-half">
                <p className="bottom-10 font-16 color-gray">SKU: {id_item}</p>
              </div>
              <div className="one-half last-column">
                <p className="bottom-0 color-green font-18" align="right">
                  <b>{formatCurrency(price_city)}</b>
                  {shouldDisplayDiscountData && (
                    <span className="left-10 color-red old-price font-18">{formatCurrency(price)}</span>
                  )}
                </p>
              </div>
            </div>
            <p className="font-13 top-40">
              <span className="right-15">
                {capitalizeString(String(unit_measurement_pum))} a $ { formatCurrencyPUM( parseFloatWithComma( pum ) )}
              </span>
              {shouldDisplayDiscountData && (
                <span>
                  <i className="fa fa-tag color-green" /> Ahorra {formatCurrency(price - price_city)}
                </span>
              )}
            </p>
          </div>
          {shouldRenderMatureSelector && (
            <>
              <p className="bottom-20 color-gray font-14">Madurez de tu producto</p>
              <div className="content bottom-20">
                <div>
                  <div className="checkboxes-demo">
                    <div className="fac fac-radio-full fac-green col-4"><span></span>
                      <input id="box1-fac-radio" type="radio" name="rad" value="0" onChange={handleMaturity} checked={maturity === "0"} />
                      <label htmlFor="box1-fac-radio">Verde</label>
                    </div>
                    <div className="fac fac-radio-full fac-green col-4"><span />
                      <input id="box2-fac-radio" type="radio" name="rad" value="1" onChange={handleMaturity} checked={maturity === "1"} />
                      <label htmlFor="box2-fac-radio">Normal</label>
                    </div>
                    <div className="fac fac-radio-full fac-green col-4"><span></span>
                      <input id="box3-fac-radio" type="radio" name="rad" value="2" onChange={handleMaturity} checked={maturity === "2"} />
                      <label htmlFor="box3-fac-radio">Maduro</label>
                    </div>
                  </div>
                </div>
                <div className="clear" />
              </div>
            </>)}
          <div className="page-login-field top-20 bottom-20">
            <input type="text" onChange={handleChangeComments} placeholder="Observación o comentario (opcional)" value={comments} />
          </div>
          {
            (
              (shouldRenderMatureSelector && unit_measurement_full !== 'UND')
              ||
              (unit_measurement_full !== 'UND' && (
                sub_category_id === 'POLLO' || sub_category_id === 'PESCADOS Y MARISCOS'
              ))
            )
            &&
            <div className="row top-10 color-green justify-content-center align-center font-12">
              <span>* El peso promedio de la unidad este producto es de {unit_gram} gramos.</span>
            </div>
          }
          <div className="row top-20 ">
            <div className="col-6 full-width">
              <Tabs
                className={classes.root}
                value={unityType}
                onChange={handleUnityTypeChange}
                aria-label="unity type tabs"
                variant="fullWidth"
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#068442"
                  }
                }}>
                <Tab label="Unidad" disabled={sub_category_id === 'RES Y CERDO' && unit_measurement_full !== 'UND'} className={classes.indicator} />
                {shouldRenderMeetFruverSelector && (
                  <Tab label="Peso" disabled={unit_measurement_full === 'UND'} className={classes.indicator} />
                )}
              </Tabs>
              <div className="top-20 bottom-20 align-center">
                <div className="input-group full-width no-margin">
                  <input type="button" value="-" className="button-minus" data-field="quantity" onClick={() => handleClick('minus')} />
                  <input type="number" step="1" max="" value={count} name="quantity" className="quantity-field" readOnly />
                  <input type="button" value="+" className="button-plus" data-field="quantity" onClick={() => handleClick('plus')} />
                </div>
                {unityString}
              </div>
            </div>
          </div>
          <button onClick={() => { handleSubmit(false) }}
            className="button bottom-15 button-full button-rounded shadow-small button-green">
            {
              (
                (shouldRenderMatureSelector && unit_measurement_full !== 'UND')
                ||
                (unit_measurement_full !== 'UND' && (
                  sub_category_id === 'POLLO' || sub_category_id === 'PESCADOS Y MARISCOS'
                ))
              )
                && unityType === 0 ?
                `Añadir al carrito ${formatCurrency(unityType === 0 ? (parseFloatWithComma(pum)  * unit_gram)* count : parseFloatWithComma(pum) * count)}` 
                          :
                          `Añadir al carrito ${formatCurrency(unityType === 1 ? parseFloatWithComma(pum) * count : price_city * count )}`
            }
          </button>
          <div className="decoration top-10" />
        </div>
      </div>
    </>
  );
}



export default ProductDetailMobile;
