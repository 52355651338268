import React from "react";
import { Dropdown } from "react-bootstrap";
import { options } from "utils/const";
import FilterListIcon from "@material-ui/icons/FilterList";
import Select from "react-select";

function FilterList({ handleSelect, handleChange, isBrowser, selectedOption }) {
  return (
    <>
      {isBrowser ? (
        <div
          style={{
            textAlign: "right",
            marginBottom: "-50px",
            marginTop: "-30px",
          }}
        >
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              <FilterListIcon />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {options.map((option, idx) => (
                <Dropdown.Item
                  onSelect={handleSelect}
                  eventKey={option.value}
                  key={idx}
                >
                  {option.label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ) : (
        <Select
          value={selectedOption}
          placeholder="Selecciona filtro"
          options={options}
          onChange={handleChange}
        />
      )}
    </>
  );
}

export default FilterList;
