import React from "react";
import { isBrowser } from 'react-device-detect';

import FooterDesktop from "./desktop/Footer";
import FooterMobile from "./mobile/Footer";

const Footer = () => {
  const Footer = isBrowser ? FooterDesktop : FooterMobile;

  return (
    <Footer />
  );
};

export default Footer;
