import React from 'react';
import {isBrowser, isMobile } from 'react-device-detect';
import '../../styles/mobile/framework-store.css';
import FavoritesDesktop from "./FavoritesDesktop";
import FavoritesMobile from "./FavoritesMobile";

class Favorites extends React.Component {
    render() {

        if (isBrowser) {
            return <FavoritesDesktop/>;
        }

        if (isMobile) {
            return <FavoritesMobile/>;
        }
    }
}

export default Favorites;
