import React from "react";
import { useSelector } from 'react-redux';
import { ShoppingCart } from 'components/shoppingCart';
import { DeliveryDetails } from 'components/deliveryDetails';
import { TrackingOrder } from 'components/trackingOrder';

const AVAILABLE_ORDER_STAGES = [ShoppingCart, DeliveryDetails, TrackingOrder];

const RightSideBarDesktop = ({ isRightSidebarOpen, toggleRightSidebar }) => {
  const orderStage = useSelector((state) => (state.shoppingCart.ui.orderStage));
  const CurrentOrderStage = AVAILABLE_ORDER_STAGES[orderStage];

  return (
    <aside id="sidebar-right" className="sidebar-right">
      <CurrentOrderStage isRightSidebarOpen={isRightSidebarOpen} toggleRightSidebar={toggleRightSidebar}/>
    </aside>
  );
};

export default RightSideBarDesktop;
