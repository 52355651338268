import React from 'react';
import '../../styles/mobile/framework-store.css';
import {Link} from "react-router-dom";
import icon_email from "../../../images/figu-email.png";

class AccountCreatedMobile extends React.Component {
    render() {
        return (
            <React.Fragment>

                <div className="page-content page-content-full">

                    <Link
                        className="get-started-custom shadow-large back-button button button-rounded button-full button-green font-15"
                        to="/ingreso">
                        Aceptar
                    </Link>

                    <div className="cover-item">
                        <div className="cover-content cover-content-center">
                            <img className="cover-icon" src={icon_email} alt="icon_email"/>
                            <h2 className="regular center-text bottom-20 bold font-30">Revisa tu email</h2>
                            <p className="center-text bottom-30">Cuenta creada con éxito. Confirma tu<br/>dirección
                                de correo en el enlace que<br/>enviamos a tu bandeja de entrada.</p>
                        </div>
                        <div className="cover-overlay overlay"/>
                    </div>

                </div>

            </React.Fragment>
        );
    }
}

export default AccountCreatedMobile;
