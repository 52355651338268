import moment from 'moment';
import { useSelector } from 'react-redux';
import { currentUserIdSelector } from 'selectors';

export const useDateValidation = () => {
  //validate current time to display advice
  const currentTime = moment();
  const monday = moment().startOf('isoWeek').add(1,'week');
  const saturday = moment().startOf('isoWeek').add(6,'week');
  const startTime = moment().startOf('date').add(8,'hours');
  const endTime = moment().endOf('date').subtract(8,'hours');
  const isStoreClosed = !currentTime.isBetween(startTime, endTime) && !currentTime.isBetween(monday, saturday, 'isoWeek');
  const deliveryDate = currentTime.isBetween(endTime, moment().endOf('date'))
    ? currentTime.add(1, 'days').format('dddd D [de] MMMM')
    : currentTime.format('dddd D [de] MMMM');

  return { isStoreClosed, deliveryDate };
};

export const useLoggedUserStatus = () => {
  const loggedUserId = useSelector(currentUserIdSelector);

  return loggedUserId !== undefined;
};
