import React, { useState, useEffect } from "react";
import "date-fns";
import esLocale from "date-fns/locale/es";
import { useDispatch, useSelector } from "react-redux";
import {
  createOrderRequest,
  decrementOrderStep,
  updateDeliveryAndPaymentInfo,
  updateDeliveryDetail,
  updateStatesOrder,
} from "store/domains/shoppingCart/shoppingCartActions";
import { useDeliveryDetailsBehavior, useShoppingCartValues } from "hooks";
import { NavLink } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import { userSelector } from "selectors";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { stores, deliveryTimes } from "../../utils/const";
import { Loading } from "components/common/Loading";
import {
  updateStatusOrder,
  updateCreateAt,
  clearCurrentOrder,
  createLinkToPay,
} from "store/domains/orders/ordersActions";
import logoApp from "images/logo-splash.png";

const DeliveryDetails = ({ toggleRightSidebar }) => {
  // selectors:
  const user = useSelector(userSelector);
  const status = useSelector((state) => state.orders.currentOrder);
  // react router dom: useHistory:
  const history = useHistory();
  const [message, setMessage] = useState("Confirmar");

  const dispatch = useDispatch();
  const {
    referenceCode,
    addresses,
    confirmButtonClassnames,
    deliveryAddress,
    deliveryDate,
    isStoreClosed,
    paymentMethod,
    handleDeliveryAddressChange,
    handlePaymentMethodChange,
    handlePickupStoreChange,
    handleDeliveryTimeChange,
    handleDeliveryTypeChange,
    shouldDisableConfirmButton,
    deliveryNeighborhood,
    deliveryZone,
    deliveryCity,
    pickupStore,
    deliveryTime,
    deliveryType,
  } = useDeliveryDetailsBehavior();
  const handleBackClick = () => {
    dispatch(decrementOrderStep());
  };
  const [confirmState, setConfirmState] = useState(false);
  const [hour, setHour] = useState(new Date());
  const [hourList, setHourList] = useState([]);

  useEffect(() => {
    let MounthNow = hour.getMonth() + 1;
    let MountSelect = deliveryTime.date.getMonth() + 1;
    let DateNow = hour.getDate() + "/" + MounthNow;
    let DateSelect = deliveryTime.date.getDate() + "/" + MountSelect;
    let hourListTemp = [];
    let hourInt = parseInt(hour.getHours() + "" + hour.getMinutes()) + 100;
    let deliveryTimeTemp = 0;

    DateNow.includes(DateSelect)
      ? deliveryTimes.map((hourTemp) => {
        deliveryTimeTemp > 1200
          ? (deliveryTimeTemp =
            parseInt(
              "0" +
              hourTemp.split(" ")[0].split(":")[0] +
              hourTemp.split(" ")[0].split(":")[1]
            ) + 1200)
          : (deliveryTimeTemp = parseInt(
            hourTemp.split(" ")[0].split(":")[0] +
            hourTemp.split(" ")[0].split(":")[1]
          ));

        if (deliveryTimeTemp > hourInt) {
          hourListTemp.push(hourTemp);
        }
        setHourList(hourListTemp);
      })
      : setHourList(deliveryTimes);
  }, [deliveryTime]);

  useEffect(() => {
    !deliveryTime.time.includes("default")
      ? 1 <= deliveryTime.date.getDay() &&
        deliveryTime.date.getDay() <= 5 &&
        deliveryTime.time.split(" ")[1].includes("PM")
        ? parseInt(
          deliveryTime.time.split(" ")[0].split(":")[0] +
          deliveryTime.time.split(" ")[0].split(":")[1]
        ) > 400
          ? setMessage(
            "Su pedido Sera entregado el dia de mañana en el transcurso del dia"
          )
          : setMessage("Confirmar")
        : deliveryTime.date.getDay() === 6 &&
          deliveryTime.time.split(" ")[1].includes("PM")
          ? parseInt(
            deliveryTime.time.split(" ")[0].split(":")[0] +
            deliveryTime.time.split(" ")[0].split(":")[1]
          ) > 400
            ? setMessage(
              "Su pedido sera entregado el dia Lunes en el transcurso del dia"
            )
            : setMessage("Confirmar")
          : deliveryTime.date.getDay() === 0
            ? setMessage(
              "Su pedido sera entregado el dia Lunes en el transcurso del dia"
            )
            : setMessage("Confirmar")
      : setMessage("Confirmar");
  }, [deliveryTime.time]);

  const handleConfirmClick = () => {
    if (!shouldDisableConfirmButton) {
      if (user.phone) {
        if (status) {
          dispatch(clearCurrentOrder());
        }
        dispatch(updateStatusOrder("CREADO"));
        dispatch(updateCreateAt(new Date().toISOString()));
        handleStatusChange("CREADO");
        setConfirmState(true);
        const dateTimeOfDelivery = `${deliveryTime.date.toLocaleDateString()} ${deliveryTime.time
          }`;
        console.log(
          "deliveryAddress: " +
          deliveryAddress +
          " paymentMethod: " +
          paymentMethod +
          " deliveryNeighborhood: " +
          deliveryNeighborhood +
          " deliveryZone: " +
          deliveryZone +
          " deliveryType: " +
          deliveryType +
          " dateTimeOfDelivery: " +
          dateTimeOfDelivery +
          " deliveryCity: " +
          deliveryCity
        );
        dispatch(
          updateDeliveryAndPaymentInfo({
            deliveryAddress,
            paymentMethod,
            deliveryNeighborhood,
            deliveryZone,
            deliveryType,
            deliveryTime: dateTimeOfDelivery,
            deliveryCity,
          })
        );
        dispatch(createOrderRequest());
      } else {
        close();
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Debe registrar su teléfono para continuar con su orden",
        }).then((result) => {
          history.push("/perfil");
        });
        console.log(
          "Error: Debe registrar su teléfono para continuar con su orden"
        );
      }
    }
  };
  const close = () => {
    toggleRightSidebar();
  };

  const handleStatusChange = (status) => {
    let changeStatusDate = "";
    let statusDates = [];
    if (new Date().getMinutes() < 10) {
      changeStatusDate =
        new Date().getDate() +
        "/" +
        (new Date().getMonth() + 1) +
        "/" +
        new Date().getFullYear() +
        " " +
        new Date().getHours() +
        ":" +
        ("0" + new Date().getMinutes());
    } else {
      changeStatusDate =
        new Date().getDate() +
        "/" +
        (new Date().getMonth() + 1) +
        "/" +
        new Date().getFullYear() +
        " " +
        new Date().getHours() +
        ":" +
        new Date().getMinutes();
    }
    if (status === "CREADO") {
      statusDates.push({
        CREADO: changeStatusDate,
      });
    }
    if (status === "PAGO EN PROCESO") {
      statusDates.push({
        PAGO_EN_PROCESO: changeStatusDate,
      });
    }

    dispatch(updateStatesOrder(statusDates));
  };

  const { totalValue } = useShoppingCartValues();

  const handleclic = () => {
    const dateTimeOfDelivery = `${deliveryTime.date.toLocaleDateString()} ${deliveryTime.time
      }`;
    dispatch(
      updateDeliveryDetail({
        deliveryAddress,
        paymentMethod,
        deliveryNeighborhood,
        deliveryZone,
        deliveryType,
        deliveryTime: dateTimeOfDelivery,
        deliveryCity,
        totalValue,
      })
    );
  };

  const handleClicLine = () => {
    if (status) {
      dispatch(clearCurrentOrder());
    }
    handleStatusChange("PAGO EN PROCESO");
    dispatch(updateStatusOrder("PAGO EN PROCESO"));
    dispatch(updateCreateAt(new Date().toISOString()));
    setConfirmState(true);
    const dateTimeOfDelivery = `${deliveryTime.date.toLocaleDateString()} ${deliveryTime.time
      }`;
    dispatch(
      updateDeliveryAndPaymentInfo({
        deliveryAddress,
        paymentMethod,
        deliveryNeighborhood,
        deliveryZone,
        deliveryType,
        deliveryTime: dateTimeOfDelivery,
        deliveryCity,
      })
    );
    dispatch(createOrderRequest());
    // establecer el envio del pago en línea
    dispatch(createLinkToPay(true));
  };
  return (
    <div className="sidebar-right-wrapper">
      <div className="sidebar-widget">
        <div className="row align-items-center">
          <div className="col">
            <h4 className="color-default" onClick={handleBackClick}>
              <p className="color-default" style={{ cursor: "pointer" }}>
                <i className="fas fa-arrow-left" />
              </p>
            </h4>
          </div>
          <img
            className="col"
            src={logoApp}
            alt="La Montaña Agromercados"
            style={{ width: "25%", marginLeft: "19%" }}
          />
          <div className="col-md-12">
            <h3 className="color-default top-0">Detalles de entrega</h3>
          </div>
        </div>
      </div>

      <div className="sidebar-widget">
        {isStoreClosed && (
          <div className="alert alert-danger" style={{ marginBottom: "5px" }}>
            Actualmente nuestra tienda está cerrada. Nuesto hoario de atención
            es de lunes a viernes de 8am a 4pm Tu pedido llegará aproximadamente
            el {deliveryDate} en el transcurso del día.
          </div>
        )}
      </div>

      <h4 className="color-default top-0 lugar-margin-bottom">Lugar</h4>
      <div
        className="sidebar-widget flex-grow-2 flex-column "
        style={{ minHeight: "125px" }}
      >
        {addresses.length === 0 ? (
          <div>
            {/*
              <div className="radio-custom radio-success">
                <input onChange={handleDeliveryAddressChange} type="radio" id="radio-pickup" name="radio-address" value='pickup' />
                <label htmlFor="radio-pickup">
                  <p className="bottom-0 color-default">
                    <b>PICK UP</b> - Recoger en supermercado
                    </p>
                </label>
              </div>
              {
                deliveryAddress.includes('PICK UP') &&
                <div>
                  <select id="store-select" className="form-control" name="city-select"
                    value={pickupStore}
                    onChange={handlePickupStoreChange}>
                    {
                      stores.map((store, i) => <option key={i} value={store}>{store}</option>)
                    }
                  </select>
                </div>
              } */}

            <div className="color-gray bottom-20 align-center">
              <h3 className="color-green">
                ¡No tienes direcciones registradas!
              </h3>
              Por favor registra una dirección.
            </div>
          </div>
        ) : (
          <div className="side-bar-left" style={{overflowY: 'scroll'}}>
              {addresses.map((address, index) => (
                <div
                  key={`address-item-${index}`}
                  className="radio-custom radio-success"
                >
                  <input
                    onChange={handleDeliveryAddressChange}
                    type="radio"
                    id={`radio-address-${index}`}
                    name="radio-address"
                    value={index}
                  />
                  <label htmlFor={`radio-address-${index}`}>
                    <p className="bottom-0 color-default">
                      <span className="color-gray">
                        {address.city} - {address.alias}
                      </span>
                      <br />
                      {address.value}
                    </p>
                  </label>
                </div>
              ))}
              {/*}
              <div className="radio-custom radio-success top-10">
                <input onChange={handleDeliveryAddressChange} type="radio" id="radio-pickup" name="radio-address" value='pickup' />
                <label htmlFor="radio-pickup">
                  <p className="bottom-0 color-default">
                    <b>PICK UP</b> - Recoger en supermercado
                    </p>
                </label>
              </div>*/}
              {deliveryAddress.includes("PICK UP") && (
                <div>
                  <select
                    id="store-select"
                    className="form-control"
                    name="city-select"
                    value={pickupStore}
                    onChange={handlePickupStoreChange}
                  >
                    {stores.map((store, i) => (
                      <option key={i} value={store}>
                        {store}
                      </option>
                    ))}
                  </select>
                </div>
              )}
          </div>
        )}
      </div>

      <div>
        <NavLink
          to="/direcciones"
          className="btn btn-success btn-block"
          onClick={close}
        >
          Añadir dirección
        </NavLink>
      </div>
      <Scrollbars className="scroll-size">
        <div className="top-10 bottom-10">
          <h4 className="color-default">Escoge tu horario</h4>

          <div className="radio-custom radio-success">
            <input
              onChange={handleDeliveryTypeChange}
              type="radio"
              id="radio-express"
              name="express"
              value="EXPRESS"
            />
            <label htmlFor="radio-express">
              <p className="bottom-0 color-default">
                Horario Normal
                <br />
                <span className="color-gray">
                  Tu pedido se enviara en el trascurso del día.
                </span>
              </p>
            </label>
          </div>
          {deliveryAddress.includes("PICK UP") && (
            <div className="radio-custom radio-success">
              <input
                onChange={handleDeliveryTypeChange}
                type="radio"
                id="radio-program"
                name="express"
                value="PROGRAMADO"
              />
              <label htmlFor="radio-program">
                <p className="bottom-0 color-default">
                  Programar pedido
                  <br />
                  <span className="color-gray">
                    Selecciona la fecha y hora que quieres recoger en nuestras
                    tiendas.
                  </span>
                </p>
              </label>
            </div>
          )}
        </div>
        {deliveryType === "PROGRAMADO" && deliveryAddress.includes("PICK UP") && (
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <div className="d-flex align-items-center top-10 bottom-20 ">
              <label htmlFor="date" className="right-10">
                Fecha:
              </label>
              <DatePicker
                inputVariant="outlined"
                id="date"
                className="right-20 w-50"
                value={deliveryTime.date}
                onChange={(date) => {
                  handleDeliveryTimeChange(date, "date");
                }}
                autoOk
              />
              {hourList.length !== 0 ? (
                <React.Fragment>
                  <label htmlFor="time-select" className="right-10">
                    Hora:
                  </label>
                  <select
                    name="time-select"
                    id="time-select"
                    className="full-height"
                    value={deliveryTime.time}
                    onChange={(e) => {
                      handleDeliveryTimeChange(e.target.value, "time");
                    }}
                  >
                    <option value="default">Seleccionar</option>
                    {hourList.map((time, i) => (
                      <option key={i} value={time}>
                        {time}
                      </option>
                    ))}
                  </select>
                </React.Fragment>
              ) : (
                <label htmlFor="time-select" className="right-10">
                  No hay mas horas programadas para el dia de hoy.
                </label>
              )}
            </div>
          </MuiPickersUtilsProvider>
        )}

        <div
          className="sidebar-widget space-between"
          style={{ paddingTop: "0px" }}
        >
          <h4 className="color-default">Método de pago</h4>

          <div className="radio-custom radio-success">
            <input
              onChange={handlePaymentMethodChange}
              type="radio"
              id="radioExample5"
              name="radioExample2"
              value="EFECTIVO"
            />
            <label htmlFor="radioExample5">
              <p className="bottom-0 color-default">Efectivo</p>
            </label>
          </div>

          <div className="radio-custom radio-success">
            <input
              onChange={handlePaymentMethodChange}
              type="radio"
              id="radioExample6"
              name="radioExample2"
              value="TARJETA"
            />
            <label htmlFor="radioExample6">
              <p className="bottom-0 color-default">
                Datafono
                <br />
                <span className="color-gray">
                  Datáfono en el punto de entrega
                </span>
              </p>
            </label>
          </div>

          <div className="radio-custom radio-success">
            <input
              onChange={handlePaymentMethodChange}
              type="radio"
              id="radioExample7"
              name="radioExample2"
              value="BONOS"
            />
            <label htmlFor="radioExample7">
              <p className="bottom-0 color-default">Bonos</p>
            </label>
          </div>
          <div className="radio-custom radio-success">
            <input
              onChange={handlePaymentMethodChange}
              type="radio"
              id="radioExample8"
              name="radioExample2"
              value="GLOBALPAY"
            />
            <label htmlFor="radioExample8">
              <p className="bottom-0 color-default">Pago en lnea</p>
            </label>
          </div>
        </div>
      </Scrollbars>
      <div className="sidebar-widget">
        {paymentMethod.includes("GLOBALPAY") ? (
          deliveryAddress && deliveryType ? (
            <button
              type="button"
              className={confirmButtonClassnames}
              onClick={handleClicLine}
            >
              Pagar en línea
            </button>
          ) : (
            <span className="ml-3">
              Seleccione todos los campos obligatorios.
            </span>
          )
        ) : confirmState ? (
          <Loading caption="Creando el pedido ..." />
        ) : (
          <button
            onClick={handleConfirmClick}
            type="button"
            className={confirmButtonClassnames}
          >
            {message}
          </button>
        )}
      </div>
    </div>
  );
};

export default DeliveryDetails;
