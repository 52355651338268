import {
  call,
  put,
  select,
  takeEvery
} from 'redux-saga/effects';
import {
  createOrderRequest,
  createOrderSuccessed,
  createOrderFailed,
  incrementOrderStep,
  updateClientResponse
} from './shoppingCartActions';
import { orderDataSelector, currentOrderSelector } from 'selectors';
import { reduxSagaFirebase } from '../../rootSaga';

function* createOrder() {
  try {
    // sumarize order data
    // console.log(orderDataSelector);
    
    const orderData = yield select(orderDataSelector);

    console.log(orderData);
    // and create a CollectionReference
    const document = yield call(
      reduxSagaFirebase.firestore.addDocument,
      'orders',
      orderData
    );
    // yield call(reduxSagaFirebase.firestore.updateDocument, 'orders/'+ document.id, 'id_order',document.id );
    // add order id to retrieve progress data
    yield put(createOrderSuccessed(document.id));
    // and display order progress (in desktop mode, in Mobile go to next page)
    yield put(incrementOrderStep());
  } catch (error) {
    console.error(error);
    yield put(createOrderFailed());
  }
}

function* updateClientResponseOrder( { payload: { client_response } } ) {
  try {
    // current order id en el local storage
    const { currentOrderId } = yield select( currentOrderSelector )

    // call par ejecutar una función con argumentos
    yield call(
      reduxSagaFirebase.firestore.updateDocument,
      `/orders/${ currentOrderId }`,
      'client_response',
      client_response
    );

    // yield put( createOrderSuccessed( currentOrderId ) );

  }catch( error ) {
    yield put(createOrderFailed());
  }
}

export function* shoppingCartSaga() {
  yield takeEvery([createOrderRequest], createOrder);
  yield takeEvery( [ updateClientResponse ], updateClientResponseOrder );
}
