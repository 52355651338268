// es poner en variables para acceder más facil al local storage
export const authSelector = (state) => (state.auth);
export const userSelector = (state) => (state.firebase.profile);
export const profileSelector = (state) => (state.profile);
export const addressesSelector = (state) => (userSelector(state).addresses);
export const detailedProductSelector = (state) => (state.products);
export const detailedProduct2Selector = (state) => (state.categoryDetail.products);
export const shoppingCartSelector = (state) => (state.shoppingCart.order);
export const deliveryDetailSelector = (state) => (state.shoppingCart);
export const statusDateSelector = (state) => ({
  statusDates: state.shoppingCart.statusDates,
});
export const configSelector = (state) => (state.configs.list);
export const configDrawerSelector = (state) => (state.configs);
export const currentOrderSelector = (state) => ({
  currentOrderData: state.orders.currentOrder,
  currentOrderId: state.shoppingCart.currentOrderId
});
export const currentUserIdSelector = (state) => (state.firebase.auth.uid);
export const orderDataSelector = (state) => {

  return ({
  payToProcessAt: state.orders.status_order === "PAGO EN PROCESO" && new Date().toISOString(),
  createdAt: new Date().toISOString(),
  userId: state.firebase.auth.uid,
  name: state.firebase.profile.name,
  address: state.shoppingCart.deliveryAddress,
  zone: state.shoppingCart.deliveryZone,
  neighborhood: state.shoppingCart.deliveryNeighborhood,
  city: state.shoppingCart.deliveryCity,
  paymentMethod: state.shoppingCart.paymentMethod,
  products: state.shoppingCart.order,  

  observations_client: state.shoppingCart.observations_client,

  domiciliary: state.shoppingCart.domiciliary,
  domiciliary_id: state.shoppingCart.domiciliary_id,
  observations_admin: state.shoppingCart.observations_admin,
  client_response: state.shoppingCart.client_response,
  price_domicile: state.shoppingCart.price_domicile,
  orderObservations: state.shoppingCart.orderObservations,
  bags_count: state.shoppingCart.bags_count,
  bags_price: state.shoppingCart.bags_price,
  deliveryType: state.shoppingCart.deliveryType,
  deliveryTime: state.shoppingCart.deliveryTime,
  statusDates: state.shoppingCart.statusDates,
  status: state.orders.status_order
});

}
export const addedStatusSelector = state => state.shoppingCart.newProductAdded;
