import React from 'react';
import {Link, Redirect} from "react-router-dom";
import {isMobile} from 'react-device-detect';
import {connect} from "react-redux";
import {
    signInWithCredentials,
    signInWithFacebook,
    signInWithGoogle,
    clearAuth
} from "../store/actions/authActions";
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import {routes} from "../../../router/router";
import { clearCurrentOrder } from '../../../store/domains/orders/ordersActions';

class LoginMobile extends React.Component {  
    
    
    state = {
        email: '',
        password: ''
    };

    handleSubmit = (e) => {
        e.preventDefault();
        console.log(this.state);
        this.props.clearCurrentOrder();
        this.props.signInWithCredentials(this.state);
    };

    // Firebas social login
    handleSignInWithGoogle = (e) => {
        e.preventDefault();
        this.props.clearCurrentOrder();
        this.props.signInWithGoogle();
    }

    handleSignInWithFacebook = (e) => {
        e.preventDefault();
        this.props.clearCurrentOrder();
        this.props.signInWithFacebook();
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value,
        });
    };

    // Snackbar handle close
    handleClose = (e,reason) => {
        this.props.clearAuth();
    }


    render() {

        const {authError, auth} = this.props;
        if (auth.uid) return <Redirect to={routes.home.path}/>;

        if (isMobile) {
            return (
                <React.Fragment>

                    <div className="demo-header shadow-tiny no-border header-line-1 header-logo-app fixed">
                        <a href className="header-logo-title">Iniciar sesión</a>
                        <Link to={routes.home.path} className="header-icon header-icon-1"><i
                            className="fa fa-arrow-left"/></Link>
                    </div>

                    <div className="page-content h-fixed">

                        <div className="page-login page-login-full">

                            <h2 className="regular top-0 bottom-0 font-30">Inicia sesión o regístrate</h2>

                            <form onSubmit={this.handleSubmit}>
                                <div className="page-login-field top-50">
                                    <input type="text" placeholder="Escribe tu correo electrónico" id="email"
                                        onChange={this.handleChange}/>
                                </div>
                                <div className="page-login-field bottom-30">
                                    <input type="password" placeholder="Escribe una contraseña" id="password"
                                        onChange={this.handleChange}/>
                                </div>
                                <div className="page-login-links bottom-30">
                                    <Link className="forgot button-center" to={routes.reset_password.path}>
                                        <i className="fa fa-eye"/>¿Olvidaste tu contraseña?
                                    </Link>
                                    <div className="clear"/>
                                </div>
                                <button
                                    className="button bottom-15 button-green button-full button-rounded button-sm font-15 shadow-small">Ingresar
                                </button>
                            </form>
                            <button
                                className="button bottom-15 button-full button-rounded shadow-small font-15 bg-facebook"
                                onClick={this.handleSignInWithFacebook}>
                                <i className="fab fa-facebook-f font-15"/> Ingresa con Facebook
                            </button>
                            <button
                                className="button bottom-15 button-full button-rounded shadow-small font-15 bg-google"
                                onClick={this.handleSignInWithGoogle}>
                                <i className="fab fa-google font-15"/> Ingresa con Google
                            </button>

                            <div className="page-login-links bottom-30 top-25">
                                <p className="center-text">
                                    <Link to={routes.register.path}>
                                        <span className="color-green"><b>Registrate</b></span>
                                    </Link> a La Montaña Agromercados,<br/>y comienza a seleccionar tu mercado.
                                </p>
                                <div className="clear"/>
                                <Link to="/terminos">
                                    <p className="align-center top-20">
                                        <span className="color-green">
                                            Ver terminos y condiciones
                                        </span>
                                    </p>            
                                </Link>
                            </div>
                        </div>
                    </div>
                    <Snackbar open={authError ? true : false} autoHideDuration={6000} onClose={this.handleClose} >
                        <div className="alert">
                            <MuiAlert elevation={6} variant="filled" severity="error" onClose={this.handleClose}>
                                {authError}
                            </MuiAlert>
                        </div>
                    </Snackbar>
                </React.Fragment>
            );
        }

        return (
            <div>Esto es la web</div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.authError,
        auth: state.firebase.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        clearCurrentOrder: () => dispatch(clearCurrentOrder()),
        signInWithCredentials: (creds) => dispatch(signInWithCredentials(creds)),
        signInWithGoogle: () => dispatch(signInWithGoogle()),
        signInWithFacebook: () => dispatch(signInWithFacebook()),
        clearAuth: () => dispatch(clearAuth())

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginMobile);
