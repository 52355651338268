import React, { useState } from "react";
import PropTypes from "prop-types";
import ToolbarMobile from "../../../components/layout/components/mobile/ToolbarMobile";
import { useDispatch, useSelector } from 'react-redux';
import { addNewProfileRequest, deleteProfileRequest } from 'store/domains/user/userActions';
import { useHistory } from "react-router-dom";
import { signOut } from "pages/auth/store/actions/authActions";
import avatar from "../../../images/public_avatar.png";
import Swal from 'sweetalert2'

const ProfileMobile = ({ profile }) => {

  const fileLabelRef = React.createRef();
  const fileNameRef = React.createRef();
  const [ profileFields, setProfileFields ] = useState( profile );
  const { auth } = useSelector((state) => ({
    auth: state.firebase.auth,
    loadingLogin: state.auth.loading,
  }));
  const dispatch = useDispatch();
  const history = useHistory();

  const handleFile = (event) => {
    const file = event.target.files[0];
    if ( file ) {
      console.log(event.target.files[0].name);
      const name = (+new Date()) + '-' + file.name;
      const metadata = { contentType: file.type };
      setProfileFields({
        ...profileFields,
        profilePhoto: {
          name,
          metadata,
          file
        }
      });
      fileLabelRef.current.style = 'background-color: rgba(6, 132, 66, 0.7);';
      fileNameRef.current.textContent = file.name;
    } else {
      fileLabelRef.current.style = 'background-color: rgba(0, 0, 0, 0.7);';
      fileNameRef.current.textContent = '';
    }
  }

  const handleChange = (event) => {
    setProfileFields({...profileFields, [event.target.name]: event.target.value});
    // setbuttonDisabled(false);
  }

  const handleSubmit = () => {

    if ( isLogged() ) {

        const newProfile = {
            name: profileFields.name, 
            last_name: profileFields.last_name, 
            phone: profileFields.phone,
            email: profileFields.email,
            identification_card: profileFields.identification_card,
            profilePhoto: profileFields.profilePhoto ? profileFields.profilePhoto : undefined
        }

        dispatch(addNewProfileRequest( newProfile ));
        // alert('Perfil actualizado!')
        Swal.fire({
          icon: 'success',
          title: 'Perfil actualizado!',
          showConfirmButton: false,
          timer: 4000
        })
        console.log('handleSubmit()!');

        if ( newProfile.profilePhoto ) {
          setTimeout(() => {
            history.push("/");
          }, 4000);
        }
    } else {
        // TODO: mostrar un mensaje al usuario cuando por tiempo se desloguee, y le solicite volver a logearse
        console.log('unlogged user');
    }

    
  };

  const isLogged = () => {
    // console.log(auth.uid);
    if ( auth.uid ) {
        return true;
    } else {
        return false;
    }
  }

  const handleSignOut = () => {
    dispatch(signOut());
    history.push("/");
  }

  const handleDeleteProfile = () => {
    if (isLogged()) {
      Swal.fire({
        title: '¿Desea eliminar su perfil?',
        text: "¡No podrás revertir esto!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, elimina mi perfil!',
        cancelButtonText: 'No, cancelar!',
      }).then((result) => {
        if (result.value) {
          dispatch(deleteProfileRequest(profileFields));
          console.log('Profile deleted');
          Swal.fire(
            'Eliminado!',
            'Tu perfil ha sido eliminado.',
            'success'
          )
          history.push("/");
        }
      })
      
    } else {
        console.log('Unlogged User');
    }
  }

  return (
    <>
      <ToolbarMobile isBack={true} />
      <div className="page-content h-fixed">
        <div className="page-login page-login-full bottom-20">
          <div className="profile-image">
            <div className="btn-pic-upload" ref={ fileLabelRef }>
              <input type="file" name="photoURL" id="photoURL" className="inputfile" onChange={ handleFile } />
              <label htmlFor="photoURL">
                <span>
                  <i className="fa fa-camera" />
                </span>
              </label>
            </div>
            <div className="profile-image-box">
              <img src={ profileFields.photoURL || avatar } alt="profileAvatar" />
            </div>
          </div>

          <p style={{textAlign: 'center'}} ref={ fileNameRef } ></p>
  
          <form 
            onSubmit={e => {
              e.preventDefault();
              handleSubmit();
            }}

            // action="cuenta-creada.html"
          >
            <div className="page-login-field top-15">
              <input 
                name="name" 
                type="text" 
                placeholder="Nombre" 
                value={ profileFields.name } 
                onChange={ handleChange } 
              />
            </div>
            <div className="page-login-field top-15">
              <input
                name="last_name"
                type="text"
                placeholder="Apellido"
                value={ profileFields.last_name }
                onChange={ handleChange }
              />
            </div>
            <div className="page-login-field">
              <input
                name="email"
                type="text"
                placeholder="Correo electrónico"
                value={ profileFields.email }
                onChange={ handleChange }
              />
            </div>
            <div className="page-login-field bottom-10">
              <input 
                name="phone"
                type="text" 
                placeholder="Teléfono" 
                value={ profileFields.phone } 
                onChange={ handleChange }
              />
            </div>
            <div className="page-login-field bottom-10">
              <input 
                name="identification_card"
                type="number" 
                placeholder="cedula" 
                value={ profileFields.identification_card } 
                onChange={ handleChange }
              />
            </div>
            <input
              type="submit"
              className="button button-green button-full button-rounded button-sm font-15 "
              value="Guardar Cambios"
            />
  
          </form>

          <button
            onClick={ handleSignOut }
            className="button button-red button-full button-rounded button-sm font-15"
          >
            Cerrar sesión
          </button>

          <button
            onClick={ handleDeleteProfile }
            className="button button-white button-full button-rounded button-sm font-15"
          >
            Borrar cuenta
          </button>
        </div>
      </div>
    </>
  );

}


ProfileMobile.propTypes = {
  profile: PropTypes.object
};

ProfileMobile.defaultProps = {
  profile: {}
};

export default ProfileMobile;
