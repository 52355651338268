import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Scrollbars } from "react-custom-scrollbars";
import ShoppingCartItem from 'components/item/ShoppingCartItem';
import { incrementOrderStep, updateObservationsClient } from 'store/domains/shoppingCart/shoppingCartActions';
import { useShoppingCartValues, useLoggedUserStatus } from 'hooks';
import { formatCurrency } from 'utils/format';
import AuthModal from "pages/auth/desktop/AuthModal";
import {
  handleClose,
  handleShow,
} from "pages/auth/store/actions/authActions";
import styles from './ShoppingCart.module.css';
import logoApp from "images/logo-splash.png";
import { configSelector } from 'selectors';

const ShoppingCart = ({ isRightSidebarOpen, toggleRightSidebar }) => {
  const configs = useSelector(configSelector);
  const [valminM, setValminM] = useState(0);
  const [valminC, setValminC] = useState(0);
  const currentCity = useSelector((state) => (state.cities.city));


  useEffect(() => {
      if(configs[3]){
        setValminM(configs[3].valuemin_medellin);
        setValminC(configs[3].valuemin_cali);
      }
  }, [configs])

  const formatterDolar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0
  });

  const dispatch = useDispatch();
  const {
    deliveryCost,
    order,
    subtotalValue,
    totalValue,
    itemCount,
    gramCount,
    handleBagsCount
  } = useShoppingCartValues();
  const gramTotal = () => {
    const gramTotal= gramCount === 0 ? 0 : gramCount;
    return gramTotal
  }

  const ItemTotal = () => {
    const ItemTotal= itemCount === 0 ? 0 : itemCount;
    return ItemTotal
  }
  const TotalBags = () => {
    handleBagsCount(ItemTotal() + gramTotal(), configs[0].bag_price);
    return ItemTotal() + gramTotal()
  }

  const isUserLogged = useLoggedUserStatus();
  const {
    showModal
  } = useSelector((state) => ({
    showModal: state.auth.showModal
  }));
  const [showLogin, setShowLogin] = useState(true);
  const handleCloseEvent = () => {
    dispatch(handleClose());
  };
  const handleChangeAlert = () => {
    setShowLogin(!showLogin);
  };
  const [disclaimer, setDisclaimer] = useState(false);
  const handleClickToFinishOrder = () => {
    if(checkShoppingCart()){
      return false;
    }
    if (isUserLogged) {
      if (subtotalValue > 0) {
        dispatch(incrementOrderStep());
        dispatch(updateObservationsClient({ observations_client }))
      }
    } else {
      dispatch(handleShow());
      // hide current panel

      console.log(isRightSidebarOpen, 'here');
      console.log(toggleRightSidebar, 'another');
      toggleRightSidebar();
    }
  };
  const checkShoppingCart = () => {
    if(currentCity === "Medellin"){
      return subtotalValue === 0 || subtotalValue < valminM
    }else{
      return subtotalValue === 0 || subtotalValue < valminC
    }
    
  }
  const confirmButtonClassnames = classnames('btn btn-success btn-block', {
    'disabled': checkShoppingCart()
  });

  const [observations_client, setObservations_client] = useState('');
  const handleObservationsClient = (event) => {
    setObservations_client(event.target.value);
  };

  useEffect(() => {
    order.forEach((item) => {
      if ((item.unit_measurement_full !== 'UND' && item.main_category_id === '0004-FRUTAS Y VERDURAS' && item.unityType === 0)
      || (item.unit_measurement_full !== 'UND' && (item.sub_category_id === 'POLLO' || item.sub_category_id === 'PESCADOS Y MARISCOS') && item.unityType === 0))  {
        setDisclaimer(true);
      }
    });
  }, [order])

  return (
    <div className={styles['main-container']}>

      <div className={styles['header']}>
        <div className={styles["header-container"]}>
          <div className={styles["header-button"]}></div>
          <div className={styles["header-title"]}>
            <h3 className={styles['header-title-h3']}>Mi canasta de compra</h3>
            <p className={styles['header-title-p']}>{order.length} productos añadidos</p>
          </div>
          <img src={logoApp} alt="La Montaña Agromercados" style={{width: '25%', marginLeft: '19%'}}/>
        </div>
      </div>

      <Scrollbars style={{ width: '100%', height: '55%' }}>
        <div className="sidebar-widget">
          {order.map((item, index) => {
            return (
            <ShoppingCartItem
              key={`shopping-cart-item-${index}`}
              index={index}
              item={item}
              setDisclaimer={setDisclaimer}
              disclaimer={disclaimer}
              currentCity={currentCity}
            />
            )
          })}
        </div>
      </Scrollbars>

      <div className={styles['footer']}>

        {/* {disclaimer &&
          <div className="alert alert-success">
            <b>*</b> Su valor sera calculado al momento de alistar su pedido ya que cada unidad puede tener diferente peso, o si lo prefiere lo puede pedir por la opcion de peso en gramos.
          </div>
        } */}
        {
          currentCity === 'Medellin'?
          (subtotalValue < valminM &&
          <div className="alert alert-warning">
            El valor del pedido debe ser minimo de <strong>{formatterDolar.format(valminM)}</strong>
          </div>)
          :
          (subtotalValue < valminC &&
          <div className="alert alert-warning">
            El valor del pedido debe ser minimo de <strong>{formatterDolar.format(valminC)}</strong>
          </div>)
        }

        <div className="alert alert-danger">
          Al finalizar el pedido serás contactado por un representante para
          confirmar tu pedido e informarte el precio final.
        </div>

        <div>
          <textarea onChange={handleObservationsClient} value={observations_client} className={styles['textarea']} placeholder="¿No está tu producto?, dinos que necesitas"></textarea>
        </div>

        <hr className={styles['hr']} />

        <div className={styles['footer-b']}>

          <div className={styles['footer-b-col']}>
            <p className="color-gray bottom-0">Subtotal: {formatCurrency(subtotalValue)}</p>
            <p className="color-gray bottom-0">Total Valor por bolsas: { formatCurrency(TotalBags()*configs[0].bag_price) } ({TotalBags()} Bolsas)</p>
            <p className="color-gray bottom-0">Domicilio mínimo desde: {formatCurrency(deliveryCost)}</p>
            <p className={styles['total']}>Total aproximado: {formatCurrency(totalValue)}</p>
          </div>

          <div className={styles['footer-b-button-container']}>
            <div className={styles['footer-b-button']}>
            <button onClick={handleClickToFinishOrder} type="button" className={confirmButtonClassnames} style={{ borderRadius: '20px' }}>
              CONFIRMAR
            </button>
            </div>
          </div>

        </div>

      </div>

      <AuthModal
        show={showModal}
        handleClose={handleCloseEvent}
        shouldDisplayLoginForm={showLogin}
        handleChangeAlert={handleChangeAlert}
      />

    </div>
  );
};

ShoppingCart.propTypes = {
  isRightSidebarOpen: PropTypes.bool,
  toggleRightSidebar: PropTypes.func
};

ShoppingCart.defaultProps = {
  isRightSidebarOpen: false,
  toggleRightSidebar: () => { }
};

export default ShoppingCart;
