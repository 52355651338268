import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import {
    forgotPassword,
    clearAuth
} from "../../auth/store/actions/authActions";
import { Loading } from 'components/common/Loading';

const ResetPasswordMobile = ({ forgotPassword, clearAuth, authError, loadingLogin, forgotPwSended }) => {

    const [email, setEmail] = useState('');
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        forgotPassword(email);
    }

    useEffect(() => {
        clearAuth();
    }, [clearAuth])

    return (
        <React.Fragment>
            <div className="page-content h-fixed">
                <div className="page-login page-login-full">
                    <h2 className="regular top-30 bottom-0 font-30 bottom-30">Recupera tu cuenta</h2>

                    {
                        forgotPwSended ?
                            <div className="align-center bottom-30 page-login-links">
                                <i className="far fa-check-circle color-green"></i>
                                <span> Se ha enviado exitosamente el enlace de recuperación al correo {email}</span>
                            </div>
                            :
                            <div className="page-login-links bottom-30">
                                <p className="">Escribe tu correo electrónico y te enviaremos instrucciones para recuperar
                                tu cuenta.</p>
                                <div className="clear" />
                            </div>
                    }

                    <form>
                        <div className="page-login-field bottom-30">
                            <input type="text" placeholder="Escribe tu correo electrónico" id='email' onChange={handleEmailChange} value={email} />
                        </div>

                        <div className="color-red align-center bottom-20">
                            {authError && <span>{authError}</span>}
                        </div>
                        {
                            !forgotPwSended &&
                            (
                                loadingLogin ?
                                    <Loading caption={'Cargando...'} />
                                    :
                                    <Link to="/email_recuperar_enviado">
                                        <input type="submit" onClick={handleSubmit}
                                            className="button button-green button-full button-rounded button-sm font-15 shadow-small"
                                            value="Recuperar cuenta" />
                                    </Link>
                            )
                        }
                    </form>
                    <Link className="color-green top-40 align-center" to="/ingreso">
                        Volver a inicio de sesión
                        </Link>
                </div>
            </div>
        </React.Fragment>
    )
}


const mapStateToProps = (state) => {
    return {
        authError: state.auth.authError,
        loadingLogin: state.auth.loading,
        forgotPwSended: state.auth.forgotPwSended
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        forgotPassword: (email) => dispatch(forgotPassword(email)),
        clearAuth: () => dispatch(clearAuth())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordMobile);
