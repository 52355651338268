import React, { useEffect } from "react";
//import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { isBrowser, isIPad13 } from "react-device-detect";
import HomeMobile from "./HomeMobile";
import HomeDesktop from "./HomeDesktop";
//import { Snackbar } from '@material-ui/core';
//import MuiAlert from '@material-ui/lab/Alert';
import { loadCategoriesRequest } from "store/domains/category/categoryActions";
import { loadConfigsRequest } from "store/domains/config/configActions";
import { Dialog, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
//import { clearCurrentOrder } from '../../store/domains/orders/ordersActions';
//import { clearShoppingCart } from '../../store/domains/shoppingCart/shoppingCartActions';

const HomePage = () => {
  const categories = useSelector((state) => state.categories.list);
  const currentCity = useSelector((state) => state.cities.city);
  const dispatch = useDispatch();
  const HomeLayout = isBrowser && !isIPad13 ? HomeDesktop : HomeMobile;

  useEffect(() => {
    dispatch(loadCategoriesRequest());
    dispatch(loadConfigsRequest());
    window["isUpdateAvailable"].then((isAvailable) => {
      if (isAvailable) {
        if ("Notification" in window) {
          Notification.requestPermission().then(function (permission) {
            // If the user accepts, let's create a notification
            if (permission === "granted") {
              // var notification = new Notification("Tenemos una nueva versión de La Montaña Agromercadosl");
              navigator.serviceWorker.ready.then(function (registration) {
                registration.showNotification("La Montaña Agromercados", {
                  body: "Tenemos una nueva versión de La Montaña Agromercados",
                  icon: "logo512.png",
                  vibrate: [200, 100, 200, 100, 200, 100, 200],
                  tag: "app-update-montana-agromercados",
                });
              });
            }
          });
        }
        console.log("New Update");
      } else {
        console.log("No update");
      }
    });
  }, [dispatch]);

  //const [open, setOpen] = React.useState(true);
  return (
    <>
      <Dialog
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"En mantenimiento"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
              Lo sentimos Actualmente la pagina esta en mantenimiento.
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <div className={!isBrowser ? "page-content h-fixed" : ""}>
        {/* <Collapse in={open}>
        <Alert severity="info"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>Los precios de los productos solo aplican para esta plataforma y al usarla acepta los <Link to='/terminos'><b>Términos y condiciones</b></Link> </Alert>
      </Collapse> */}
        <HomeLayout categories={categories} currentCity={currentCity} />
      </div>
    </>

  );
};

export default HomePage;
