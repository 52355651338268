import { createActions, handleActions, combineActions } from 'redux-actions';

const defaultState = {
  detailedProduct: {
    id_item: '',
    main_category_id: '',
    discount: '',
    price: '',
    price_neto: '',
    pum: '',
    title: '',
    unit_measurement_pum: ''
  },
  list: {},
  listPreview: {},
  listOffer: {},
  ui: {
    isLoading: false
  }
};

const {
  loadDetailedProductRequest,
  loadDetailedProductSuccessed,
  loadDetailedProductFailed,
  loadProductsPreviewRequest,
  loadProductsPreviewSuccessed,
  loadProductPreview,
  loadProductsPreviewFailed,
  loadProductsOfferRequest,
  loadProductsOfferSuccessed,
  loadProductOffer,
  loadProductsOfferFailed,
  loadProductsRequest,
  loadProductsSuccessed,
  loadProductsFailed
} = createActions({
  LOAD_DETAILED_PRODUCT_REQUEST: (category, productId) => ({ category, productId }),
  LOAD_DETAILED_PRODUCT_SUCCESSED: (product) => ({ product }),
  LOAD_DETAILED_PRODUCT_FAILED: undefined,
  LOAD_PRODUCTS_PREVIEW_REQUEST: (category, city) => ({ category, city }),
  LOAD_PRODUCTS_PREVIEW_SUCCESSED: (category, products) => ({ category, products }),
  LOAD_PRODUCT_PREVIEW: (category, product) => ({ category, product }),
  LOAD_PRODUCTS_PREVIEW_FAILED: undefined,
  LOAD_PRODUCTS_OFFER_REQUEST: (categories, city) => ({ categories, city }),
  LOAD_PRODUCTS_OFFER_SUCCESSED: (arreglo) => ({arreglo}),
  LOAD_PRODUCT_OFFER: (category, product) => ({ category, product }),
  LOAD_PRODUCTS_OFFER_FAILED: undefined,
  LOAD_PRODUCTS_REQUEST: undefined,
  LOAD_PRODUCTS_SUCCESSED: (category, products) => ({ category, products }),
  LOAD_PRODUCTS_FAILED: undefined
});

const reducer = handleActions({
  [combineActions(
    loadDetailedProductRequest,
    loadProductsRequest,
    loadProductsPreviewRequest,
    loadProductsOfferRequest
  )]: (state) => ({
    ...state,
    ui: {
      isLoading: true
    }
  }),
  [loadDetailedProductSuccessed]: (state, { payload: { product }}) => ({
    ...state,
    detailedProduct: product,
    ui: {
      ...state.ui,
      isLoading: false
    }
  }),
  [loadProductsSuccessed]: (state, { payload: { category, products } }) => ({
    ...state,
    list: {
      ...state.list,
      [category]: products
    },
    ui: {
      isLoading: false
    }
  }),
  [loadProductsPreviewSuccessed]: ( state, { payload: { category, products }}) => ({
    ...state,
    
    listPreview: {
      ...state.listPreview,
      [category]: products
    },
    ui: {
      isLoading: false
    }
  }),
  [loadProductsOfferSuccessed]: ( state, { payload: { arreglo }}) => ({
    ...state,
    listOffer: arreglo,
    ui: {
      isLoading: false
    }
  }),
  [loadProductPreview]: ( state, { payload: { category, product }}) => {
        
    if (!(category in state.listPreview)) {

      return {
        ...state,
        listPreview: {
          ...state.listPreview,
          [category]: [product]
        }
      }      
    }

    return {
      ...state,
      listPreview: {
        ...state.listPreview,
        [category]: [
          ...state.listPreview[category],
          product
        ]
      }
    }
  },
  [combineActions(
    loadDetailedProductFailed,
    loadProductsFailed,
    loadProductsPreviewFailed,
    loadProductsOfferFailed
  )]: (state) => ({
    ...state,
    ui: {
      isLoading: false
    }
  })
}, defaultState);

export {
  defaultState,
  loadDetailedProductRequest,
  loadDetailedProductSuccessed,
  loadDetailedProductFailed,
  loadProductsPreviewRequest,
  loadProductsPreviewSuccessed,
  loadProductPreview,
  loadProductsPreviewFailed,
  loadProductsOfferRequest,
  loadProductsOfferSuccessed,
  loadProductOffer,
  loadProductsOfferFailed,
  loadProductsRequest,
  loadProductsSuccessed,
  loadProductsFailed
};

export default reducer;
