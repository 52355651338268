import React, {Component} from 'react';
import './splash-screen.css';
import logoApp from "../images/logo-splash.png";

function LoadingMessage() {
    return (
        <div className="splash-screen">
            <img src={logoApp} alt="La Montaña Agromercados"/>
        </div>
    );
}

function withSplashScreen(WrappedComponent) {
    return class extends Component {
        constructor(props) {
            super(props);
            this.state = {
                loading: true,
            };
        }

        async componentDidMount() {
            try {
                setTimeout(() => {
                    this.setState({
                        loading: false,
                    });
                }, 3000) //TIEMPO DE SPLASH
            } catch (err) {
                console.log(err);
                this.setState({
                    loading: false,
                });
            }
        }

        render() {
            // while checking user session, show "loading" message
            if (this.state.loading) return LoadingMessage();

            // otherwise, show the desired route
            return <WrappedComponent {...this.props} />;
        }
    };
}

export default withSplashScreen;
