import React from 'react';
import { isBrowser } from 'react-device-detect';
import '../../styles/mobile/framework-store.css';
import DeliveryDetailsDesktop from "./DeliveryDetailsDesktop";
import DeliveryDetailsMobile from "./DeliveryDetailsMobile";

const DeliveryDetails = () => {
	const DeliveryDetailsLayout = isBrowser ? DeliveryDetailsDesktop : DeliveryDetailsMobile;

	return (<DeliveryDetailsLayout />);
};

export default DeliveryDetails;
