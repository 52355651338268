import React from 'react';
import { isBrowser } from 'react-device-detect';
import ToolbarMobile from "components/layout/components/mobile/ToolbarMobile";
import Styles from './Terms.module.css'

export const Terms = () => {
    return (
        <React.Fragment>
            {!isBrowser && <ToolbarMobile isBack={true} showCart={false} />}
            <div className={`${Styles['content']}  container h-fixed page-content`}>
                <h1>Términos y Condiciones La Montaña Agromercados</h1>

                <p>
                    Gracias por entrar a www.lamontanaagromercados.com, en adelante (La Montaña Agromercados”),
                    cuya actividad principal es permitir la exhibición y comercialización de productos y servicios para su
                    adquisición en línea por los consumidores ubicados en la Ciudad de Medellín y Cali, Colombia, en
                    adelante (“los Clientes” o “los Usuarios”). Los clientes actualmente podrán acceder a La Montaña
                    Agromercados a través de la línea para llamada o Whatsapp (3108323232), y a través de la web,
                    inicialmente para la sucursal de Medellín (Antioquia): Bloque Naranja de la Central
                    Mayorista de Antioquia - Locales Nos. 117-118-119-140-141-142 Itagüí A. y Cali
                    (Valle del Cauca): Calle 8va #24-25, Barrio Alameda.
                </p>
                <p>
                    “La Montaña Agromercados” es controlado y operado por Migan Capital S.AS. Nit 900.872.767-6.
                    en adelante (La Montaña Agromercados), Sociedad Comercial domiciliada en la ciudad de
                    Cali, Valle del Cauca. Aquellos Usuarios que decidan ingresar desde otros países, lo harán
                    bajo su propia iniciativa y es su responsabilidad el sujetarse a las leyes locales que sean
                    aplicables. Cualquier reclamo en relación con el uso de La Montaña Agromercados y el
                    material en él contenido está regulado por las leyes colombianas.
                </p>
                <p>
                    Los Usuarios que ingresen a “La Montaña Agromercados” desde lugares diferentes a la
                    Ciudad de Medellín (Antioquia) o Cali (Valle del Cauca), podrán realizar compras
                    sujetándose a los términos y condiciones aquí contemplados, sin embargo los productos
                    solo serán entregados específicamente en la ciudad de Medellín (Antioquia), Cali (Valle del
                    Cauca), a la dirección suministrada por el usuario.
                </p>
                <p>
                    Cuando el Usuario ingresa a La Montaña Agromercados y admite haber leído y entendido
                    los Términos y Condiciones de Uso, en adelante (“Términos y Condiciones”), está
                    asumiendo la obligación de respetar su contenido, todas las cláusulas de exención de
                    responsabilidad, las notificaciones legales, y la política de privacidad que figuran en el
                    mismo.
                </p>
                <p>
                    Los presentes Términos y Condiciones están sujetos a cambios sin previo aviso, en
                    cualquier momento, y a partir de la fecha de modificación de estos Términos y Condiciones,
                    todas las operaciones que se celebren entre “La Montaña Agromercados” y el Usuario se
                    regirán por el documento modificado.
                </p>

                <h3>Inscripción del Usuario en La Montaña Agromercados, Whatsapp o vía telefónica.
                En el proceso de inscripción en La Montaña Agromercados se solicitará la siguiente
                información personal:</h3>
                <p>
                    Información de contacto, la cual incluye: nombre, número de identificación, fecha de
                    nacimiento, dirección domicilio, número de teléfono y correo electrónico. Información de
                    contacto del destinatario de tratarse de una persona distinta al Usuario.
                </p>

                <h3>Capacidad del Usuario</h3>
                <p>
                    Los servicios de “La Montaña Agromercados” están disponibles sólo para personas
                    mayores de edad que tengan capacidad legal para contratar según lo dispuesto por la
                    legislación colombiana vigente. Si el Usuario carece de dicha capacidad legal no podrá
                    realizar transacciones en La Montaña Agromercados.
                </p>

                <h3>Análisis del Usuario</h3>
                <p>
                    Cuando el Usuario ingresa a La Montaña Agromercados, adquiere el compromiso de
                    suministrar información personal correcta y verdadera para que la entrega de los productos
                    adquiridos se realice de forma efectiva. “La Montaña Agromercados” se reservará la
                    facultad de verificar los datos comunicados por cada uno de los Usuarios. De constatarse
                    una inconsistencia en la información del Usuario en cualquier momento o si omite notificar
                    algún cambio, “La Montaña Agromercados” podrá proceder con la cancelación del pedido y
                    la terminación inmediata de la cuenta del Usuario en cuestión.
                </p>

                <h3>Deberes del Usuario</h3>
                <p>
                    Se le prohíbe al Usuario transmitir o enviar desde La Montaña Agromercados cualquier
                    material ilegal, amenazador, calumniador, difamatorio, obsceno, escandaloso, pornográfico
                    y/o cualquier otro material que pudiera dar lugar a cualquier responsabilidad civil o penal en
                    los términos de la Ley. Así mismo, el Usuario acepta no utilizar ningún dispositivo, software
                    entre otros para obstruir el funcionamiento correcto de La Montaña Agromercados y de sus
                    contenidos y de las actividades realizadas en el mismo.
                </p>

                <h3>Productos</h3>
                <p>
                    “La Montaña Agromercados” ofrece a sus Usuarios principalmente productos como
                    alimentos frescos, abarrotes y otros que se encuentren en nuestros puntos de venta;
                    incluidos los productos de concesionarios.
                </p>

                <h4>Visualización de productos (App)</h4>
                <p>
                    “La Montaña Agromercados” exhibe las imágenes de productos con la mayor nitidez y
                    precisión posible. No obstante, la visualización del color de los productos dependerá del
                    monitor o móvil desde el que se acceda.
                </p>

                <h4>Disponibilidad de productos</h4>
                <p>
                    “La Montaña Agromercados” se encarga de la actualización de la página y la revisión
                    constante de los productos que se exhiben en La Montaña Agromercados. No obstante, “ La
                    Montaña Agromercados” puede modificar y descontinuar los productos en cualquier
                    momento sin contraer ninguna responsabilidad frente a los Usuarios.
                </p>

                <h4>Limitación de cantidades y restricción de pedidos</h4>
                <p>
                    “La Montaña Agromercados” puede, a su exclusivo criterio, limitar o cancelar las cantidades
                    compradas por Cliente, cuando la compra no cumpla con las condiciones y restricciones
                    informadas al momento de la compra. En tal caso “La Montaña Agromercados” rechazará
                    de plano las compras que superen el número máximo de unidades autorizadas para un (1)
                    número de identificación. Lo anterior significa que una persona podrá realizar compras, por
                    día, por el número máximo autorizado según corresponda.
                </p>

                <h3>Información para pagos</h3>
                <p>
                    Dentro de las alternativas que se contemplan en La Montaña Agromercados para el pago de
                    los productos seleccionados, “La Montaña Agromercados” Cuando el usuario realice el
                    pedido por Whatsapp, por teléfono o App, debe confirmar por cual medio va a realizar su
                    pago y tener la identificación pertinente al momento de recibir la mercancía, cuando se
                    requiera. (pago con TD, TC o bonos).
                </p>

                <h3>Perfeccionamiento y transferencia de propiedad</h3>
                <p>
                    Cuando “La Montaña Agromercados” acepte la oferta de compra presentada por el Cliente,
                    genera el perfeccionamiento del contrato.
                </p>
                <p>
                    La propiedad sobre los productos y/o servicios se transmitirá al cliente desde el momento en
                    que los mismos sean cancelados por éste en el lugar de entrega.
                </p>

                <h3>Cobros por envío e impuestos</h3>
                <p>
                    El Cliente será responsable de los cobros de envío, manejo y/o servicios que adquiera en
                    La Montaña Agromercados, así como del impuesto sobre la venta, impuesto a la bolsa y
                    demás gravámenes que se ocasionen por cada oferta de compra aceptada. Todos los
                    impuestos causados por la compra serán liquidados desde el momento en que el Cliente
                    realice su oferta de compra en La Montaña Agromercados, y por tanto podrá conocer el
                    valor exacto que debe pagar a favor de “La Montaña Agromercados” el cual incluirá todos
                    los conceptos mencionados.
                </p>
                <p>
                    El valor del domicilio (envío) será:
                </p>
                <ul>
                    <li>$5.000 para el Valle de Aburrá</li>
                    <li>$5.000 para la zona urbana de Cali.</li>
                </ul>
                <p>
                    Para las zonas no mencionadas será comunicado al cliente antes del alistamiento de los productos.
                </p>

                <h3>Entrega de los productos</h3>
                <p>
                    “La Montaña Agromercados” se compromete a entregar, en las áreas geográficas limitadas por el punto de venta inicialmente en las ciudades de Medellín (Antioquia) y Cali (Valle del Cauca).
                </p>
                <p>
                    El tiempo de entrega de los domicilios de 1 a 25 artículos es de 4 a 24 horas hábiles; para pedidos más grandes se le notificará al cliente cuál será el tiempo de alistamiento y entrega.
                </p>
                <p>
                    Se entiende que cualquier persona que se encuentre en el domicilio donde debe realizarse
                    la entrega, estará debidamente autorizada por el Cliente para recibir su pedido. Por
                    consiguiente, “La Montaña Agromercados” queda exonerado de cualquier responsabilidad
                    por la entrega que realice, siempre que la misma se haga en el domicilio registrado en La
                    Montaña Agromercados.
                </p>
                <u>Cambios y/o Devoluciones</u>
                <p>
                    “La Montaña Agromercados” procederá a aceptar las devoluciones de los productos
                    adquiridos que resultaren averiados y/o cuya deficiencia en su calidad no sea apto para el
                    consumo. “La Montaña Agromercados” brindará al cliente la opción de decidir si se
                    descuenta del pago total o se cambia el producto, caso en el cual los gastos de envío serán
                    asumidos por “La Montaña Agromercados”, o el cliente podrá dirigirse al punto de venta que
                    le despachó y realizar el cambio.
                </p>

                <h3>Procedimientos para los cambios y otros “La Montaña Agromercados”</h3>
                <p>Cambios y Devoluciones para productos de mercado</p>

                <ul>
                    <li>Cuando el cliente devuelve todo el domicilio porque no tiene dinero, o se equivocó en el pedido, correrá con el costo del domicilio que corresponde a la entrega y devolución de la mercancía.</li>
                    <li>Cuando el Cliente devuelve todo el domicilio porque los pedidos llegaron en mal estado (rotos, averías, aplastados, mojados, derretidos, etc.) el cliente no pagará y se le preguntará si autoriza que le volvamos a enviar su pedido, el cual si acepta lo cancelará con el domicilio correspondiente cuando lo reciba a satisfacción.</li>
                    <li>Cuando el cliente recibe el pedido y no le alcanza el dinero para pagar todo, puede realizar la devolución del o los productos que crea conveniente para poder pagar el pedido, y no pagará el domicilio de regreso al almacén, si dejo el pedido parcialmente.</li>
                    <li>Cuando el cliente hace devolución de algún artículo porque se equivocó y dejó el resto, no pagará el domicilio de regreso al almacén, a no ser que solicite que se le cambie por otro artículo, en este caso, asumirá el otro domicilio.</li>
                    <li>La Montaña Agromercados, prestará el servicio de domicilios a través de un tercero: el cliente tiene el derecho de informar a La Montaña Agromercados cualquier irregularidad, reclamo o inconformidad con el servicio prestado.</li>
                    <li>Si el domiciliario al momento de recibir el pago del domicilio donde el cliente, encuentra que el billete es falso, procederá a destruirlo o a marcarlo como tal y el cliente debe de reponer el valor correspondiente.</li>
                    <li>“La Montaña Agromercados” advierte a los Usuarios que la información La Montaña Agromercados puede contener errores, inexactitudes o aproximaciones no estar completa o actualizada. Por ello, “La Montaña Agromercados” se reserva el derecho de corregir cualquier error, omisión o inexactitud, cambiar o actualizar la misma en cualquier momento y sin previo aviso.</li>
                </ul>

                <u>Exención de responsabilidad y limitación de responsabilidad</u>
                <p>
                    Al ingresar a La Montaña Agromercados, el Usuario acepta en forma expresa que su uso se realiza bajo su exclusiva responsabilidad y riesgo.
                </p>
                <p>
                    Ni “La Montaña Agromercados” ni sus respectivos empleados y terceros asociados garantizan que el uso La Montaña Agromercados no sufrirá interrupciones ni contendrá errores. Bajo ninguna circunstancia “La Montaña Agromercados” ni sus relacionados serán responsables de ningún daño directo, indirecto, imprevisto, especial ni emergente que se genere, ya sea por el uso o falta de capacidad para usar La Montaña Agromercados, incluidos los daños que surjan a raíz de su confianza en la información obtenida en La Montaña Agromercados que ocasione errores, omisiones, interrupciones, eliminación o corrupción de archivos, virus, demoras en la operación o transmisión, o cualquier otro tipo de error en su funcionamiento.
                </p>
                <p>
                    Sin perjuicio de lo determinado en las normas imperativas de la legislación colombiana aplicable, “La Montaña Agromercados” no asume responsabilidad alguna ni responsabilidad derivada de cualquier daño o perjuicio, incluyendo, mas no limitado a, la pérdida de información o utilidades, existencia de virus, resultados del uso o la incapacidad para usar el material, oportunidades de negocios perdidas, o cualquier otro daño, aun cuando el Usuario haya avisado acerca de la posibilidad de tales daños, o por cualquier reclamo de terceros, salvo lo que expresamente aquí se estipula.
                </p>
                <p>
                    Bajo los términos aquí señalados, “La Montaña Agromercados” no asume ninguna responsabilidad por la información que se suministra en la página, incluyendo, pero sin limitarse a, la referente a productos y/o servicios, notas de interés, opiniones, consejos prácticos y solución de inquietudes. “La Montaña Agromercados” no asume responsabilidad alguna por problemas o por la imposibilidad de utilización La Montaña Agromercados o de alguna de las páginas que lo conforman, incluyendo pero sin limitarse a eventos tales como problemas en el servidor o en la conexión, interrupciones en su comunicación, problemas técnicos.
                </p>
                <p>
                    En caso de que el uso del material arroje como resultado la necesidad de dar servicio, reparar o corregir equipo o información, el Usuario deberá asumir cualquier costo derivado de ello. La limitación de responsabilidad precedente se aplicará en toda acción legal, aun cuando el representante autorizado de “La Montaña Agromercados” haya sido informado o tuviera previamente conocimiento de dichos daños.
                </p>

                <u>Derechos de Propiedad Intelectual</u>
                <p>
                    Todo el material informático, gráfico, publicitario, fotográfico, de multimedia, audiovisual y/o de diseño, así como todos los contenidos, textos y bases de datos (en adelante “los Contenidos”), puestos a disposición de Los Usuarios en La Montaña Agromercados son de propiedad exclusiva de MIGGO INVESTMENT SAS, o de terceros que han autorizado su uso y/o explotación.
                </p>
                <p>
                    Cualquier uso no autorizado de los contenidos constituirá una violación a los presentes términos y condiciones y a las normas vigentes sobre marcas, derechos de autor y/u otras normas de propiedad intelectual tanto nacionales e internacionales aplicables y dará lugar a las acciones civiles y penales correspondientes
                </p>

                <u>Publicidad y vínculos</u>
                <p>
                    Las organizaciones, productos y/o servicios a los cuales se les realice publicidad en La Montaña Agromercados así como los enlaces (links) no son afiliados o tienen relación alguna con “La Montaña Agromercados”, el cual no cumple ninguna función en la producción de esos productos y/o la prestación de servicios y no constituye ninguna garantía, expresa o implícita de su contenido o de su adecuación para un propósito particular. Así por ejemplo, cuando se sugiera la utilización de algún producto y/o servicio, es de exclusiva autonomía del Usuario decidir su utilización, y por lo tanto “La Montaña Agromercados” no asume ninguna responsabilidad por los perjuicios directos o indirectos, ciertos o eventuales, pasados, presentes o futuros, que se llegaren a derivar de dicha utilización.
                </p>
                <p>
                    De esta forma, las transacciones que el Usuario realice con terceros ajenos a La Montaña Agromercados a partir de las publicidades de productos y/o servicios, así como las visitas que realice a sus sitios web a través de los vínculos (links) serán bajo su exclusiva cuenta y riesgo, sin que de manera alguna comprometan la responsabilidad de “La Montaña Agromercados”.
                </p>

                <u id="proteccion-datos">Protección de Datos Personales</u>
                <p>
                    Al aceptar los términos y condiciones legales, el Usuario indica que conoce y autoriza de manera previa, expresa e informada a MIGGO INVESTMENT SAS, y vinculadas, para que sus datos personales puedan ser almacenados y usados con el fin de lograr una eficiente comunicación durante el presente trámite o actividad; y autoriza en los mismos términos, que dicha información pueda ser tratada conforme a lo dispuesto en la Ley 1581 de 2011 y sus Decretos Reglamentarios, con el fin de recibir información acerca de sus productos, servicios, ofertas, promociones, alianzas, estudios, concursos, contenidos. Así mismo, el Usuario ha sido informado acerca de la política para la protección de datos personales disponible en La Montaña Agromercados, en la cual se incluyen los procedimientos de consulta y reclamación que permiten hacer efectivos los derechos de los Usuarios al acceso, conocimiento, consulta, rectificación, actualización, y supresión de los datos, e igualmente el Usuario podrá presentar cualquier solicitud referida a los datos personales a través del correo electrónico:
                    <a href="mailto:crm@lamontanaagromercados.com">
                        crm@lamontanaagromercados.com
                    </a>
                </p>

                <u>Consultas</u>
                <p>
                    Cualquier pregunta relacionada con el uso de los Contenidos, cookies o con el
                    establecimiento de links hacia el URL www.lamontanaagromercados.com podrá dirigirse a
                    “La Montaña Agromercados” al teléfono (310)8323232. Su uso, en contravención de lo aquí
                    dispuesto, dará lugar a las acciones civiles y penales correspondientes.
                </p>

                <u>Legislación aplicable y jurisdicción</u>
                <p>
                    Los presentes Términos y Condiciones se regirán e interpretarán de acuerdo a las leyes de la República de Colombia. Cualquier controversia que derive de este documento se someterá a las leyes aplicables y a los jueces competentes de acuerdo con la legislación colombiana.
                </p>

                <u>Independencia de disposiciones</u>
                <p>
                    En caso de que una o más de las disposiciones contenidas en los presentes Términos y Condiciones sean consideradas nulas, ilegales o ineficaces en cualquier aspecto, la validez, legalidad y exigibilidad o eficacia del resto de las disposiciones del presente documento no se verán afectadas o anuladas por dicha circunstancia.
                </p>

                <u>Notificaciones</u>
                <p>
                    “La Montaña Agromercados” recibirá notificaciones a través del chat de whatsapp
                    (3108323232), o a través de correo electrónico                     
                </p>
                <a href="mailto:notificacionesmigan@comergyg.com.co">
                            notificacionesmigan@comergyg.com.co
                    </a>

            </div>
        </React.Fragment>
    )
}
