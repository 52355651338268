import React from "react";
import ToolbarMobile from "components/layout/components/mobile/ToolbarMobile";
import { TrackingOrder } from 'components/trackingOrder';

const TrackingOrderMobile = () => {
  return (
    <>
      <ToolbarMobile isBack={true} showCart={true}/>
      <TrackingOrder />
    </>);
};

export default TrackingOrderMobile;
