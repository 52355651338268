import { call, put, takeEvery } from 'redux-saga/effects';
import {
    loadConfigsRequest,
    loadConfigsSuccess,
    loadConfigsFailed
} from './configActions';
import firebase from 'config/firebaseConfig';
import { reduxSagaFirebase } from '../../rootSaga';


function* fetchConfigs() {
  try {
    firebase.firestore();
    
    const documentRef = firebase.firestore().collection('configs');
    const snapshot = yield call(reduxSagaFirebase.firestore.getCollection,  documentRef );
    const documents = snapshot.docs;
    const configs = yield documents.map((document) => (document.data()));

    yield put(loadConfigsSuccess(configs));
  } catch (error) {
    console.error(error);
    yield put(loadConfigsFailed());
  }
}

export function* configsSaga() {
  yield takeEvery([loadConfigsRequest], fetchConfigs);
}